import { useEffect, useState } from 'react';

import { useDrag, useDrop } from 'react-dnd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch } from 'react-redux';

import filePdfImgPath from 'assets/fileTypes/file-pdf-icon.svg';
import classNames from 'classnames';
import { countPdfPages } from 'helpers/countPdfPages';
import isNil from 'lodash/isNil';

import { sendAnalyticEvent } from 'data/actions/analytics';
import { updateMergeDocumentImage } from 'data/actions/documents';

import { generatePDFCover } from 'utils/generatePdfCover';

import FileActions from './fileActions';

interface IFileItem {
  name: string;
  file: File;
  thumbnail?: string | null;
  index: number;
  moveItem: (sourceIndex: number, destIndex: number) => void;
  isDraggingGlobal: boolean;
  color?: string;
}

const FileItem = ({ name, file, thumbnail, index, moveItem, isDraggingGlobal, color }: IFileItem) => {
  const dispatch = useDispatch();
  const [pagesCount, setPagesCount] = useState<null | number>(null);

  const [{ isDragging }, dragRef] = useDrag({
    type: 'FILE',
    item: { index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const [, dropRef] = useDrop({
    accept: 'FILE',
    hover: (draggedItem: any) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index; // Update the dragged item's index
      }
    },
    drop: () => {
      dispatch(sendAnalyticEvent({ event: 'merge_page_action', data: { action_type: 'reorder_files' } }));
    }
  });

  useEffect(() => {
    const fetchPdfCovers = async () => {
      try {
        const arrayBuffer = await file.arrayBuffer();

        const coverBlob = await generatePDFCover({
          pdfFileArrayBuffer: arrayBuffer,
          width: 164
        });
        const coverDataURL = coverBlob !== null ? URL.createObjectURL(coverBlob) : '';
        dispatch(updateMergeDocumentImage(index, coverDataURL));
      } catch {
        // do nothing
      }
    };

    const countPages = async () => {
      const pagesCount = await countPdfPages(file);
      setPagesCount(pagesCount);
    };

    if (!thumbnail || thumbnail === null) fetchPdfCovers();
    countPages();
  }, [file, thumbnail, dispatch, index]);

  return (
    <div
      ref={(node) => dragRef(dropRef(node))}
      className={[
        'p-3 m-1 cursor-grab border-2 flex flex-col gap-2 items-center rounded-lg box-border bg-white transition-all  w-[164px] h-[250px]',
        isDraggingGlobal && 'hover:bg-white',
        isDragging
          ? '!bg-[#5F30E20D] border-[#5F30E2] border-2 border-dashed'
          : 'group hover:bg-[#efeafc] border-transparent active:border-[#C7C7C7] active:opacity-80 active:bg-white active:transition-none'
      ]
        .filter(Boolean)
        .join(' ')}
      data-testid={`merge-file-${index}`}
    >
      {!isDragging && (
        <>
          <div className='relative'>
            {!isNil(pagesCount) && pagesCount > 1 && (
              <div className='absolute top-[-6px] left-[-6px] w-[124px] h-[168px] border border-[#E9E9E9] rounded-lg bg-[#FAFAFA]' />
            )}
            <FileActions
              file={file}
              thumbnail={thumbnail}
              index={index}
            />
            <div className='relative bg-white flex justify-center items-center border border-[#E9E9E9] rounded-lg overflow-hidden w-[140px] h-[168px]'>
              {thumbnail ? (
                <LazyLoadImage
                  src={thumbnail}
                  alt='pdf preview'
                />
              ) : (
                <LazyLoadImage
                  src={thumbnail || filePdfImgPath}
                  alt='pdf icon'
                />
              )}
            </div>
          </div>
          <div
            className={classNames(
              'text-[14px] w-full py-[1px] px-4 flex items-center justify-center rounded-md whitespace-nowrap text-ellipsis overflow-hidden text-center max-w-[124px] text-[#616161]',
              color || 'bg-[#5f30e20d]'
            )}
            data-testid={`merge-file-name-${index}`}
          >
            <span className='w-full whitespace-nowrap text-ellipsis overflow-hidden'>{name}</span>
          </div>
          {!isNil(pagesCount) && (
            <div
              className='text-[14px] w-full whitespace-nowrap text-ellipsis overflow-hidden text-center max-w-[124px] text-[#616161]'
              data-testid={`merge-file-pages-count-${index}`}
            >
              {pagesCount} page(s)
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FileItem;
