import React from 'react';

import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

const mailDomains = ['@gmail.com', '@yahoo.com', '@hotmail.com', '@hotmail.co.uk', '@icloud.com', '@outlook.com'];

const mailDomainsDev = [
  '@bot.pdfmaster.app',
  '@gmail.com',
  '@yahoo.com',
  '@hotmail.com',
  '@hotmail.co.uk',
  '@icloud.com',
  '@outlook.com'
];

if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_API_URL === 'https://api-dev.pdfguru.com')
  mailDomains.unshift('@bot.pdfmaster.app');

export interface EmailInputResult {
  email: string;
  valid: boolean;
}

interface IProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onDomainClick: (email: string) => void;
  placeholder?: string;
  className?: string;
  error?: React.ReactNode | string;
  disabled?: boolean;
  customEmailLabel?: string;
}

export const EmailInput: React.FC<IProps> = ({ value, onChange, onBlur, onDomainClick, error, customEmailLabel }) => {
  const { t } = useTranslation();

  const isDev = ['dev', 'local'].includes(process.env.NEXT_PUBLIC_APP_ENV || '');

  const currentMailDomains = isDev ? mailDomainsDev : mailDomains;

  const [isDomainsVisible, setIsDomainsVisible] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);

  const mailDomainsWithValue = () => {
    if (value.includes('@')) {
      setIsDomainsVisible(false);
      return [];
    }
    return currentMailDomains.map((domain) => `${value}${domain}`);
  };

  const handleFocus = React.useCallback(() => {
    setIsFocused(true);

    setIsDomainsVisible(!value.includes('@') && value.length > 0);
  }, [value]);

  React.useEffect(() => {
    setIsDomainsVisible(!value.includes('@') && value.length > 0);
  }, [isDomainsVisible, value]);

  const handleBlur = (event: React.FocusEvent<HTMLDivElement, Element>) => {
    // @NOTE: triggered if focus leave div-container, not only input
    if (!event.currentTarget.contains(event.relatedTarget)) {
      if (isFocused) {
        setIsFocused(false);
      }

      onBlur?.(event as React.FocusEvent<HTMLInputElement>);
    }
  };

  const classes = classNames({
    input: true,
    'no-valid': error
  });

  return (
    <div
      className={classes}
      onBlur={handleBlur}
    >
      {error !== null && <div className='error'>{error}</div>}
      <input
        value={value}
        id='email'
        type='email'
        inputMode='email'
        autoComplete='email'
        placeholder={t('global.enter_email')}
        onChange={onChange}
        autoFocus={true}
        onFocus={handleFocus}
        className='!bg-white !rounded-[5px] !text-[17px]'
      />
      <label
        htmlFor='email'
        className='!text-[16px] !mb-2 !ml-1'
      >
        {customEmailLabel || t('global.email')}
      </label>
      {isDomainsVisible && (
        <div
          id='domains'
          className='absolute top-[80px] z-10 w-full pb-2 '
        >
          <div
            style={{ overflowWrap: 'break-word' }}
            className=' bg-white w-full rounded-[5px] py-3 border border-solid border-[#F1F0EE] max-h-[126px] mobile:max-h-[136px] overflow-y-scroll '
          >
            {mailDomainsWithValue().map((item) => (
              <div
                role='button'
                className='px-4 py-2 flex text-[17px] hover:cursor-pointer hover:bg-[#f1f0ee] focus:bg-[#f1f0ee] focus:outline-none'
                key={item}
                onClick={() => {
                  onDomainClick(item);
                  setIsFocused(false);
                }}
                id={item}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
