import FlagAF from 'react-world-flags/src/svgs/af.svg';
import FlagAL from 'react-world-flags/src/svgs/al.svg';
import FlagAM from 'react-world-flags/src/svgs/am.svg';
import FlagAZ from 'react-world-flags/src/svgs/az.svg';
import FlagBA from 'react-world-flags/src/svgs/ba.svg';
import FlagBD from 'react-world-flags/src/svgs/bd.svg';
import FlagBG from 'react-world-flags/src/svgs/bg.svg';
import FlagCN from 'react-world-flags/src/svgs/cn.svg';
import FlagCZ from 'react-world-flags/src/svgs/cz.svg';
import FlagDE from 'react-world-flags/src/svgs/de.svg';
import FlagDK from 'react-world-flags/src/svgs/dk.svg';
import FlagEE from 'react-world-flags/src/svgs/ee.svg';
import FlagES from 'react-world-flags/src/svgs/es.svg';
import FlagET from 'react-world-flags/src/svgs/et.svg';
import FlagEU from 'react-world-flags/src/svgs/eu.svg';
import FlagFI from 'react-world-flags/src/svgs/fi.svg';
import FlagFR from 'react-world-flags/src/svgs/fr.svg';
import FlagGB from 'react-world-flags/src/svgs/gb.svg';
import FlagGE from 'react-world-flags/src/svgs/ge.svg';
import FlagGR from 'react-world-flags/src/svgs/gr.svg';
import FlagHR from 'react-world-flags/src/svgs/hr.svg';
import FlagHT from 'react-world-flags/src/svgs/ht.svg';
import FlagHU from 'react-world-flags/src/svgs/hu.svg';
import FlagID from 'react-world-flags/src/svgs/id.svg';
import FlagIE from 'react-world-flags/src/svgs/ie.svg';
import FlagIL from 'react-world-flags/src/svgs/il.svg';
import FlagIN from 'react-world-flags/src/svgs/in.svg';
import FlagIQ from 'react-world-flags/src/svgs/iq.svg';
import FlagIR from 'react-world-flags/src/svgs/ir.svg';
import FlagIS from 'react-world-flags/src/svgs/is.svg';
import FlagIT from 'react-world-flags/src/svgs/it.svg';
import FlagJP from 'react-world-flags/src/svgs/jp.svg';
import FlagKG from 'react-world-flags/src/svgs/kg.svg';
import FlagKH from 'react-world-flags/src/svgs/kh.svg';
import FlagKR from 'react-world-flags/src/svgs/kr.svg';
import FlagKZ from 'react-world-flags/src/svgs/kz.svg';
import FlagLA from 'react-world-flags/src/svgs/la.svg';
import FlagLT from 'react-world-flags/src/svgs/lt.svg';
import FlagLV from 'react-world-flags/src/svgs/lv.svg';
import FlagMG from 'react-world-flags/src/svgs/mg.svg';
import FlagMK from 'react-world-flags/src/svgs/mk.svg';
import FlagMM from 'react-world-flags/src/svgs/mm.svg';
import FlagMN from 'react-world-flags/src/svgs/mn.svg';
import FlagMT from 'react-world-flags/src/svgs/mt.svg';
import FlagMY from 'react-world-flags/src/svgs/my.svg';
import FlagNG from 'react-world-flags/src/svgs/ng.svg';
import FlagNL from 'react-world-flags/src/svgs/nl.svg';
import FlagNO from 'react-world-flags/src/svgs/no.svg';
import FlagNP from 'react-world-flags/src/svgs/np.svg';
import FlagPH from 'react-world-flags/src/svgs/ph.svg';
import FlagPK from 'react-world-flags/src/svgs/pk.svg';
import FlagPL from 'react-world-flags/src/svgs/pl.svg';
import FlagPT from 'react-world-flags/src/svgs/pt.svg';
import FlagRO from 'react-world-flags/src/svgs/ro.svg';
import FlagRS from 'react-world-flags/src/svgs/rs.svg';
import FlagSA from 'react-world-flags/src/svgs/sa.svg';
import FlagSE from 'react-world-flags/src/svgs/se.svg';
import FlagSI from 'react-world-flags/src/svgs/si.svg';
import FlagSK from 'react-world-flags/src/svgs/sk.svg';
import FlagSO from 'react-world-flags/src/svgs/so.svg';
import FlagTH from 'react-world-flags/src/svgs/th.svg';
import FlagTJ from 'react-world-flags/src/svgs/tj.svg';
import FlagTR from 'react-world-flags/src/svgs/tr.svg';
import FlagTW from 'react-world-flags/src/svgs/tw.svg';
import FlagTZ from 'react-world-flags/src/svgs/tz.svg';
import FlagUA from 'react-world-flags/src/svgs/ua.svg';
import FlagUZ from 'react-world-flags/src/svgs/uz.svg';
import FlagVN from 'react-world-flags/src/svgs/vn.svg';
import FlagZA from 'react-world-flags/src/svgs/za.svg';

export interface Language {
  language: string;
  code: string;
  flag: string;
  ttsCode?: string;
  nativeName: string;
  francName: string;
  link: string;
}

export const languages: Language[] = [
  {
    language: 'Afrikaans',
    code: 'af',
    flag: 'ZA',
    link: FlagZA,
    ttsCode: 'af-ZA',
    nativeName: 'Afrikaans',
    francName: 'afr'
  },
  {
    language: 'Albanian',
    code: 'sq',
    flag: 'AL',
    link: FlagAL,
    nativeName: 'Shqip',
    francName: 'alb'
  },
  {
    language: 'Amharic',
    code: 'am',
    flag: 'ET',
    link: FlagET,
    ttsCode: 'am-ET',
    nativeName: 'አማርኛ',
    francName: 'amh'
  },
  {
    language: 'Arabic',
    code: 'ar',
    flag: 'SA',
    link: FlagSA,
    ttsCode: 'ar-SA',
    nativeName: 'العربية',
    francName: 'ara'
  },
  {
    language: 'Armenian',
    code: 'hy',
    flag: 'AM',
    link: FlagAM,
    ttsCode: 'hy-AM',
    nativeName: 'Հայերեն',
    francName: 'hye'
  },
  {
    language: 'Azerbaijani',
    code: 'az',
    flag: 'AZ',
    link: FlagAZ,
    ttsCode: 'az-AZ',
    nativeName: 'Azərbaycan dili',
    francName: 'aze'
  },
  {
    language: 'Basque',
    code: 'eu',
    flag: 'ES',
    link: FlagES,
    ttsCode: 'eu-ES',
    nativeName: 'Euskara',
    francName: 'eus'
  },
  {
    language: 'Bengali',
    code: 'bn',
    flag: 'BD',
    link: FlagBD,
    ttsCode: 'bn-BD',
    nativeName: 'বাংলা',
    francName: 'ben'
  },
  {
    language: 'Bosnian',
    code: 'bs',
    flag: 'BA',
    link: FlagBA,
    nativeName: 'Bosanski',
    francName: 'bos'
  },
  {
    language: 'Bulgarian',
    code: 'bg',
    flag: 'BG',
    link: FlagBG,
    nativeName: 'Български',
    francName: 'bul'
  },
  {
    language: 'Catalan',
    code: 'ca',
    flag: 'ES',
    link: FlagES,
    nativeName: 'Català',
    francName: 'cat'
  },
  {
    language: 'Cebuano',
    code: 'ceb',
    flag: 'PH',
    link: FlagPH,
    nativeName: 'Cebuano',
    francName: 'ceb'
  },
  {
    language: 'Chinese (Simplified)',
    code: 'zh-CN',
    flag: 'CN',
    link: FlagCN,
    nativeName: '简体中文',
    francName: 'zho'
  },
  {
    language: 'Chinese (Traditional)',
    code: 'zh-TW',
    flag: 'TW',
    link: FlagTW,
    nativeName: '繁體中文',
    francName: 'zho'
  },
  {
    language: 'Corsican',
    code: 'co',
    flag: 'FR',
    link: FlagFR,
    nativeName: 'Corsu',
    francName: 'cos'
  },
  {
    language: 'Croatian',
    code: 'hr',
    flag: 'HR',
    link: FlagHR,
    nativeName: 'Hrvatski',
    francName: 'hrv'
  },
  {
    language: 'Czech',
    code: 'cs',
    flag: 'CZ',
    link: FlagCZ,
    nativeName: 'Čeština',
    francName: 'ces'
  },
  {
    language: 'Danish',
    code: 'da',
    flag: 'DK',
    link: FlagDK,
    nativeName: 'Dansk',
    francName: 'dan'
  },
  {
    language: 'Dutch',
    code: 'nl',
    flag: 'NL',
    link: FlagNL,
    nativeName: 'Nederlands',
    francName: 'nld'
  },
  {
    language: 'English',
    code: 'en',
    flag: 'GB',
    link: FlagGB,
    nativeName: 'English',
    francName: 'eng'
  },
  {
    language: 'Esperanto',
    code: 'eo',
    flag: 'EU',
    link: FlagEU,
    nativeName: 'Esperanto',
    francName: 'epo'
  },
  {
    language: 'Estonian',
    code: 'et',
    flag: 'EE',
    link: FlagEE,
    nativeName: 'Eesti',
    francName: 'est'
  },
  {
    language: 'Finnish',
    code: 'fi',
    flag: 'FI',
    link: FlagFI,
    nativeName: 'Suomi',
    francName: 'fin'
  },
  {
    language: 'French',
    code: 'fr',
    flag: 'FR',
    link: FlagFR,
    nativeName: 'Français',
    francName: 'fra'
  },
  {
    language: 'Galician',
    code: 'gl',
    flag: 'ES',
    link: FlagES,
    nativeName: 'Galego',
    francName: 'glg'
  },
  {
    language: 'Georgian',
    code: 'ka',
    flag: 'GE',
    link: FlagGE,
    nativeName: 'ქართული',
    francName: 'kat'
  },
  {
    language: 'German',
    code: 'de',
    flag: 'DE',
    link: FlagDE,
    nativeName: 'Deutsch',
    francName: 'deu'
  },
  {
    language: 'Greek',
    code: 'el',
    flag: 'GR',
    link: FlagGR,
    nativeName: 'Ελληνικά',
    francName: 'ell'
  },
  {
    language: 'Gujarati',
    code: 'gu',
    flag: 'IN',
    link: FlagIN,
    nativeName: 'ગુજરાતી',
    francName: 'guj'
  },
  {
    language: 'Haitian Creole',
    code: 'ht',
    flag: 'HT',
    link: FlagHT,
    nativeName: 'Kreyòl Ayisyen',
    francName: 'hat'
  },
  {
    language: 'Hausa',
    code: 'ha',
    flag: 'NG',
    link: FlagNG,
    nativeName: 'Hausa',
    francName: 'hau'
  },
  {
    language: 'Hebrew',
    code: 'iw',
    flag: 'IL',
    link: FlagIL,
    nativeName: 'עברית',
    francName: 'heb'
  },
  {
    language: 'Hindi',
    code: 'hi',
    flag: 'IN',
    link: FlagIN,
    nativeName: 'हिन्दी',
    francName: 'hin'
  },
  {
    language: 'Hungarian',
    code: 'hu',
    flag: 'HU',
    link: FlagHU,
    nativeName: 'Magyar',
    francName: 'hun'
  },
  {
    language: 'Icelandic',
    code: 'is',
    flag: 'IS',
    link: FlagIS,
    nativeName: 'Íslenska',
    francName: 'isl'
  },
  {
    language: 'Igbo',
    code: 'ig',
    flag: 'NG',
    link: FlagNG,
    nativeName: 'Igbo',
    francName: 'ibo'
  },
  {
    language: 'Indonesian',
    code: 'id',
    flag: 'ID',
    link: FlagID,
    nativeName: 'Bahasa Indonesia',
    francName: 'ind'
  },
  {
    language: 'Irish',
    code: 'ga',
    flag: 'IE',
    link: FlagIE,
    nativeName: 'Gaeilge',
    francName: 'gle'
  },
  {
    language: 'Italian',
    code: 'it',
    flag: 'IT',
    link: FlagIT,
    nativeName: 'Italiano',
    francName: 'ita'
  },
  {
    language: 'Japanese',
    code: 'ja',
    flag: 'JP',
    link: FlagJP,
    nativeName: '日本語',
    francName: 'jpn'
  },
  {
    language: 'Javanese',
    code: 'jw',
    flag: 'ID',
    link: FlagID,
    nativeName: 'Basa Jawa',
    francName: 'jav'
  },
  {
    language: 'Kannada',
    code: 'kn',
    flag: 'IN',
    link: FlagIN,
    nativeName: 'ಕನ್ನಡ',
    francName: 'kan'
  },
  {
    language: 'Kazakh',
    code: 'kk',
    flag: 'KZ',
    link: FlagKZ,
    nativeName: 'Қазақ тілі',
    francName: 'kaz'
  },
  {
    language: 'Khmer',
    code: 'km',
    flag: 'KH',
    link: FlagKH,
    nativeName: 'ខ្មែរ',
    francName: 'khm'
  },
  {
    language: 'Korean',
    code: 'ko',
    flag: 'KR',
    link: FlagKR,
    nativeName: '한국어',
    francName: 'kor'
  },
  {
    language: 'Kurdish',
    code: 'ku',
    flag: 'IQ',
    link: FlagIQ,
    nativeName: 'Kurdî',
    francName: 'kur'
  },
  {
    language: 'Kyrgyz',
    code: 'ky',
    flag: 'KG',
    link: FlagKG,
    nativeName: 'Кыргызча',
    francName: 'kir'
  },
  {
    language: 'Lao',
    code: 'lo',
    flag: 'LA',
    link: FlagLA,
    nativeName: 'ລາວ',
    francName: 'lao'
  },
  {
    language: 'Latvian',
    code: 'lv',
    flag: 'LV',
    link: FlagLV,
    nativeName: 'Latviešu',
    francName: 'lav'
  },
  {
    language: 'Lithuanian',
    code: 'lt',
    flag: 'LT',
    link: FlagLT,
    nativeName: 'Lietuvių',
    francName: 'lit'
  },
  {
    language: 'Macedonian',
    code: 'mk',
    flag: 'MK',
    link: FlagMK,
    nativeName: 'Македонски',
    francName: 'mkd'
  },
  {
    language: 'Malagasy',
    code: 'mg',
    flag: 'MG',
    link: FlagMG,
    nativeName: 'Malagasy',
    francName: 'mlg'
  },
  {
    language: 'Malay',
    code: 'ms',
    flag: 'MY',
    link: FlagMY,
    nativeName: 'Bahasa Melayu',
    francName: 'msa'
  },
  {
    language: 'Malayalam',
    code: 'ml',
    flag: 'IN',
    link: FlagIN,
    nativeName: 'മലയാളം',
    francName: 'mal'
  },
  {
    language: 'Maltese',
    code: 'mt',
    flag: 'MT',
    link: FlagMT,
    nativeName: 'Malti',
    francName: 'mlt'
  },
  {
    language: 'Marathi',
    code: 'mr',
    flag: 'IN',
    link: FlagIN,
    nativeName: 'मराठी',
    francName: 'mar'
  },
  {
    language: 'Mongolian',
    code: 'mn',
    flag: 'MN',
    link: FlagMN,
    nativeName: 'Монгол',
    francName: 'mon'
  },
  {
    language: 'Myanmar (Burmese)',
    code: 'my',
    flag: 'MM',
    link: FlagMM,
    nativeName: 'မြန်မာ',
    francName: 'mya'
  },
  {
    language: 'Nepali',
    code: 'ne',
    flag: 'NP',
    link: FlagNP,
    nativeName: 'नेपाली',
    francName: 'nep'
  },
  {
    language: 'Norwegian',
    code: 'no',
    flag: 'NO',
    link: FlagNO,
    nativeName: 'Norsk',
    francName: 'nor'
  },
  {
    language: 'Pashto',
    code: 'ps',
    flag: 'AF',
    link: FlagAF,
    nativeName: 'پښتو',
    francName: 'pus'
  },
  {
    language: 'Persian',
    code: 'fa',
    flag: 'IR',
    link: FlagIR,
    nativeName: 'فارسی',
    francName: 'fas'
  },
  {
    language: 'Polish',
    code: 'pl',
    flag: 'PL',
    link: FlagPL,
    nativeName: 'Polski',
    francName: 'pol'
  },
  {
    language: 'Portuguese',
    code: 'pt',
    flag: 'PT',
    link: FlagPT,
    nativeName: 'Português',
    francName: 'por'
  },
  {
    language: 'Punjabi',
    code: 'pa',
    flag: 'IN',
    link: FlagIN,
    nativeName: 'ਪੰਜਾਬੀ',
    francName: 'pan'
  },
  {
    language: 'Romanian',
    code: 'ro',
    flag: 'RO',
    link: FlagRO,
    nativeName: 'Română',
    francName: 'ron'
  },
  {
    language: 'Serbian',
    code: 'sr',
    flag: 'RS',
    link: FlagRS,
    nativeName: 'Српски',
    francName: 'srp'
  },
  {
    language: 'Slovak',
    code: 'sk',
    flag: 'SK',
    link: FlagSK,
    nativeName: 'Slovenský',
    francName: 'slk'
  },
  {
    language: 'Slovenian',
    code: 'sl',
    flag: 'SI',
    link: FlagSI,
    nativeName: 'Slovenski',
    francName: 'slv'
  },
  {
    language: 'Somali',
    code: 'so',
    flag: 'SO',
    link: FlagSO,
    nativeName: 'Soomaali',
    francName: 'som'
  },
  {
    language: 'Spanish',
    code: 'es',
    flag: 'ES',
    link: FlagES,
    nativeName: 'Español',
    francName: 'spa'
  },
  {
    language: 'Swahili',
    code: 'sw',
    flag: 'TZ',
    link: FlagTZ,
    nativeName: 'Kiswahili',
    francName: 'swa'
  },
  {
    language: 'Swedish',
    code: 'sv',
    flag: 'SE',
    link: FlagSE,
    nativeName: 'Svenska',
    francName: 'swe'
  },
  {
    language: 'Tajik',
    code: 'tg',
    flag: 'TJ',
    link: FlagTJ,
    nativeName: 'Тоҷикӣ',
    francName: 'tgk'
  },
  {
    language: 'Tamil',
    code: 'ta',
    flag: 'IN',
    link: FlagIN,
    nativeName: 'தமிழ்',
    francName: 'tam'
  },
  {
    language: 'Telugu',
    code: 'te',
    flag: 'IN',
    link: FlagIN,
    nativeName: 'తెలుగు',
    francName: 'tel'
  },
  {
    language: 'Thai',
    code: 'th',
    flag: 'TH',
    link: FlagTH,
    nativeName: 'ไทย',
    francName: 'tha'
  },
  {
    language: 'Turkish',
    code: 'tr',
    flag: 'TR',
    link: FlagTR,
    nativeName: 'Türkçe',
    francName: 'tur'
  },
  {
    language: 'Ukrainian',
    code: 'uk',
    flag: 'UA',
    link: FlagUA,
    nativeName: 'Українська',
    francName: 'ukr'
  },
  {
    language: 'Urdu',
    code: 'ur',
    flag: 'PK',
    link: FlagPK,
    nativeName: 'اردو',
    francName: 'urd'
  },
  {
    language: 'Uzbek',
    code: 'uz',
    flag: 'UZ',
    link: FlagUZ,
    nativeName: 'Ozbek',
    francName: 'uzb'
  },
  {
    language: 'Vietnamese',
    code: 'vi',
    flag: 'VN',
    link: FlagVN,
    nativeName: 'Tiếng Việt',
    francName: 'vie'
  },
  {
    language: 'Welsh',
    code: 'cy',
    flag: 'GB',
    link: FlagGB,
    nativeName: 'Cymraeg',
    francName: 'cym'
  },
  {
    language: 'Xhosa',
    code: 'xh',
    flag: 'ZA',
    link: FlagZA,
    nativeName: 'isiXhosa',
    francName: 'xho'
  },
  {
    language: 'Yiddish',
    code: 'yi',
    flag: 'IL',
    link: FlagIL,
    nativeName: 'ייִדיש',
    francName: 'yid'
  },
  {
    language: 'Yoruba',
    code: 'yo',
    flag: 'NG',
    link: FlagNG,
    nativeName: 'Yorùbá',
    francName: 'yor'
  },
  {
    language: 'Zulu',
    code: 'zu',
    flag: 'ZA',
    link: FlagZA,
    nativeName: 'isiZulu',
    francName: 'zul'
  }
];
