import type { Middleware } from 'redux';
import { Subject } from 'rxjs';

import { Analytics } from 'services/analytics';
import type { AnalyticsEvent } from 'services/analytics/events';

import type { IReduxAction } from 'ts/interfaces/redux/redux.action';

const eventsSubject = new Subject<{ topic: string; analyticEvent: AnalyticsEvent }>();

eventsSubject.subscribe({
  next: (event) => {
    try {
      Analytics.sendEvent(event.analyticEvent);
    } catch (error) {
      console.error('Error sending event:', error);
    }
  }
});

const analyticsMiddleware = (): Middleware => () => (next: any) => async (action: IReduxAction) => {
  const analyticEvent = action?.analyticEvent;
  if (!analyticEvent) return next(action);

  eventsSubject.next({ topic: 'analyticEvent', analyticEvent });
  return next(action);
};

export default analyticsMiddleware;
