import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { sendAnalyticEvent } from 'data/actions/analytics';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector } from 'data/selectors/modals';

import ModalLayout from '../baseModal/base';

export const AreYouSureLeavePageModal = () => {
  const options = useSelector(modalOptionsSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(sendAnalyticEvent({ event: 'leave_page_modal_view', data: { type: options.type } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <ModalLayout canClose>
      <div className='rounded-xl p-6 border-l border-t bg-white w-[520px] relative'>
        <div className='flex flex-col items-center justify-between h-full gap-8'>
          <div className='text-2xl font-bold text-center max-w-[60%] mt-10'>
            {t('popups.are_you_sure_leave_page_popup.description')}
          </div>
          <div className='flex flex-row items-center justify-center w-full gap-4'>
            <button
              onClick={() => {
                dispatch(toggleModal({ visible: false }));
                dispatch(
                  sendAnalyticEvent({
                    event: 'leave_page_modal_cancel_tap',
                    data: { type: options.type }
                  })
                );
              }}
              className='w-[200px] text-lg font-bold text-center rounded-xl py-3 border-2 border-black'
            >
              {t('popups.are_you_sure_leave_page_popup.cancel')}
            </button>
            <button
              onClick={() => {
                options.onAccept();
                dispatch(toggleModal({ visible: false }));
                dispatch(
                  sendAnalyticEvent({
                    event: 'leave_page_modal_confirm_tap',
                    data: { type: options.type }
                  })
                );
              }}
              className='w-[200px] text-white text-lg font-bold text-center rounded-xl py-3 border-2 border-[#5F31E2] bg-[#5F31E2]'
            >
              {t('popups.are_you_sure_leave_page_popup.yes')}
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
