import React, { useState } from 'react';

import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import cross from 'assets/img/icons/32px/cross-grey.svg';
import plus from 'assets/img/icons/account/tools/plus.svg';
import trash from 'assets/img/icons/account/tools/trash.svg';
import infoIconPath from 'assets/img/info-icon-blue.svg';
import { Buffer } from 'buffer';
import { mergeImagesPageService } from 'helpers/services/mergeImageServiceList';
import { MAX_FILES_ALLOWED } from 'pages/mergePdf';
import type { PDFPage } from 'pdf-lib';
import { PDFDocument } from 'pdf-lib';

import { sendAnalyticEvent } from 'data/actions/analytics';
import { toggleModal } from 'data/actions/modals';
import { mergeDocumentsListSelector } from 'data/selectors/documents';

import { urltoFile } from 'utils/urlToFile';

import UploadButtonMerge from '../../uploadButtons/uploadButtonMerge';
import ModalLayout from '../baseModal';

const MergeFilesModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const filesList: { file: File; thumbnail?: string }[] = useSelector(mergeDocumentsListSelector());

  const [error] = useState<string | null>(null);

  const handleClose = () => {
    dispatch(toggleModal({ visible: false }));
    dispatch(
      sendAnalyticEvent({
        event: 'tool_merge_file_close_tap',
        data: { place: 'my_dashboard' }
      })
    );
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedFiles = Array.from(filesList);
    const [movedFile] = reorderedFiles.splice(result.source.index, 1);
    reorderedFiles.splice(result.destination.index, 0, movedFile);

    dispatch({
      type: 'SET_MERGE_DOCUMENT_DATA',
      payload: reorderedFiles
    });

    dispatch(sendAnalyticEvent({ event: 'files_reordered' }));
  };

  const removeFile = (index: number) => {
    const updatedFiles = filesList.filter((_: any, i: number) => i !== index);
    dispatch(sendAnalyticEvent({ event: 'tool_merge_file_delete_tap' }));
    dispatch({
      type: 'SET_MERGE_DOCUMENT_DATA',
      payload: updatedFiles
    });
  };

  async function mergePdfs(pdfsToMerge: ArrayBuffer[]): Promise<ArrayBufferLike> {
    const mergedPdf: PDFDocument = await PDFDocument.create();

    const createInnerPromise = async (arrayBuffer: ArrayBuffer): Promise<PDFPage[]> => {
      const pdf: PDFDocument = await PDFDocument.load(arrayBuffer, { ignoreEncryption: true });
      return await mergedPdf.copyPages(pdf, pdf.getPageIndices());
    };

    const outerPromise: Promise<PDFPage[]>[] = pdfsToMerge.map((arrayBuffer) => {
      const innerPromise: Promise<PDFPage[]> = createInnerPromise(arrayBuffer);
      return innerPromise;
    });

    const resultOuterPromise: PDFPage[][] = await Promise.all(outerPromise);

    resultOuterPromise.forEach((pageArray: PDFPage[]) => {
      pageArray.forEach((page: PDFPage) => {
        mergedPdf.addPage(page);
      });
    });

    return (await mergedPdf.save()).buffer;
  }

  const mergePdfFiles = async () => {
    const arrayBufferList = await Promise.all(filesList.map((file) => file.file.arrayBuffer()));
    const mergedFile = await mergePdfs(arrayBufferList);

    const buffer = Buffer.from(mergedFile);

    const base64String = `data:application/pdf;base64,${buffer.toString('base64')}`;

    return urltoFile(base64String, 'merged.pdf', 'application/pdf');
  };

  const handleDropZoneDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files) {
      const filesArray = Array.from(files).map((file) => ({
        file,
        thumbnail: URL.createObjectURL(file)
      }));
      const updatedFiles = [...filesList, ...filesArray].slice(0, 14);

      dispatch({
        type: 'SET_MERGE_DOCUMENT_DATA',
        payload: updatedFiles
      });

      dispatch(sendAnalyticEvent({ event: 'tool_merge_file_add_tap' }));
    }
  };

  const preventDefault = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleMerge = async () => {
    dispatch(
      sendAnalyticEvent({
        event: 'complete_merge_pdf_tap',
        data: { count: filesList.length }
      })
    );

    const resultFile = await mergePdfFiles();
    // @ts-expect-error Very strange code, need to review it
    handleUploadFile(resultFile);
  };

  return (
    <ModalLayout data-testid='merge-files'>
      <div className='py-8 px-10 flex flex-col items-center bg-white'>
        <div
          className='w-full'
          onDragOver={preventDefault}
          onDrop={handleDropZoneDrop}
        >
          <div className='flex justify-end w-full'>
            <img
              src={cross}
              className='cursor-pointer'
              alt='Close icon'
              width={16}
              height={16}
              onClick={handleClose}
            />
          </div>

          <h1 className='text-[25px] font-bold leading-[32.5px] text-center'>{t('account_page.actions.merge_pdfs')}</h1>

          <div className='flex justify-center items-center mt-6'>
            <img
              src={infoIconPath}
              alt='info'
              width={24}
              height={24}
            />
            <p className='text-[13.2px] leading-[17.7px] text-[#616161] ml-2'>{t('account_page.info.merge_pdfs')}</p>
          </div>

          <div className='mt-4 w-full max-w-[740px]'>
            <div className='flex flex-wrap gap-4 min-h-[145px]'>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                  droppableId='merge-files-droppable'
                  direction='horizontal'
                >
                  {(provided) => (
                    <div
                      className='flex flex-wrap gap-4'
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {filesList.map((fileItem: any, index: number) => (
                        <Draggable
                          key={fileItem.name}
                          draggableId={fileItem.name}
                          index={index}
                        >
                          {(providedDraggable) => (
                            <div
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              {...providedDraggable.dragHandleProps}
                              className={`relative w-[118px] h-[181px] cursor-move rounded-lg`}
                            >
                              <button
                                onClick={() => {
                                  removeFile(index);
                                }}
                                className='bg-white p-2 absolute right-[6px] top-1 rounded-lg z-10'
                              >
                                <img
                                  src={trash}
                                  alt='trash'
                                  width={16}
                                  height={16}
                                />
                              </button>

                              <div className='flex items-center flex-col rounded-lg overflow-hidden'>
                                {fileItem.thumbnail ? (
                                  <div className='w-[118px] h-[160px] relative rounded-lg'>
                                    <img
                                      src={fileItem.thumbnail}
                                      alt={`Preview of ${fileItem.file.name}`}
                                      className='rounded-lg object-contain'
                                      sizes='118px'
                                    />
                                    <div className='absolute inset-0 bg-black opacity-10 rounded-lg' />
                                  </div>
                                ) : (
                                  <div className='w-[118px] h-[160px] bg-gray-200 animate-pulse' />
                                )}
                                <span className='text-[11px] leading-[14.8px] text-[#575757] truncate max-w-full'>
                                  {fileItem.name}
                                </span>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}

                      {filesList.length < 14 && (
                        <div
                          className='cursor-pointer w-[118px] h-[160px] flex items-center justify-center border-2 border-dashed border-gray-300 rounded-lg'
                          onDragOver={() => preventDefault}
                          onDrop={() => handleDropZoneDrop}
                        >
                          <UploadButtonMerge
                            isDisabled={filesList && filesList?.length >= MAX_FILES_ALLOWED}
                            service={mergeImagesPageService(t)}
                            buttonText={t('complete_merge.add_file')}
                            dataTestId='add-file-button'
                            customButton={
                              <div>
                                <img
                                  src={plus}
                                  alt='plus'
                                  width={50}
                                  height={50}
                                  className='hover:opacity-80 transition-opacity'
                                  draggable={false}
                                />
                              </div>
                            }
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>

        {filesList.length < 2 && (
          <div className='text-[13.2px] leading-[17.7px] text-red-400 mt-4'>{t('upload_section.error_1')}</div>
        )}

        {error && <div className='text-[13.2px] leading-[17.7px] text-red-400 mt-4'>{error}</div>}

        <button
          className='mt-4 bg-[#D2294B] text-white px-10 py-3 rounded-md w-full tablet:max-w-[394px] disabled:cursor-not-allowed'
          onClick={handleMerge}
          disabled={filesList.length < 2 || filesList.length > 14}
        >
          {t('account_page.actions.merge')}
        </button>
      </div>
    </ModalLayout>
  );
};

export default MergeFilesModal;
