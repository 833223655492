import type { AnalyticsEvent } from 'services/analytics/events';
import type { AnalyticsService, AnalyticsUserProp } from 'services/analytics/index';
import { Analytics } from 'services/analytics/index';

import { getDeviceType } from 'utils/getDeviceType';
import { Logger } from 'utils/logger';

class Gtag implements AnalyticsService {
  private initialized = false;
  private eventBuffer: AnalyticsEvent[] = [];
  private propsBuffer: AnalyticsUserProp[] = [];
  private readonly logger = new Logger('Gtag');

  init(): void {
    const interval = setInterval(() => {
      if (window?.gtag === undefined) {
        return;
      }

      this.initialized = true;
      this.logger.log('Init');
      this.processBuffer();

      clearInterval(interval);
    }, 200);
  }

  public track(event: AnalyticsEvent): void {
    if (!this.initialized) {
      this.eventBuffer.push(event);
      return;
    }

    if (window.gtag === undefined) {
      this.eventBuffer.push(event);
      return;
    }

    this.sendEvent(event);
  }

  public updateUser(data: AnalyticsUserProp | AnalyticsUserProp[]): void {
    if (!this.initialized) {
      this.propsBuffer = Array.isArray(data) ? [...this.propsBuffer, ...data] : [...this.propsBuffer, data];
      return;
    }

    if (window.gtag === undefined) {
      this.propsBuffer = Array.isArray(data) ? [...this.propsBuffer, ...data] : [...this.propsBuffer, data];
      return;
    }

    this.sendUserProps(data);
  }

  public setUserId(_: string): void {
    // do nothing
  }

  private processBuffer() {
    this.eventBuffer.map((event) => this.sendEvent(event));
    this.eventBuffer = [];
    this.propsBuffer.map((props) => this.sendUserProps(props));
    this.propsBuffer = [];
  }

  private sendEvent(event: AnalyticsEvent | any): void {
    const page = Analytics.getCurrentPageName();
    const localPage = Analytics.getCurrentPageLocale();
    const deviceType = getDeviceType();

    let props = {
      page,
      local_page: localPage,
      device: deviceType
    };

    if ('data' in event && event.data) {
      props = { ...props, ...event.data };
    }

    if (event.event === 'sale_confirmation_success') {
      const name = 'purchase';
      const data = {
        currency: 'USD',
        value: event.data.value || 2.0,
        transaction_id: event.data.orderId,
        items: [
          {
            item_id: event.data.productId || 'pdf_guru_monthly_49_99_with_trial_1_99',
            item_name: event.data.productId || 'pdf_guru_monthly_49_99_with_trial_1_99',
            price: event.data.value || 2.0,
            quantity: 1
          }
        ]
      };

      window.dataLayer.push('event', name, data);
      window.gtag('event', name, data);
      this.logger.log('Send system event', { name, data: JSON.stringify(data) });
    }

    if (event.event === 'choose_plan_tap') {
      const selectItemData = {
        items: [{ item_id: event.data.productId, item_name: event.data.productId }]
      };

      window.gtag('event', 'select_item', selectItemData);
      this.logger.log('Send system event', {
        name: 'select_item',
        data: JSON.stringify(selectItemData)
      });

      const addToCartData = {
        currency: event.data.currency,
        value: event.data.value,
        items: [{ item_id: event.data.productId, item_name: event.data.productId }]
      };
      window.gtag('event', 'add_to_cart', addToCartData);
      this.logger.log('Send system event', {
        name: 'add_to_cart',
        data: JSON.stringify(addToCartData)
      });
    }

    if (event.event.includes('page_view')) {
      window.dataLayer.push('event', event.event, props);
    }

    window?.gtag('event', event.event, props);
    // this.logger.log('Send custom event', {
    //   name: event.event,
    //   data: JSON.stringify(props),
    // })
  }

  private sendUserProps(data: AnalyticsUserProp | AnalyticsUserProp[]): void {
    const update = (prop: any) => {
      window.gtag('set', { [prop.key]: prop.value });
    };

    if (Array.isArray(data)) {
      data.map((prop) => update(prop));
      return;
    }

    update(data);
  }
}

export const GtagAnalytics = new Gtag();
