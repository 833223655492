import React from 'react';

import imgEpub from 'assets/img/icons/ab-import_export_editor_2_4_4/epub.svg';
import imgXlsx from 'assets/img/icons/ab-import_export_editor_2_4_4/excel.svg';
import imgImage from 'assets/img/icons/ab-import_export_editor_2_4_4/images.svg';
import imgPdf from 'assets/img/icons/ab-import_export_editor_2_4_4/pdf.svg';
import imgPpt from 'assets/img/icons/ab-import_export_editor_2_4_4/power_point.svg';
import imgDocx from 'assets/img/icons/ab-import_export_editor_2_4_4/word.svg';

import { fromMimetype, InternalFileType } from 'ts/types/file';

interface IProps {
  type?: InternalFileType | string;
  size: number;
}

export const UploadFileIcon: React.FC<IProps> = ({ type, size }) => {
  const style = { width: size, height: size };

  switch (fromMimetype(type || '')) {
    case InternalFileType.DOC:
    case InternalFileType.DOCX:
      return (
        <img
          src={imgDocx}
          alt='DOCX icon'
          style={style}
        />
      );

    case InternalFileType.PDF:
      return (
        <img
          src={imgPdf}
          alt='PDF icon'
          style={style}
        />
      );

    case InternalFileType.XLS:
    case InternalFileType.XLSX:
      return (
        <img
          src={imgXlsx}
          alt='Excel icon'
          style={style}
        />
      );

    case InternalFileType.PPT:
    case InternalFileType.PPTX:
      return (
        <img
          src={imgPpt}
          alt='PowerPoint icon'
          style={style}
        />
      );

    case InternalFileType.EPUB:
      return (
        <img
          src={imgEpub}
          alt='EPUB icon'
          style={style}
        />
      );

    case InternalFileType.JPG:
    case InternalFileType.JPEG:
    case InternalFileType.PNG:
    case InternalFileType.GIF:
    case InternalFileType.BMP:
    case InternalFileType.TIFF:
    case InternalFileType.WEBP:
    case InternalFileType.HEIC:
    case InternalFileType.SVG:
    case InternalFileType.EPS:
      // eslint-disable-next-line jsx-a11y/img-redundant-alt
      return (
        <img
          src={imgImage}
          alt='Icon'
          style={style}
        />
      );
    default:
      return (
        <img
          src={imgDocx}
          alt='Default DOCX icon'
          style={style}
        />
      );
  }
};
