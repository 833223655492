import { PDFDocument, RotationTypes } from 'pdf-lib';

export const rotatePdfPages = async (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          // Load the PDF
          const pdfDoc = await PDFDocument.load(e.target?.result as ArrayBuffer, {
            ignoreEncryption: true
          });

          // Rotate all pages by 90 degrees
          pdfDoc.getPages().forEach((page) => {
            page.setRotation({
              type: RotationTypes.Degrees,
              angle: page.getRotation().angle + 90
            });
          });

          // Serialize the modified PDF
          const pdfBytes = await pdfDoc.save();

          // Create a new File object
          const rotatedPdfFile = new File([pdfBytes], file.name, {
            type: 'application/pdf'
          });

          resolve(rotatedPdfFile);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    } catch (error) {
      reject(error);
    }
  });
};
