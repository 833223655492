import type { ReactNode } from 'react';

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import FullScreenLoader from 'components/common/fullScreenLoader';

import { getLoadingSelector } from 'data/selectors/loading';
import { isUserAuthenticated } from 'data/selectors/user';

import { PAGE_LINKS } from 'ts/constants/page-links';

interface PathLocaleProviderProps {
  children: ReactNode;
}

export const ProtectAuthRoutesProvider = ({ children }: PathLocaleProviderProps) => {
  const isLoading = useSelector(getLoadingSelector('get_user'));
  const isAuth = useSelector(isUserAuthenticated);

  if (isLoading) {
    return <FullScreenLoader />;
  }

  if (!isAuth) {
    return <Navigate to={PAGE_LINKS.MAIN} />;
  }

  return <>{children}</>;
};
