import type { FC } from 'react';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useKeyPress from 'hooks/useKeyPress';

import { PrimaryButton } from 'components/buttons/primary-button';
import { SecondaryButton } from 'components/buttons/secondary-button';
import ModalLayout from 'components/modals/baseModal/base';

import { Analytics } from 'services/analytics';

import { deleteDocumentById } from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector } from 'data/selectors/modals';

import { sliceFileName } from 'utils/sliceFileName';

import s from './delete-file-popup.module.scss';

const DeleteDocumentModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { documentId, fileName } = useSelector(modalOptionsSelector);

  useEffect(() => {
    Analytics.sendEvent({ event: 'delete_ﬁle_modal_view' });
  }, []);

  const handleDeleteFile = () => {
    dispatch(deleteDocumentById(documentId));
    dispatch(toggleModal({ visible: false }));
    Analytics.sendEvent({ event: 'delete_ﬁle_confirm_tap' });
  };

  useKeyPress({ targetKey: 'Enter', onKeyPressDown: handleDeleteFile });

  const handleClose = () => {
    dispatch(toggleModal({ visible: false }));
    Analytics.sendEvent({ event: 'delete_ﬁle_cancel_tap' });
  };

  return (
    <ModalLayout canClose>
      <div className={s.deleteDocumentPopup}>
        <h2>{t('popups.delete_file_popup.question', { filename: sliceFileName(fileName || '') })}</h2>
        <div className={s.buttons}>
          <SecondaryButton
            onClick={handleClose}
            className={s.button}
          >
            {t('popups.delete_file_popup.no')}
          </SecondaryButton>
          <PrimaryButton
            onClick={handleDeleteFile}
            className={s.buttonSubmit}
          >
            {t('popups.delete_file_popup.yes')}
          </PrimaryButton>
        </div>
      </div>
    </ModalLayout>
  );
};

export default DeleteDocumentModal;
