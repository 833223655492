import dayjs from 'dayjs';
// without this line it didn't work
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';

export const getFileFormatFromFile = (files: File | File[]) => {
  const file = Array.isArray(files) ? files[0] : files;
  if (!file) return null;

  const formatMatch = file.name.match(/\.[0-9a-z]+$/i);

  if (formatMatch) {
    return formatMatch[0];
  }

  return null;
};

export const formatTimeMoment = ({
  time,
  locale = 'en',
  format = 'MMMM DD, YYYY'
}: {
  time: string;
  locale?: string;
  format?: string;
}) => {
  return dayjs(time).locale(locale).format(format);
};
