import how_to_epub_to_pdf from 'assets/img/how-to-section/how-to-new-design-epub-to-pdf.svg';
import how_to_excel_to_pdf from 'assets/img/how-to-section/how-to-new-design-excel-to-pdf.svg';
import how_to_heic_to_pdf from 'assets/img/how-to-section/how-to-new-design-heic-to-pdf.svg';
import how_to_image_to_pdf from 'assets/img/how-to-section/how-to-new-design-image-to-pdf.svg';
import how_to_jpg_to_pdf from 'assets/img/how-to-section/how-to-new-design-jpg-to-pdf.svg';
import how_to_pdf_to_epub from 'assets/img/how-to-section/how-to-new-design-pdf-to-epub.svg';
import how_to_pdf_to_excel from 'assets/img/how-to-section/how-to-new-design-pdf-to-excel.svg';
import how_to_pdf_to_jpg from 'assets/img/how-to-section/how-to-new-design-pdf-to-jpg.svg';
import how_to_pdf_to_png from 'assets/img/how-to-section/how-to-new-design-pdf-to-png.svg';
import how_to_pdf_to_pptx from 'assets/img/how-to-section/how-to-new-design-pdf-to-pptx.svg';
import how_to_pdf_to_word from 'assets/img/how-to-section/how-to-new-design-pdf-word.svg';
import how_to_png_to_pdf from 'assets/img/how-to-section/how-to-new-design-png-to-pdf.svg';
import how_to_pptx_to_pdf from 'assets/img/how-to-section/how-to-new-design-pptx-to-pdf.svg';
import how_to_word_to_pdf from 'assets/img/how-to-section/how-to-new-design-word-to-pdf.svg';
import icon_1 from 'assets/img/how-to-section/icon_1.svg';
import icon_2 from 'assets/img/how-to-section/icon_2.svg';
import icon_3 from 'assets/img/how-to-section/icon_3.svg';

import type { IService } from 'ts/interfaces/services/service';
import { EServiceType } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

import epub_to_pdf from './assets/eput_to_pdf.svg';
import excel_to_pdf from './assets/excel_to_pdf.svg';
import image_to_pdf from './assets/image_to_pdf.svg';
import jpg_to_pdf from './assets/jpg_to_pdf.svg';
import pdf_converter from './assets/pdf_converter.svg';
import pdf_to_epub from './assets/pdf_to_epub.svg';
import pdf_to_excel from './assets/pdf_to_excel.svg';
import pdf_to_jpg from './assets/pdf_to_jpg.svg';
import pdf_to_png from './assets/pdf_to_png.svg';
import pdf_to_pptx from './assets/pdf_to_pptx.svg';
import pdf_to_word from './assets/pdf_to_word.svg';
import png_to_pdf from './assets/png_to_pdf.svg';
import pptx_to_pdf from './assets/pptx_to_pdf.svg';
import word_to_pdf from './assets/word_to_pdf.svg';
import { mergeImagesPageService } from './mergeImageServiceList';
import { mergePageService } from './mergePageService';

export const converterServicesList = (t: any): IService[] | any[] => {
  return [
    mergePageService(t),
    mergeImagesPageService(t),
    {
      key: 'pdf-to-word',
      serviceType: EServiceType.CONVERTOR,
      path: 'pdf-to-word',
      mainColor: 'dark-blue',
      uploadSectionImage: pdf_to_word,
      uploadSection: {
        from: InternalFileType.PDF,
        to: InternalFileType.DOCX,
        headline: t('landing.pdf_to_word.h1.title'),
        subtitle_1: t('landing.pdf_to_word.h1.subtitle')
      },
      banner: {
        headline: t('landing.pdf_to_word.CTA.title'),
        subtitle: t('landing.pdf_to_word.CTA.subtitle'),
        format: InternalFileType.PDF,
        buttonLabel: t('landing.pdf_to_word.CTA.buttonLabel')
      },
      howTo: {
        headline_1: t('landing.pdf_to_word.how_to.h2'),
        img: how_to_pdf_to_word,
        description: [
          {
            icon: icon_1,
            text: t('landing.pdf_to_word.how_to.first')
          },
          {
            icon: icon_2,
            text: t('landing.pdf_to_word.how_to.second')
          },
          {
            icon: icon_3,
            text: t('landing.pdf_to_word.how_to.third')
          }
        ]
      },
      title: t('meta.pdf_to_word.title'),
      description: t('meta.pdf_to_word.description'),
      fag: {
        question: 'Convert a PDF file to Word online in a few steps!',
        answer:
          '1. To convert a PDF file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PDF document using our tools and convert it to Word with one click. 3. Download or share the Word file.'
      },
      name: t('meta.pdf_to_word.title'),
      nameKey: t('meta.pdf_to_word.title'),
      from: 'PDF',
      to: 'DOCX',
      availableFormats: '.pdf'
    },
    {
      mainColor: 'orange',
      uploadSectionImage: pdf_to_png,
      uploadSection: {
        from: InternalFileType.PDF,
        to: InternalFileType.PNG,
        headline: t('landing.pdf_to_png.h1.title'),
        subtitle_1: t('landing.pdf_to_png.h1.subtitle')
      },
      banner: {
        headline: t('landing.pdf_to_png.CTA.title'),
        subtitle: t('landing.pdf_to_png.CTA.subtitle'),
        format: InternalFileType.PDF,
        buttonLabel: t('landing.pdf_to_png.CTA.buttonLabel')
      },
      howTo: {
        headline_1: t('landing.pdf_to_png.how_to.h2'),
        img: how_to_pdf_to_png,
        description: [
          {
            icon: icon_1,
            text: t('landing.pdf_to_png.how_to.first')
          },
          {
            icon: icon_2,
            text: t('landing.pdf_to_png.how_to.second')
          },
          {
            icon: icon_3,
            text: t('landing.pdf_to_png.how_to.third')
          }
        ]
      },
      title: t('meta.pdf_to_png.title'),
      description: t('meta.pdf_to_png.description'),
      fag: {
        question: 'Convert a PDF file to JPG online in a few steps!',
        answer:
          '1. To convert a PDF file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PDF document using our tools and convert it to JPG with one click. 3. Download or share the JPG file.'
      },
      serviceType: EServiceType.CONVERTOR,
      path: 'pdf-to-png',
      key: 'pdf-to-png',
      name: t('meta.pdf_to_png.title'),
      nameKey: 'landing.pdf_to_png.name',
      from: 'PDF',
      to: 'PNG',
      availableFormats: '.pdf'
    },
    {
      mainColor: 'orange',
      uploadSection: {
        from: InternalFileType.WEBP,
        to: InternalFileType.PNG,
        headline: t('landing.webp_to_jpg.h1.title'),
        subtitle_1: t('landing.webp_to_jpg.h1.subtitle')
      },
      banner: {
        headline: t('landing.webp_to_jpg.CTA.title'),
        subtitle: t('landing.webp_to_jpg.CTA.subtitle'),
        format: InternalFileType.WEBP,
        buttonLabel: t('landing.webp_to_jpg.CTA.buttonLabel')
      },
      howTo: {
        headline_1: t('landing.webp_to_jpg.how_to.h2'),
        img: how_to_pdf_to_png,
        description: [
          {
            icon: icon_1,
            text: t('landing.webp_to_jpg.how_to.first')
          },
          {
            icon: icon_2,
            text: t('landing.webp_to_jpg.how_to.second')
          },
          {
            icon: icon_3,
            text: t('landing.webp_to_jpg.how_to.third')
          }
        ]
      },
      title: t('meta.webp_to_jpg.title'),
      description: t('meta.webp_to_jpg.description'),
      fag: {
        question: 'Convert a WEBP file to JPG online in a few steps!',
        answer:
          '1. To convert a WEBP file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your WEBP file using our tools and convert it to JPG with one click. 3. Download or share the JPG file.'
      },
      serviceType: EServiceType.CONVERTOR,
      path: 'webp-to-jpg',
      key: 'webp-to-jpg',
      name: t('meta.webp_to_jpg.title'),
      nameKey: 'landing.webp_to_jpg.name',
      from: 'WEBP',
      to: 'JPG',
      availableFormats: '.webp'
    },
    {
      mainColor: 'orange',
      uploadSection: {
        from: InternalFileType.JPG,
        to: InternalFileType.WEBP,
        headline: t('landing.jpg_to_webp.h1.title'),
        subtitle_1: t('landing.jpg_to_webp.h1.subtitle')
      },
      banner: {
        headline: t('landing.jpg_to_webp.CTA.title'),
        subtitle: t('landing.jpg_to_webp.CTA.subtitle'),
        format: InternalFileType.JPG,
        buttonLabel: t('landing.jpg_to_webp.CTA.buttonLabel')
      },
      howTo: {
        headline_1: t('landing.jpg_to_webp.how_to.h2'),
        img: how_to_pdf_to_png,
        description: [
          {
            icon: icon_1,
            text: t('landing.jpg_to_webp.how_to.first')
          },
          {
            icon: icon_2,
            text: t('landing.jpg_to_webp.how_to.second')
          },
          {
            icon: icon_3,
            text: t('landing.jpg_to_webp.how_to.third')
          }
        ]
      },
      title: t('meta.jpg_to_webp.title'),
      description: t('meta.jpg_to_webp.description'),
      fag: {
        question: 'Convert a JPG file to WEBP online in a few steps!',
        answer:
          '1. To convert a WEBP file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your WEBP file using our tools and convert it to JPG with one click. 3. Download or share the JPG file.'
      },
      serviceType: EServiceType.CONVERTOR,
      path: 'jpg-to-webp',
      key: 'jpg-to-webp',
      name: t('meta.webp_to_jpg.title'),
      nameKey: 'landing.jpg_to_webp.name',
      from: [InternalFileType.JPG, InternalFileType.JPEG],
      to: 'WEBP',
      availableFormats: '.jpg, .jpeg'
    },
    {
      serviceType: EServiceType.CONVERTOR,
      key: 'pdf-to-epub',
      path: 'pdf-to-epub',
      title: t('meta.pdf_to_epub.title'),
      description: t('meta.pdf_to_epub.description'),
      from: 'PDF',
      to: 'EPUB',
      availableFormats: '.epub',
      mainColor: 'green',
      uploadSectionImage: pdf_to_epub,
      uploadSection: {
        from: InternalFileType.PDF,
        to: InternalFileType.EPUB,
        headline: t('landing.pdf_to_epub.h1.title'),
        subtitle_1: t('landing.pdf_to_epub.h1.subtitle')
      },
      banner: {
        headline: t('landing.pdf_to_epub.CTA.title'),
        subtitle: t('landing.pdf_to_epub.CTA.subtitle'),
        format: InternalFileType.PDF,
        buttonLabel: t('landing.pdf_to_epub.CTA.buttonLabel')
      },
      howTo: {
        headline_1: t('landing.pdf_to_epub.how_to.h2'),
        img: how_to_pdf_to_epub,
        description: [
          {
            icon: icon_1,
            text: t('landing.pdf_to_epub.how_to.first')
          },
          {
            icon: icon_2,
            text: t('landing.pdf_to_epub.how_to.second')
          },
          {
            icon: icon_3,
            text: t('landing.pdf_to_epub.how_to.third')
          }
        ]
      },
      fag: {
        question: 'Convert a PDF file to Word online in a few steps!',
        answer:
          '1. To convert a PDF file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PDF document using our tools and convert it to Word with one click. 3. Download or share the Word file.'
      }
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: 'pdf-to-jpg',
      key: 'pdf-to-jpg',
      uploadSectionImage: pdf_to_jpg,
      name: t('meta.pdf_to_jpg.title'),
      nameKey: 'landing.pdf_to_jpg.name',
      title: t('meta.pdf_to_word.title'),
      description: t('meta.pdf_to_word.description'),
      from: 'PDF',
      to: 'JPG',
      availableFormats: '.pdf',
      mainColor: 'violet',
      uploadSection: {
        from: InternalFileType.PDF,
        to: InternalFileType.JPG,
        headline: t('landing.pdf_to_jpg.h1.title'),
        subtitle_1: t('landing.pdf_to_jpg.h1.subtitle')
      },
      banner: {
        headline: t('landing.pdf_to_jpg.CTA.title'),
        subtitle: t('landing.pdf_to_jpg.CTA.subtitle'),
        format: InternalFileType.PDF,
        buttonLabel: t('landing.pdf_to_jpg.CTA.buttonLabel')
      },
      howTo: {
        headline_1: t('landing.pdf_to_jpg.how_to.h2'),
        img: how_to_pdf_to_jpg,
        description: [
          {
            icon: icon_1,
            text: t('landing.pdf_to_jpg.how_to.first')
          },
          {
            icon: icon_2,
            text: t('landing.pdf_to_jpg.how_to.second')
          },
          {
            icon: icon_3,
            text: t('landing.pdf_to_jpg.how_to.third')
          }
        ]
      },
      fag: {
        question: 'Convert a PDF file to JPG online in a few steps!',
        answer:
          '1. To convert a PDF file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PDF document using our tools and convert it to JPG with one click. 3. Download or share the JPG file.'
      }
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: 'pdf-to-excel',
      key: 'pdf-to-excel',
      uploadSectionImage: pdf_to_excel,
      name: t('meta.pdf_to_excel.title'),
      nameKey: 'landing.pdf_to_excel.name',
      title: t('meta.pdf_to_excel.title'),
      description: t('meta.pdf_to_excel.description'),
      from: 'PDF',
      to: 'XLS',
      availableFormats: '.pdf',
      mainColor: 'green',
      uploadSection: {
        from: InternalFileType.PDF,
        to: InternalFileType.XLS,
        headline: t('landing.pdf_to_excel.h1.title'),
        subtitle_1: t('landing.pdf_to_excel.h1.subtitle')
      },
      banner: {
        headline: t('landing.pdf_to_excel.CTA.title'),
        subtitle: t('landing.pdf_to_excel.CTA.subtitle'),
        format: InternalFileType.PDF,
        buttonLabel: t('landing.pdf_to_excel.CTA.buttonLabel')
      },
      howTo: {
        headline_1: t('landing.pdf_to_excel.how_to.h2'),
        img: how_to_pdf_to_excel,
        description: [
          {
            icon: icon_1,
            text: t('landing.pdf_to_excel.how_to.first')
          },
          {
            icon: icon_2,
            text: t('landing.pdf_to_excel.how_to.second')
          },
          {
            icon: icon_3,
            text: t('landing.pdf_to_excel.how_to.third')
          }
        ]
      },
      fag: {
        question: 'Convert a PDF file to Word online in a few steps!',
        answer:
          '1. To convert a PDF file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PDF document using our tools and convert it to Word with one click. 3. Download or share the Word file.'
      }
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: 'pdf-to-pptx',
      key: 'pdf-to-pptx',
      uploadSectionImage: pdf_to_pptx,
      name: t('meta.pdf_to_pptx.title'),
      nameKey: 'landing.pdf_to_pptx.name',
      from: 'PDF',
      to: 'PPTX',
      availableFormats: '.pdf',
      title: t('meta.pdf_to_pptx.title'),
      description: t('meta.pdf_to_pptx.description'),
      mainColor: 'peach',
      uploadSection: {
        from: InternalFileType.PDF,
        to: InternalFileType.PPTX,
        headline: t('landing.pdf_to_pptx.h1.title'),
        subtitle_1: t('landing.pdf_to_pptx.h1.subtitle')
      },
      banner: {
        headline: t('landing.pdf_to_pptx.CTA.title'),
        subtitle: t('landing.pdf_to_pptx.CTA.subtitle'),
        format: InternalFileType.PDF,
        buttonLabel: t('landing.pdf_to_pptx.CTA.buttonLabel')
      },
      howTo: {
        headline_1: t('landing.pdf_to_pptx.how_to.h2'),
        img: how_to_pdf_to_pptx,
        description: [
          {
            icon: icon_1,
            text: t('landing.pdf_to_pptx.how_to.first')
          },
          {
            icon: icon_2,
            text: t('landing.pdf_to_pptx.how_to.second')
          },
          {
            icon: icon_3,
            text: t('landing.pdf_to_pptx.how_to.third')
          }
        ]
      },
      fag: {
        question: 'Convert a PDF file to Word online in a few steps!',
        answer:
          '1. To convert a PDF file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PDF document using our tools and convert it to Word with one click. 3. Download or share the Word file.'
      }
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: 'word-to-pdf',
      key: 'word-to-pdf',
      uploadSectionImage: word_to_pdf,
      name: t('meta.word_to_pdf.title'),
      nameKey: 'landing.word_to_pdf.name',
      title: t('meta.word_to_pdf.title'),
      description: t('meta.word_to_pdf.description'),
      from: [InternalFileType.DOC, InternalFileType.DOCX],
      to: 'PDF',
      availableFormats: '.doc, .docx',
      mainColor: 'dark-blue',
      uploadSection: {
        from: [InternalFileType.DOC, InternalFileType.DOCX],
        to: InternalFileType.PDF,
        headline: t('landing.word_to_pdf.h1.title'),
        subtitle_1: t('landing.word_to_pdf.h1.subtitle')
      },
      banner: {
        headline: t('landing.word_to_pdf.CTA.title'),
        subtitle: t('landing.word_to_pdf.CTA.subtitle'),
        format: [InternalFileType.DOC, InternalFileType.DOCX],
        buttonLabel: t('landing.word_to_pdf.CTA.buttonLabel')
      },
      howTo: {
        headline_1: t('landing.word_to_pdf.how_to.h2'),
        img: how_to_word_to_pdf,
        description: [
          {
            icon: icon_1,
            text: t('landing.word_to_pdf.how_to.first')
          },
          {
            icon: icon_2,
            text: t('landing.word_to_pdf.how_to.second')
          },
          {
            icon: icon_3,
            text: t('landing.word_to_pdf.how_to.third')
          }
        ]
      },
      fag: {
        question: 'Convert a Word file to PDF online in a few steps!',
        answer:
          '1. To convert Microsoft Word file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your Word document using our tools and convert it to PDF with one click. 3. Download or share the PDF file.'
      }
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: 'png-to-pdf',
      key: 'png-to-pdf',
      name: t('meta.png_to_pdf.title'),
      nameKey: 'landing.png_to_pdf.name',
      title: t('meta.png_to_pdf.title'),
      uploadSectionImage: png_to_pdf,
      description: t('meta.png_to_pdf.description'),
      from: 'PNG',
      to: 'PDF',
      availableFormats: '.png',
      mainColor: 'orange',
      uploadSection: {
        from: InternalFileType.PNG,
        to: InternalFileType.PDF,
        headline: t('landing.png_to_pdf.h1.title'),
        subtitle_1: t('landing.png_to_pdf.h1.subtitle')
      },
      banner: {
        headline: t('landing.png_to_pdf.CTA.title'),
        subtitle: t('landing.png_to_pdf.CTA.subtitle'),
        format: InternalFileType.PNG,
        buttonLabel: t('landing.png_to_pdf.CTA.buttonLabel')
      },
      howTo: {
        headline_1: t('landing.png_to_pdf.how_to.h2'),
        img: how_to_png_to_pdf,
        description: [
          {
            icon: icon_1,
            text: t('landing.png_to_pdf.how_to.first')
          },
          {
            icon: icon_2,
            text: t('landing.png_to_pdf.how_to.second')
          },
          {
            icon: icon_3,
            text: t('landing.png_to_pdf.how_to.third')
          }
        ]
      },
      fag: {
        question: 'Convert a PNG file to PDF online in a few steps!',
        answer:
          '1. To convert PNG file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PNG document using our tools and convert it to PDF with one click. 3. Download or share the PDF file.'
      }
    },
    {
      serviceType: EServiceType.CONVERTOR,
      uploadSectionImage: epub_to_pdf,
      key: 'epub-to-pdf',
      path: '/epub-to-pdf',
      name: t('meta.epub_to_pdf.title'),
      nameKey: 'landing.epub_to_pdf.name',
      title: t('meta.epub_to_pdf.title'),
      description: t('meta.epub_to_pdf.description'),
      from: InternalFileType.EPUB,
      to: 'PDF',
      availableFormats: '.epub',
      mainColor: 'green',
      uploadSection: {
        from: InternalFileType.EPUB,
        to: InternalFileType.PDF,
        headline: t('landing.epub_to_pdf.h1.title'),
        subtitle_1: t('landing.epub_to_pdf.h1.subtitle')
      },
      banner: {
        headline: t('landing.epub_to_pdf.CTA.title'),
        subtitle: t('landing.epub_to_pdf.CTA.subtitle'),
        format: InternalFileType.EPUB,
        buttonLabel: t('landing.epub_to_pdf.CTA.buttonLabel')
      },
      howTo: {
        headline_1: t('landing.epub_to_pdf.how_to.h2'),
        img: how_to_epub_to_pdf,
        description: [
          {
            icon: icon_1,
            text: t('landing.epub_to_pdf.how_to.first')
          },
          {
            icon: icon_2,
            text: t('landing.epub_to_pdf.how_to.second')
          },
          {
            icon: icon_3,
            text: t('landing.epub_to_pdf.how_to.third')
          }
        ]
      },
      fag: {
        question: 'Convert an EPUB file to PDF online in a few steps!',
        answer:
          '1. To convert EPUB file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your EPUB document using our tools and convert it to PDF with one click. 3. Download or share the PDF file.'
      }
    },
    {
      serviceType: EServiceType.CONVERTOR,
      uploadSectionImage: jpg_to_pdf,
      key: 'jpg-to-pdf',
      path: '/jpg-to-pdf',
      name: t('meta.jpg_to_pdf.title'),
      nameKey: 'landing.jpg_to_pdf.name',
      title: t('meta.jpg_to_pdf.title'),
      description: t('meta.jpg_to_pdf.description'),
      from: [InternalFileType.JPG, InternalFileType.JPEG, InternalFileType.HEIC],
      to: 'PDF',
      availableFormats: '.jpg, .jpeg .heic',
      mainColor: 'orange',
      uploadSection: {
        from: [InternalFileType.JPG, InternalFileType.JPEG, InternalFileType.HEIC],
        to: InternalFileType.PDF,
        headline: t('landing.jpg_to_pdf.h1.title'),
        subtitle_1: t('landing.jpg_to_pdf.h1.subtitle')
      },
      banner: {
        headline: t('landing.jpg_to_pdf.CTA.title'),
        subtitle: t('landing.jpg_to_pdf.CTA.subtitle'),
        format: [InternalFileType.JPG, InternalFileType.JPEG],
        buttonLabel: t('landing.jpg_to_pdf.CTA.buttonLabel')
      },
      howTo: {
        headline_1: t('landing.jpg_to_pdf.how_to.h2'),
        img: how_to_jpg_to_pdf,
        description: [
          {
            icon: icon_1,
            text: t('landing.jpg_to_pdf.how_to.first')
          },
          {
            icon: icon_2,
            text: t('landing.jpg_to_pdf.how_to.second')
          },
          {
            icon: icon_3,
            text: t('landing.jpg_to_pdf.how_to.third')
          }
        ]
      },
      fag: {
        question: 'Convert a JPG file to PDF online in a few steps!',
        answer:
          '1. To convert JPG file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your JPG document using our tools and convert it to PDF with one click. 3. Download or share the PDF file.'
      }
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/excel-to-pdf',
      key: 'excel-to-pdf',
      uploadSectionImage: excel_to_pdf,
      name: t('meta.excel_to_pdf.title'),
      nameKey: 'landing.excel_to_pdf.name',
      title: t('meta.excel_to_pdf.title'),
      description: t('meta.excel_to_pdf.description'),
      from: 'XLS',
      to: 'PDF',
      availableFormats: '.xls, .xlsx',
      mainColor: 'green',
      uploadSection: {
        from: [InternalFileType.XLS, InternalFileType.XLSX],
        to: InternalFileType.PDF,
        headline: t('landing.excel_to_pdf.h1.title'),
        subtitle_1: t('landing.excel_to_pdf.h1.subtitle')
      },
      banner: {
        headline: t('landing.excel_to_pdf.CTA.title'),
        subtitle: t('landing.excel_to_pdf.CTA.subtitle'),
        format: [InternalFileType.XLS, InternalFileType.XLSX],
        buttonLabel: t('landing.excel_to_pdf.CTA.buttonLabel')
      },
      howTo: {
        headline_1: t('landing.excel_to_pdf.how_to.h2'),
        img: how_to_excel_to_pdf,
        description: [
          {
            icon: icon_1,
            text: t('landing.excel_to_pdf.how_to.first')
          },
          {
            icon: icon_2,
            text: t('landing.excel_to_pdf.how_to.second')
          },
          {
            icon: icon_3,
            text: t('landing.excel_to_pdf.how_to.third')
          }
        ]
      },
      fag: {
        question: 'Convert an Excel file to PDF online in a few steps!',
        answer:
          '1. To convert Excel file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your Excel document using our tools and convert it to PDF with one click. 3. Download or share the PDF file.'
      }
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pptx-to-pdf',
      key: 'pptx-to-pdf',
      uploadSectionImage: pptx_to_pdf,
      name: t('meta.pptx_to_pdf.title'),
      nameKey: 'landing.pptx_to_pdf.name',
      title: t('meta.pptx_to_pdf.title'),
      description: t('meta.pptx_to_pdf.description'),
      from: 'PPTX',
      to: 'PDF',
      availableFormats: '.pptx, .ppt',
      mainColor: 'peach',
      uploadSection: {
        from: InternalFileType.PPTX,
        to: InternalFileType.PDF,
        headline: t('landing.pptx_to_pdf.h1.title'),
        subtitle_1: t('landing.pptx_to_pdf.h1.subtitle')
      },
      banner: {
        headline: t('landing.pptx_to_pdf.CTA.title'),
        subtitle: t('landing.pptx_to_pdf.CTA.subtitle'),
        format: InternalFileType.PPTX,
        buttonLabel: t('landing.pptx_to_pdf.CTA.buttonLabel')
      },
      howTo: {
        headline_1: t('landing.pptx_to_pdf.how_to.h2'),
        img: how_to_pptx_to_pdf,
        description: [
          {
            icon: icon_1,
            text: t('landing.pptx_to_pdf.how_to.first')
          },
          {
            icon: icon_2,
            text: t('landing.pptx_to_pdf.how_to.second')
          },
          {
            icon: icon_3,
            text: t('landing.pptx_to_pdf.how_to.third')
          }
        ]
      },
      fag: {
        question: 'Convert a PPTX file to PDF online in a few steps!',
        answer:
          '1. To convert PPTX file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PPTX document using our tools and convert it to PDF with one click. 3. Download or share the PDF file.'
      }
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/image-to-pdf',
      key: 'image-to-pdf',
      uploadSectionImage: image_to_pdf,
      name: t('meta.image_to_pdf.title'),
      nameKey: 'landing.image_to_pdf.name',
      title: t('meta.image_to_pdf.title'),
      description: t('meta.image_to_pdf.description'),
      from: [
        InternalFileType.SVG,
        InternalFileType.HEIC,
        InternalFileType.PNG,
        InternalFileType.BMP,
        InternalFileType.EPS,
        InternalFileType.GIF,
        InternalFileType.TIFF,
        InternalFileType.WEBP,
        InternalFileType.JPG,
        InternalFileType.JPEG
      ],
      to: InternalFileType.PDF,
      availableFormats: '.jpg, .jpeg, .png, .gif, .webp, .bmp',
      mainColor: 'orange',
      uploadSection: {
        from: [
          InternalFileType.HEIC,
          InternalFileType.SVG,
          InternalFileType.PNG,
          InternalFileType.BMP,
          InternalFileType.EPS,
          InternalFileType.GIF,
          InternalFileType.TIFF,
          InternalFileType.WEBP,
          InternalFileType.JPG,
          InternalFileType.JPEG
        ],
        to: InternalFileType.PDF,
        headline: t('landing.image_to_pdf.h1.title'),
        subtitle_1: t('landing.image_to_pdf.h1.subtitle')
      },
      banner: {
        headline: t('landing.image_to_pdf.CTA.title'),
        subtitle: t('landing.image_to_pdf.CTA.subtitle'),
        format: [
          InternalFileType.SVG,
          InternalFileType.HEIC,
          InternalFileType.PNG,
          InternalFileType.BMP,
          InternalFileType.EPS,
          InternalFileType.GIF,
          InternalFileType.TIFF,
          InternalFileType.WEBP,
          InternalFileType.JPG,
          InternalFileType.JPEG
        ],
        buttonLabel: t('landing.image_to_pdf.CTA.buttonLabel')
      },
      howTo: {
        headline_1: t('landing.image_to_pdf.how_to.h2'),
        img: how_to_image_to_pdf,
        description: [
          {
            icon: icon_1,
            text: t('landing.image_to_pdf.how_to.first')
          },
          {
            icon: icon_2,
            text: t('landing.image_to_pdf.how_to.second')
          },
          {
            icon: icon_3,
            text: t('landing.image_to_pdf.how_to.third')
          }
        ]
      },
      fag: {
        question: 'Convert a Image to PDF online in a few steps!',
        answer:
          '1. To convert Image, upload it using the button "Upload" above or Drag&Drop. 2. Edit your Image document using our tools and convert it to PDF with one click. 3. Download or share the PDF file.'
      }
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/heic-to-pdf',
      key: 'heic-to-pdf',
      uploadSectionImage: pdf_converter,
      name: t('meta.heic_to_pdf.title'),
      nameKey: 'landing.heic_to_pdf.name',
      title: t('meta.heic_to_pdf.title'),
      description: t('meta.heic_to_pdf.description'),
      from: [
        InternalFileType.SVG,
        InternalFileType.HEIC,
        InternalFileType.PNG,
        InternalFileType.BMP,
        InternalFileType.EPS,
        InternalFileType.GIF,
        InternalFileType.TIFF,
        InternalFileType.WEBP,
        InternalFileType.JPG,
        InternalFileType.JPEG
      ],
      to: InternalFileType.PDF,
      availableFormats: '.jpg, .jpeg, .png, .gif, .webp, .bmp, .heic',
      mainColor: 'violet',
      uploadSection: {
        from: [
          InternalFileType.HEIC,
          InternalFileType.SVG,
          InternalFileType.PNG,
          InternalFileType.BMP,
          InternalFileType.EPS,
          InternalFileType.GIF,
          InternalFileType.TIFF,
          InternalFileType.WEBP,
          InternalFileType.JPG,
          InternalFileType.JPEG
        ],
        to: InternalFileType.PDF,
        headline: t('landing.heic_to_pdf.h1.title'),
        subtitle_1: t('landing.heic_to_pdf.h1.subtitle')
      },
      banner: {
        headline: t('landing.heic_to_pdf.CTA.title'),
        subtitle: t('landing.heic_to_pdf.CTA.subtitle'),
        format: [
          InternalFileType.SVG,
          InternalFileType.HEIC,
          InternalFileType.PNG,
          InternalFileType.BMP,
          InternalFileType.EPS,
          InternalFileType.GIF,
          InternalFileType.TIFF,
          InternalFileType.WEBP,
          InternalFileType.JPG,
          InternalFileType.JPEG
        ],
        buttonLabel: t('landing.heic_to_pdf.CTA.buttonLabel')
      },
      howTo: {
        headline_1: t('landing.heic_to_pdf.how_to.h2'),
        img: how_to_heic_to_pdf,
        description: [
          {
            icon: icon_1,
            text: t('landing.heic_to_pdf.how_to.first')
          },
          {
            icon: icon_2,
            text: t('landing.heic_to_pdf.how_to.second')
          },
          {
            icon: icon_3,
            text: t('landing.heic_to_pdf.how_to.third')
          }
        ]
      },
      fag: {
        question: 'Convert a Image to PDF online in a few steps!',
        answer:
          '1. To convert Image, upload it using the button "Upload" above or Drag&Drop. 2. Edit your Image document using our tools and convert it to PDF with one click. 3. Download or share the PDF file.'
      }
    }
  ];
};
