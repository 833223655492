import React from 'react';

import logo_new from 'assets/img/new-logo-pdf-guru.svg';
import classNames from 'classnames';

export const LogoNewDesign: React.FC<{ height?: number; width?: number; className?: string }> = ({
  height,
  width,
  className
}) => {
  return (
    <img
      src={logo_new}
      alt='PDFGuru logo'
      className={classNames('w-[123px] h-[32px]', className)}
      height={height}
      width={width}
    />
  );
};
