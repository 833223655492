export enum EReduxTypes {
  CREATE_ANONYMOUS_USER = 'CREATE_ANONYMOUS_USER',
  CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD',
  CHANGE_USER_EMAIL = 'CHANGE_USER_EMAIL',
  SET_USER_EMAIL = 'SET_USER_EMAIL',
  SIGN_UP = 'SIGN_UP',
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  GOOGLE_AUTH = 'GOOGLE_AUTH',
  UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
  GET_DOCUMENTS = 'GET_DOCUMENTS',
  GET_CONVERTED_FILE = 'GET_CONVERTED_FILE',
  GET_OPTIMIZED_FILE = 'GET_OPTIMIZED_FILE',
  GET_SPLITTED_FILE = 'GET_SPLITTED_FILE',
  GET_MERGED_FILE = 'GET_MERGED_FILE',
  GET_DOCUMENT_BY_ID = 'GET_DOCUMENT_BY_ID',
  GET_TRANSLATED_FILE = 'GET_TRANSLATED_FILE',
  DELETE_DOCUMENT_BY_ID = 'DELETE_DOCUMENT_BY_ID',
  GET_UPLOAD_LINK = 'GET_UPLOAD_LINK',
  DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT',
  DOWNLOAD_DOCUMENT_AND_UPDATE_DOCUMENT_ID = 'DOWNLOAD_DOCUMENT_AND_UPDATE_DOCUMENT_ID',
  DOWNLOAD_AND_CONVERT_TO_BUFFER = 'DOWNLOAD_AND_CONVERT_TO_BUFFER',
  CONVERT_DOCUMENT = 'CONVERT_DOCUMENT',
  GET_CONVERT_DOCUMENT = 'GET_CONVERT_DOCUMENT',
  MERGE_DOCUMENT = 'MERGE_DOCUMENT',
  CONVERT_EXISTING_DOCUMENT = 'CONVERT_EXISTING_DOCUMENT',
  SPLIT_EXISTING_DOCUMENT = 'SPLIT_EXISTING_DOCUMENT',
  SPLIT_DOCUMENT = 'SPLIT_DOCUMENT',
  UPLOAD_EDIT_DOCUMENT = 'UPLOAD_EDIT_DOCUMENT',
  SAVE_EDIT_DOCUMENT = 'SAVE_EDIT_DOCUMENT',
  UPDATE_EDIT_FILENAME = 'UPDATE_EDIT_FILENAME',
  SET_CONVERT_DOCUMENT_DATA = 'SET_CONVERT_DOCUMENT_DATA',
  SET_MERGE_DOCUMENT_DATA = 'SET_MERGE_DOCUMENT_DATA',
  UPDATE_MERGE_DOCUMENT_IMAGE = 'UPDATE_MERGE_DOCUMENT_IMAGE',
  PUSH_MERGE_DOCUMENT_DATA = 'PUSH_MERGE_DOCUMENT_DATA',
  SET_EDIT_DOCUMENT_DATA = 'SET_EDIT_DOCUMENT_DATA',
  UPDATE_EDIT_DOCUMENT_DATA = 'UPDATE_EDIT_DOCUMENT_DATA',
  UPDATE_CONVERT_DOCUMENT_DATA = 'UPDATE_CONVERT_DOCUMENT_DATA',
  SET_PDF_FILE_CONTENT = 'SET_PDF_FILE_CONTENT',
  GET_PLANS = 'GET_PLANS',
  GET_USER_COUNTRY = 'GET_USER_COUNTRY',
  INIT_PAYMENT_CARD = 'INIT_PAYMENT_CARD',
  INIT_USER_SUBSCRIPTION = 'INIT_USER_SUBSCRIPTION',
  UPDATE_DOWNLOAD_PROGRESS = 'UPDATE_DOWNLOAD_PROGRESS',
  CONVERT_ANONYMOUS = 'CONVERT_ANONYMOUS',
  UPDATE_LOADING_COMPONENT = 'UPDATE_LOADING_COMPONENT',
  GET_USER_CREDITS = 'GET_USER_CREDITS',
  CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT',
  PASSWORD_RESET = 'PASSWORD_RESET',
  SEND_FORGOT_PASSWORD = 'SEND_FORGOT_PASSWORD',
  GET_USER = 'GET_USER',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
  GET_USER_SUBSCRIPTION = 'GET_USER_SUBSCRIPTION',
  GET_ORDER_LIST = 'GET_ORDER_LIST',
  SET_SELECTED_PLAN_ID = 'SET_SELECTED_PLAN_ID',
  CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
  RESTORE_SUBSCRIPTION = 'RESTORE_SUBSCRIPTION',
  SET_ORDER_DATA = 'SET_ORDER_DATA',
  SET_PAGE_VIEW = 'SET_PAGE_VIEW',
  GET_TRUST_PILOT_LINK = 'GET_TRUST_PILOT_LINK',
  SWITCH_SUBSCRIPTION = 'SWITCH_SUBSCRIPTION',
  GET_FREE_ACCESS = 'GET_FREE_ACCESS',
  CONVERTING_EVENT = 'CONVERTING_EVENT',
  SEND_ANALYTIC_EVENT = 'SEND_ANALYTIC_EVENT',
  SIGN_UP_WITH_GOOGLE = 'SIGN_UP_WITH_GOOGLE',
  GET_OCRED_FILE = 'GET_OCRED_FILE',
  PARSE_TEXT_FROM_DOCUMENT = 'PARSE_TEXT_FROM_DOCUMENT',
  SET_SELECTED_FILE = 'SET_SELECTED_FILE',
  SET_AB_TESTS = 'SET_AB_TESTS',
  UPDATE_FILENAME = 'EDIT_FILENAME',
  SAVE_UPLOADED_FILE = 'SAVE_UPLOADED_FILE',
  SHARE_FILE_LINK = 'SHARE_FILE_LINK',
  SEND_FILE_VIA_EMAIL = 'SEND_FILE_VIA_EMAIL',
  GET_INVOICES = 'GET_INVOICES',
  GET_INVOICE_URL = 'GET_INVOICE_URL',
  SET_SELECTED_FILES = 'SET_SELECTED_FILES',
  SET_QUERY_USER_ID = 'SET_QUERY_USER_ID',
  TRANSLATE_DOCUMENT = 'TRANSLATE_DOCUMENT',
  GET_TRANSLATED_DOCUMENT = 'GET_TRANSLATED_DOCUMENT',
  GET_SUPPORTED_LANGUAGES = 'GET_SUPPORTED_LANGUAGES',
  SET_TRANSLATE_DOCUMENT_DATA = 'SET_TRANSLATE_DOCUMENT_DATA',
  SET_DOCUMENT_FOR_TRANSLATE = 'SET_DOCUMENT_FOR_TRANSLATE',
  TRANSLATE_FIRST_PAGE = 'TRANSLATE_FIRST_PAGE',
  UPDATE_EDIT_MULTIPLE_DOCUMENT_DATA = 'UPDATE_EDIT_MULTIPLE_DOCUMENT_DATA',
  SET_EDIT_MULTIPLE_DOCUMENT_DATA = 'SET_EDIT_MULTIPLE_DOCUMENT_DATA',
  PUSH_EDIT_MULTIPLE_DOCUMENT_DATA = 'PUSH_EDIT_MULTIPLE_DOCUMENT_DATA',
  SET_PREPARED_FILE_FROM_EDITOR = 'SET_PREPARED_FILE_FROM_EDITOR',
  CONVERT_MULTIPLE_DOCUMENTS = 'CONVERT_MULTIPLE_DOCUMENTS',
  SIGN_IN_WITH_GOOGLE = 'SIGN_IN_WITH_GOOGLE',
  SET_CHATS_HISTORY = 'SET_CHATS_HISTORY',
  UPDATE_MULTIPLE_LOADING_COMPONENTS_WITH_ID = 'UPDATE_MULTIPLE_LOADING_COMPONENTS_WITH_ID',
  SET_FILE_ID = 'SET_FILE_ID',
  SET_CURRENT_THREAD_ID = 'SET_CURRENT_THREAD_ID',
  SET_CURRENT_THREAD_ASSISTANT_MESSAGE = 'SET_CURRENT_THREAD_ASSISTANT_MESSAGE',
  SET_CURRENT_THREAD_USER_MESSAGE = 'SET_CURRENT_THREAD_USER_MESSAGE',
  REMOVE_CURRENT_THREAD_DATA = 'REMOVE_CURRENT_THREAD_DATA',
  SET_CURRENT_FILE_URL = 'SET_CURRENT_FILE_URL',
  SET_NEW_FILE_DATA = 'SET_NEW_FILE_DATA',
  SET_FILE_NAME = 'SET_FILE_NAME',
  SET_MOBILE_MENU_OPEN = 'SET_MOBILE_MENU_OPEN',
  SET_FREE_LIMIT_REACHED = 'SET_FREE_LIMIT_REACHED',
  LOGIN_BY_TOKEN = 'LOGIN_BY_TOKEN',
  SET_QUERY_USER_COUNTRY = 'SET_QUERY_USER_COUNTRY',
  SET_USER_COUNTRY = 'SET_USER_COUNTRY'
}
