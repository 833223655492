import type { FC } from 'react';

export const ArrowDownIcon: FC = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.72572 9.00014C6.54022 9.00014 6.35198 9.0723 6.21127 9.21301C5.92958 9.4947 5.92958 9.96049 6.21127 10.2422L11.4824 15.5133C11.6184 15.6494 11.8104 15.7262 11.9968 15.7262C12.1816 15.7262 12.3657 15.659 12.5113 15.5133L17.7752 10.2489C18.0701 9.9714 18.0739 9.49461 17.7922 9.21292C17.5105 8.93123 17.0447 8.93123 16.763 9.21292L16.7629 9.2131L12.0063 13.9791L7.23999 9.21287C7.09902 9.0719 6.91079 9 6.72554 9L6.72572 9.00014Z'
        fill='currentColor'
      />
    </svg>
  );
};
