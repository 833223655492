import { Fragment, useEffect, useState } from 'react';

import { useDragLayer } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';

import plusBig from 'assets/img/icons/plus-big.svg';
import plusIcon from 'assets/img/icons/plus.svg';
import classNames from 'classnames';
import { mergeImagesPageService } from 'helpers/services/mergeImageServiceList';

import UploadButtonMerge from 'components/uploadButtons/uploadButtonMerge';

import { setMergeDocumentData } from 'data/actions/documents';
import { mergeDocumentsListSelector } from 'data/selectors/documents';

import { getFileNameWithoutFormatFromFile } from 'utils/getFileFormatFromFile';

import type { IMergeDocument } from 'ts/interfaces/documents/document';

import { MAX_FILES_ALLOWED } from '..';
import FileItem from './fileItem';

const FILE_COLORS = [
  'bg-[#FFE6E6]',
  'bg-[#DBF7FA]',
  'bg-[#E8F3FD]',
  'bg-[#FEEFDB]',
  'bg-[#EDEAFF]',
  'bg-[#FCE8FF]',
  'bg-[#E8F8F2]'
];

const FileList = () => {
  const isDraggingGlobal = useDragLayer((monitor) => monitor.isDragging());
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filesList: IMergeDocument[] = useSelector(mergeDocumentsListSelector());
  const [filesColors, setFilesColors] = useState<Record<string, string>>({});
  const [lastSelectedColor, setLastSelectedColor] = useState<string>(FILE_COLORS[FILE_COLORS.length - 1]);

  const moveItem = (fromIndex: number, toIndex: number) => {
    const updatedFiles = structuredClone(filesList);
    const [movedFile] = updatedFiles.splice(fromIndex, 1);
    updatedFiles.splice(toIndex, 0, movedFile);
    dispatch(setMergeDocumentData([...updatedFiles]));
  };

  useEffect(() => {
    let lastSelectedColorIndex = FILE_COLORS.findIndex((value) => value === lastSelectedColor) || 0;
    const newFilesColors = filesList.reduce((acc, file) => {
      if (acc[file.hashId]) return acc;

      const newColorIndex = (lastSelectedColorIndex + 1) % FILE_COLORS.length;

      acc[file.hashId] = FILE_COLORS[newColorIndex];

      lastSelectedColorIndex = newColorIndex;

      return acc;
    }, filesColors);

    setLastSelectedColor(FILE_COLORS[lastSelectedColorIndex]);
    setFilesColors(newFilesColors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesList]);

  return (
    <div className='flex flex-row flex-wrap gap-0 p-4'>
      {filesList.map((item, index) => (
        <Fragment key={item.hashId}>
          <FileItem
            index={index}
            moveItem={moveItem}
            name={getFileNameWithoutFormatFromFile(item?.file)}
            thumbnail={item?.thumbnail}
            file={item?.file}
            isDraggingGlobal={isDraggingGlobal}
            color={filesColors[item.hashId]}
          />
          <div
            className={classNames(
              'w-[45px]  h-[269px] border-dashed rounded-lg flex items-center justify-center',
              filesList && filesList?.length >= MAX_FILES_ALLOWED && 'opacity-50 cursor-not-allowed'
            )}
          >
            <UploadButtonMerge
              isDisabled={filesList && filesList?.length >= MAX_FILES_ALLOWED}
              service={mergeImagesPageService(t)}
              buttonText={t('complete_merge.add_file')}
              dataTestId={`insert-file-button-${index}`}
              insertAtIndex={index + 1}
              placeForAnalytics='button'
              customButton={
                <button
                  disabled={filesList && filesList?.length >= MAX_FILES_ALLOWED}
                  className='flex w-8 aspect-square justify-center items-center bg-[#F6F6F6] rounded-lg hover:bg-[#E6E6E6] transition-all disabled:hover:bg-[#F6F6F6] disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none'
                >
                  <LazyLoadImage
                    width={16}
                    height={16}
                    src={plusIcon}
                    alt='plus'
                  />
                </button>
              }
            />
          </div>
        </Fragment>
      ))}
      <div className='flex justify-center items-center'>
        <UploadButtonMerge
          isDisabled={filesList && filesList?.length >= MAX_FILES_ALLOWED}
          service={mergeImagesPageService(t)}
          buttonText={t('complete_merge.add_file')}
          dataTestId='add-file-button-placeholder'
          placeForAnalytics='file_page'
          customButton={
            <button
              className={classNames(
                'w-[164px] h-[250px] border-dashed rounded-lg flex items-center justify-center border border-[#1D1D1D] ml-2 transition-all  group hover:bg-[#F6F6F6] hover:border-[#1D1D1D]',
                filesList && filesList?.length >= MAX_FILES_ALLOWED && 'opacity-50 cursor-not-allowed',
                filesList && filesList?.length === 0 && 'ml-12'
              )}
            >
              <div className='flex flex-col text-[11px] leading-none text-[#616161] group-hover:text-[#1D1D1D] h-full justify-center items-center gap-[18px]'>
                <LazyLoadImage
                  src={plusBig}
                  alt='plus big'
                />
                {t('complete_merge.add_file')}
              </div>
            </button>
          }
        />
      </div>
    </div>
  );
};

export default FileList;
