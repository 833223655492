import { useEffect, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import classNames from 'classnames';

import convertIcon from '../assets/convert-icon.svg';
import editTextIcon from '../assets/edit-text-icon.svg';
import printIcon from '../assets/print-icon.svg';
import shareIcon from '../assets/share-icon.svg';
import signIcon from '../assets/sign-icon.svg';

interface IBanner {
  id: number;
  bg: string;
  badgeBg: string;
  text: JSX.Element;
  icon: string;
}

const banners: IBanner[] = [
  {
    id: 0,
    bg: 'bg-[#E25F49]',
    badgeBg: 'bg-[#EB8F80]',
    text: <Trans i18nKey='popups.processing_edit_pdf_popup.banner.0' />,
    icon: shareIcon
  },
  {
    id: 1,
    bg: 'bg-[#008554]',
    badgeBg: 'bg-[#4CAA87]',
    text: <Trans i18nKey='popups.processing_edit_pdf_popup.banner.1' />,
    icon: editTextIcon
  },
  {
    id: 2,
    bg: 'bg-[#2E0B90]',
    badgeBg: 'bg-[#9B7BF3]',
    text: <Trans i18nKey='popups.processing_edit_pdf_popup.banner.2' />,
    icon: printIcon
  },
  {
    id: 3,
    bg: 'bg-[#A110B9]',
    badgeBg: 'bg-[#BD58CE]',
    text: <Trans i18nKey='popups.processing_edit_pdf_popup.banner.3' />,
    icon: convertIcon
  },
  {
    id: 4,
    bg: 'bg-[#192CD2]',
    badgeBg: 'bg-[#5E6BE0]',
    text: <Trans i18nKey='popups.processing_edit_pdf_popup.banner.4' />,
    icon: signIcon
  }
];

const Banner = () => {
  const { t } = useTranslation();
  const [selectedBanner, setSelectedBanner] = useState<IBanner | null>(null);

  useEffect(() => {
    setSelectedBanner(banners[Math.floor(Math.random() * banners.length)] || banners[0]);
  }, []);

  return (
    <div
      className={classNames(
        'w-full px-4 py-3 rounded-lg flex tablet:flex-row flex-col items-center  gap-6 text-white',
        selectedBanner?.bg
      )}
    >
      <div className='flex-shrink-0'>
        <img
          src={selectedBanner?.icon}
          alt='banner icon'
          className='w-[250px] h-[160px] tablet:!w-[150px] tablet:!h-[99px]'
        />
      </div>
      <div className='flex flex-col gap-2 justify-center items-start'>
        <div
          className={classNames(
            'text-[8px] font-bold uppercase leading-none rounded-md p-1 flex items-center justify-center',
            selectedBanner?.badgeBg
          )}
        >
          <span className='mt-[2px]'>{t('popups.processing_edit_pdf_popup.badge_text')}</span>
        </div>
        <div className='font-bold text-[16px] leading-[130%]'>{selectedBanner?.text}</div>
      </div>
    </div>
  );
};

export default Banner;
