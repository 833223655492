import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import configureStore from 'data/store';

import App from './App';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={configureStore()}>
    <App />
  </Provider>
);
