import { useDispatch, useSelector } from 'react-redux';

import * as pdfjsLib from 'pdfjs-dist';
import axios, { HttpStatusCode } from 'axios';
import { countPdfPages } from 'helpers/countPdfPages';
import { getFileKeyFromAWSLink } from 'helpers/getFileKeyFromAWSLink';
import { useLocaleNavigate } from 'hooks/useLocaleNavigate';
import i18n from 'i18n';

import { Analytics } from 'services/analytics';

import { sendAnalyticEvent } from 'data/actions/analytics';
import {
  downloadDocument,
  getParsedTextFromFile,
  getUploadLink,
  parseTextFromDocument,
  setConvertDocumentData,
  updateDownloadProgress,
  updateEditDocumentData
} from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import {
  isUserAuthenticated,
  userDataSelector,
  userEmailSelector,
  userSubscriptionSelector
} from 'data/selectors/user';

import { formatFileSize } from 'utils/formatFileSize';
import showToast from 'utils/toast';

import { GET_RESULT_COUNT_RETRIES } from 'ts/constants/general';
import { PAGE_LINKS } from 'ts/constants/page-links';
import { FileType } from 'ts/enums/internal.file';
import { EModalTypes } from 'ts/enums/modal.types';
import { UserStatus } from 'ts/enums/user.status';
import type { IConvertDocumentDto, IOCRDocumentDto, SupportedOcrExportFormat } from 'ts/interfaces/documents/document';
import { OcrExportFormatEnum } from 'ts/interfaces/documents/document';
import type { IService } from 'ts/interfaces/services/service';
import { EServiceType } from 'ts/interfaces/services/service';

const EXPORT_FORMAT_OCR_MAP: Record<SupportedOcrExportFormat, FileType> = {
  [OcrExportFormatEnum.docx]: FileType.DOCX,
  [OcrExportFormatEnum.pdfSearchable]: FileType.PDF,
  [OcrExportFormatEnum.txt]: FileType.TXT
};

const useFileUploadAndOCR = ({ service }: { service?: IService }) => {
  const navigate = useLocaleNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector(isUserAuthenticated);
  const userSubscription = useSelector(userSubscriptionSelector);
  const email = useSelector(userEmailSelector);
  const user = useSelector(userDataSelector);

  // after upload a file run the progress animation

  const analyticsEventUpload = ({
    success,
    size,
    errorCode,
    fileCounter,
    from,
    pages_counter
  }: {
    success: boolean;
    size: number;
    from: string;
    errorCode?: number;
    fileCounter?: number;
    pages_counter?: number;
  }) => {
    dispatch(
      sendAnalyticEvent({
        event: 'file_upload_status',
        data: {
          status: success ? 'success' : 'fail',
          place: 'additional',
          errorCode,
          size: formatFileSize(size),
          accurate_size: size / 1000000,
          fileCounter: fileCounter || 1,
          file_format: `.${from?.toLowerCase() || '.pdf'}`,
          is_validation_error: success ? 'false' : 'true',
          pages_counter: pages_counter || 1
        }
      })
    );
  };

  const handleParseTextFromDocument = (
    dataToConvert: IOCRDocumentDto,
    signUp?: boolean,
    subscription?: any,
    fileFormat?: string
  ) => {
    const onSuccess = (res: any) => {
      let countRetries = 0;

      const interval = setInterval(() => {
        const onSuccessCallback = (res: any) => {
          clearInterval(interval);

          // awaiting the downloading animation
          setTimeout(() => {
            if (res?.processing_status === 'FAILED')
              return dispatch(
                toggleModal({
                  type: EModalTypes.FILE_UPLOAD_ERROR,
                  visible: true
                })
              );

            dispatch(toggleModal({ visible: false }));

            dispatch(downloadDocument(res?.id));
          }, 1000);
        };
        countRetries += 1;
        if (countRetries < GET_RESULT_COUNT_RETRIES) dispatch(getParsedTextFromFile(res?.fileId, onSuccessCallback));
        else {
          clearInterval(interval);
          dispatch(toggleModal({ visible: true, type: EModalTypes.FILE_UPLOAD_ERROR }));
        }
      }, 1500);
    };

    const onFailed = (error: any) => {
      dispatch(updateDownloadProgress(100));

      setTimeout(() => {
        if (error?.response?.data?.message === 'error.convert.unsupported-formats') {
          dispatch(toggleModal({ visible: true, type: EModalTypes.FILE_UPLOAD_ERROR }));
          dispatch(updateDownloadProgress(0));
          return;
        }

        if (error?.response?.status === HttpStatusCode.PaymentRequired) {
          dispatch(toggleModal({ visible: false }));
          dispatch(updateDownloadProgress(0));
          navigate(PAGE_LINKS.CHOOSING_PLAN);
          window.scrollTo(0, 0); // Scrolls to the top of the page
          return;
        }

        dispatch(toggleModal({ visible: true, type: EModalTypes.FILE_UPLOAD_ERROR }));
        dispatch(updateDownloadProgress(0));
      }, 1000);
    };

    if (!userSubscription && !subscription?.id && user?.status !== UserStatus.TEMPORARY) {
      dispatch(updateDownloadProgress(100));
      return setTimeout(
        () => {
          dispatch(parseTextFromDocument(dataToConvert, fileFormat || dataToConvert.to || '', onSuccess, onFailed));
        },
        signUp ? 0 : 2000
      );
    }

    dispatch(parseTextFromDocument(dataToConvert, fileFormat ?? '', onSuccess, onFailed));
  };

  const handleConvertFile = (dataToConvert: IOCRDocumentDto) => {
    if (!isAuth) {
      setTimeout(() => {
        dispatch(updateDownloadProgress(100));
      }, 500);
      return setTimeout(() => {
        dispatch(
          toggleModal({
            type: EModalTypes.ENTER_EMAIL_ADDRESS,
            visible: true,
            options: {
              handleConvertDocument: (subscription: any) =>
                handleParseTextFromDocument(dataToConvert, true, subscription),
              signUp: true,
              servicePath: service?.path
            }
          })
        );
      }, 1000);
    }
    handleParseTextFromDocument(dataToConvert);
  };

  const isFilePasswordProtected = async (file: File): Promise<boolean> => {
    try {
      if (file.type === 'application/pdf') {
        const arrayBuffer = await file.arrayBuffer();
        const pdf = await pdfjsLib
          .getDocument({
            data: arrayBuffer,
            password: ''
          })
          .promise.catch((error: any) => {
            return error.name === 'PasswordException';
          });

        return typeof pdf === 'boolean' ? pdf : false;
      }
      return false;
    } catch {
      return false;
    }
  };

  const handleUploadFileByLinkToS3 = async (
    file: File,
    uploadLink: string,
    to?: SupportedOcrExportFormat,
    filename?: string
  ) => {
    Analytics.sendEvent({
      event: 'upload_link_received'
    });
    try {
      const from = file?.name?.split('.')?.pop()?.toUpperCase() || service?.from || 'PDF';

      const secondaryType = from === 'PDF' ? to || service?.to || 'PNG' : from;

      dispatch(updateDownloadProgress(0));
      dispatch(
        toggleModal({
          type: EModalTypes.PROGRESS_EDIT_FILE,
          visible: true,
          options: { file, secondaryType }
        })
      );

      const res = await axios.put(uploadLink, file, { headers: {} });
      const pagesCount = from === 'PDF' ? await countPdfPages(file) : 1;

      const dataToConvert: IOCRDocumentDto = {
        filename: filename ?? file.name,
        size: file.size,
        key: getFileKeyFromAWSLink(res?.request?.responseURL),
        url: res?.request?.responseURL,
        exportFormat: to || 'docx',
        serviceType: EServiceType.OCR,
        to: EXPORT_FORMAT_OCR_MAP[to || 'docx'],
        from,
        pages_counter: pagesCount
      };
      Analytics.sendEvent({
        event: 'upload_file_to_bucket',
        data: {
          status: 'success',
          filename: file?.name
        }
      });
      // awaiting the downloading animation
      setTimeout(() => {
        dispatch(updateEditDocumentData(dataToConvert));
        dispatch(setConvertDocumentData(dataToConvert as IConvertDocumentDto));

        // set converted data to localStorage and use this data for google auth
        localStorage.setItem(
          'dataToConvert',
          JSON.stringify({
            file: dataToConvert,
            service: service?.path,
            email: email,
            serviceType: EServiceType.OCR,
            key: getFileKeyFromAWSLink(res?.request?.responseURL),
            filename: filename ?? file.name,
            to: EXPORT_FORMAT_OCR_MAP[to || 'docx']
          })
        );

        localStorage.setItem(
          'dataToEdit',
          JSON.stringify({
            file: dataToConvert,
            service: service?.path,
            email: email,
            serviceType: EServiceType.OCR,
            key: getFileKeyFromAWSLink(res?.request?.responseURL),
            filename: filename ?? file.name,
            to: EXPORT_FORMAT_OCR_MAP[to || 'docx']
          })
        );

        handleConvertFile(dataToConvert);
        analyticsEventUpload({ size: file?.size, success: true, from, pages_counter: pagesCount });
      }, 3000);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // upload to s3 bucket and convert file
  const handleUploadFile = async (file: File, to?: SupportedOcrExportFormat, filename?: string) => {
    if (!file) return;

    const isProtected = await isFilePasswordProtected(file);
    if (isProtected) {
      showToast('error', i18n.t('notifications.file_is_password_protected'), 5000, 'file-is-password-protected');
      return;
    }

    dispatch(
      sendAnalyticEvent({
        event: 'file_from_provider_chosen',
        data: {
          features_name: service?.path?.replace('/', '') || '',
          method: 'click'
        }
      })
    );

    dispatch(
      getUploadLink({
        filename: filename ?? file?.name,
        onSuccess: (res: any) => {
          handleUploadFileByLinkToS3(file, res[0]?.url, to, filename);
        },
        service
      })
    );
  };

  return {
    handleUploadFile,
    handleParseTextFromDocument
  };
};

export default useFileUploadAndOCR;
