export const checkRecaptcha = (): Promise<string> => {
  return new Promise((resolve): any => {
    try {
      window?.grecaptcha?.enterprise?.ready(async () => {
        const token = await window?.grecaptcha?.enterprise?.execute('6LdTBU0pAAAAADRKYKHpz0ZAlzvW1X41C5l8vBzJ', {
          action: 'USER_TENTH_FILE'
        });

        resolve(token);
      });
    } catch {
      resolve('');
    }
  });
};
