import formI864 from 'assets/forms/i-864.pdf';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';
import blue_number_icon_5 from 'assets/img/forms/5-step-blue-icon.svg';
import blue_number_icon_6 from 'assets/img/forms/6-step-blue-icon.svg';
import bgImgMob from 'assets/img/forms/background-i-864-mob.webp';
//import bgImgTab from 'assets/img/forms/background-941-tab.webp';
import bgImgWeb from 'assets/img/forms/background-i-864-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';

import type { IFormService } from 'ts/interfaces/services/service';
import { EServiceType } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export const formI864Service = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    pagesCount: 6,
    path: '/form-i-864',
    filePath: formI864,
    fileName: 'i-864.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_i864.title'),
      description: t('meta.form_i864.description')
    },
    getForm: {
      headline1: 'USCIS',
      headline2: 'Form',
      formName: 'I-864',
      subHeadline: '2024-2025 Printable Template',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM'
    },
    easilyComplete: {
      headline: 'How it works',
      description: [
        {
          icon: icon_1,
          iconMob: icon_mob_1,
          title: 'Start with PDF Guru',
          text: 'Access our intuitive platform and open the blank Form I-864, ready for you to fill out.'
        },
        {
          icon: icon_2,
          iconMob: icon_mob_2,
          title: 'Complete the affidavit',
          text: 'Carefully enter all required information, ensuring accuracy and compliance with USCIS guidelines.'
        },
        {
          icon: icon_3,
          iconMob: icon_mob_3,
          title: 'Review, download, and print',
          text: 'Thoroughly check your entries, download the form, and print it out for physical submission as required by USCIS.'
        }
      ]
    },
    whatIsForm: {
      headline: 'What is Form I-864?',
      text_1: [],
      text_2: [
        "Form I-864 is primarily used in family-based immigration cases. When a U.S. citizen or permanent resident sponsors a family member for immigration, they must ensure the immigrant won't rely on government welfare. This form binds the sponsor to financially support the immigrant until they become U.S. citizens or can be credited with 40 quarters of work (typically 10 years).",
        'If the immigrant does receive public benefits, the agency providing those benefits can require the sponsor to repay the cost. This highlights the importance of understanding and correctly completing Form I-864.'
      ],
      text_3: [
        'We provide a standard blank Form I-864 for you to fill out. However, there are several types of Affidavit of Support forms, including I-864, I-864EZ, I-864A, and I-864W. For detailed information about Affidavit of Support requirements and forms, please visit the U.S. Citizenship and Immigration Services (USCIS) website'
      ],
      text_4: [],
      title_1: 'What is the I-864 form used for?',
      title_2: 'Types of I-864 forms and which to use',
      subTitle: '',
      sectionImage: section_img,
      sectionImageTab: section_img_tab,
      description: [
        "The Form I-864, or Affidavit of Support, is a crucial document in the immigration process. It's used by sponsors to demonstrate they have adequate financial resources to support the immigrant they are sponsoring. It is required for most family-based immigrants and some employment-based intending immigrants to show that they have adequate means of financial support and are not likely to become a public charge.",
        'The Affidavit of Support requires detailed financial information. Sponsors must prove they earn at least 125% of the Federal Poverty Guidelines for their household size, including the immigrant. It involves submitting proof of income, assets, and employment to meet these requirements.'
      ],

      bullets: [],
      redList: [
        {
          strong: 'I-864 vs I-864A',
          isColumn: true,
          text: "The I-864A, Contract Between Sponsor and Household Member, is used when a household member agrees to support the immigrant. It's a supplement to the I-864 and not a standalone form."
        },
        {
          strong: 'I-864 vs I-864EZ',
          isColumn: true,
          text: "The I-864EZ is a simplified version of the I-864, used in cases where the sponsored immigrant is the only immigrant on the form, and the sponsor's income comes solely from their employment or pension and is above the required level."
        },
        {
          strong: 'I-864 vs I-864W',
          isColumn: true,
          text: "The I-864W, Intending Immigrant's Affidavit of Support Exemption, is for immigrants who are exempt from the requirement, such as those who have already worked 40 qualifying quarters in the U.S."
        }
      ]
    },
    howToFill: {
      headline: 'How to fill out form I-864',
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          strong: 'Start on PDF Guru:',
          text: ' Open our platform and access the blank Form I-864.'
        },
        {
          icon: blue_number_icon_2,
          strong: 'Enter personal and financial information:',
          text: " Input your details, the immigrant's details, and your financial information, following the form's instructions."
        },
        {
          icon: blue_number_icon_3,
          strong: 'Attach supporting documents:',
          text: ' Ensure all necessary financial documents are attached to validate your income and assets.'
        },
        {
          icon: blue_number_icon_4,
          strong: 'Review and download:',
          text: ' Double-check for accuracy, then download and print the form.'
        },
        {
          icon: blue_number_icon_5,
          strong: 'Signature required:',
          text: ' Physically sign the printed form, as electronic signatures are not accepted.'
        },
        {
          icon: blue_number_icon_6,
          strong: 'Submission:',
          text: " File the signed form with the appropriate USCIS office, as part of the immigrant's visa application."
        }
      ]
    },
    blockWithBlueTitle2: {
      title: 'Documents you need to submit with I-864',
      description: ["Along with the completed Form I-864, you'll need to submit:"],
      orangeList: [
        { text: 'Your most recent federal income tax return.' },
        { text: 'Proof of current employment or income.' },
        { text: 'Evidence of assets, if necessary to meet the income requirement.' }
      ]
    },
    differenceBetween2: {
      headline: 'Where to file I-864',
      text: "Form I-864 should be filed with the USCIS as part of the immigrant's visa application packet. The specific USCIS office depends on the type of immigration application."
    },
    differenceBetween: {
      headline: 'How to withdraw affidavit of support I-864',
      text: "To withdraw the Affidavit of Support, contact the USCIS office where the affidavit was filed, and provide a written request for withdrawal before the immigrant's permanent resident status is granted."
    },
    fillFormFree: {
      headline: 'Start Filling Your I-864 Now!',
      subtitle: 'Seamless editing, perfect accuracy',
      buttonLabel: 'Fill Form'
    },
    bannerQuickNEasy: {
      headline: 'Use PDF Guru for Your I-864 Form!',
      subtitle: 'Simplify your sponsorship process',
      format: InternalFileType.PDF,
      buttonLabel: 'Fill Form'
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: 'What is a joint sponsor for I-864?',
          answer: [
            "A joint sponsor is someone who meets the income requirements to sponsor an immigrant and agrees to accept legal responsibility for supporting the immigrant, along with the primary sponsor, if the primary sponsor's income is insufficient."
          ]
        },
        {
          question: 'Who is the principal immigrant on form I-864?',
          answer: [
            'The principal immigrant is the person being sponsored for immigration. They are the main applicant for whom the I-864 is filed, often a family member of the sponsor.'
          ]
        },
        {
          question: 'What does "in care of name" mean on form I-864?',
          answer: [
            '"In Care Of Name" on Form I-864 refers to a person other than the applicant who receives the mail. This is often used if the immigrant does not have a permanent U.S. address yet.'
          ]
        },
        {
          question: 'When should I submit form I-864?',
          answer: [
            "Submit Form I-864 when the immigrant you are sponsoring applies for an immigrant visa or adjustment of status. It's a critical part of the visa application process."
          ]
        }
      ]
    },
    fag: {
      question: 'How to fill out Form 1065?',
      answer:
        "1. Start on PDF Guru: Open our platform and access the blank Form I-864. 2. Enter personal and financial information: Input your details, the immigrant's details, and your financial information, following the form's instructions. 3. Attach supporting documents: Ensure all necessary financial documents are attached to validate your income and assets. 4. Review and download: Double-check for accuracy, then download and print the form. 5. Signature required: Physically sign the printed form, as electronic signatures are not accepted. 6. Submission: File the signed form with the appropriate USCIS office, as part of the immigrant's visa application. "
    }
  };
};
