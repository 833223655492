import { Buffer } from 'buffer';
import type { PDFPage } from 'pdf-lib';
import { PDFDocument } from 'pdf-lib';

import type { IMergeDocument } from 'ts/interfaces/documents/document';

import { urltoFile } from './urlToFile';

//change IMergeDocument to File[] and checks everywhere
export const mergePdfFiles = async (filesList: IMergeDocument[], fileName: string) => {
  const arrayBufferList = await Promise.all(filesList.map((file) => file.file.arrayBuffer()));
  const mergedFile = await mergePdfs(arrayBufferList);

  const buffer = Buffer.from(mergedFile);

  const base64String = `data:application/pdf;base64,${buffer.toString('base64')}`;

  return urltoFile(base64String, fileName, 'application/pdf');
};

export const mergePdfFilesABImportExportEditor244 = async (filesList: File[], fileName: string) => {
  const arrayBufferList = await Promise.all(filesList.map((file) => file.arrayBuffer()));
  const mergedFile = await mergePdfs(arrayBufferList);

  const buffer = Buffer.from(mergedFile);

  const base64String = `data:application/pdf;base64,${buffer.toString('base64')}`;

  return urltoFile(base64String, fileName, 'application/pdf');
};

export async function mergePdfs(pdfsToMerge: ArrayBuffer[]): Promise<ArrayBufferLike> {
  const mergedPdf: PDFDocument = await PDFDocument.create();

  const createInnerPromise = async (arrayBuffer: ArrayBuffer): Promise<PDFPage[]> => {
    const pdf: PDFDocument = await PDFDocument.load(arrayBuffer, { ignoreEncryption: true });
    return await mergedPdf.copyPages(pdf, pdf.getPageIndices());
  };

  const outerPromise: Promise<PDFPage[]>[] = pdfsToMerge.map((arrayBuffer) => {
    const innerPromise: Promise<PDFPage[]> = createInnerPromise(arrayBuffer);
    return innerPromise;
  });

  const resultOuterPromise: PDFPage[][] = await Promise.all(outerPromise);

  resultOuterPromise.forEach((pageArray: PDFPage[]) => {
    pageArray.forEach((page: PDFPage) => {
      mergedPdf.addPage(page);
    });
  });

  return (await mergedPdf.save()).buffer;
}
