import type { FC } from 'react';

export const CrossIcon: FC = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='19.4907'
        y='3'
        width='2.13412'
        height='23.3216'
        rx='1.06706'
        transform='rotate(45 19.4907 3)'
        fill='currentColor'
      />
      <rect
        x='21.625'
        y='19.491'
        width='2.13412'
        height='23.3216'
        rx='1.06706'
        transform='rotate(135 21.625 19.491)'
        fill='currentColor'
      />
    </svg>
  );
};
