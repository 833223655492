export enum EModalTypes {
  AUTH_FORM = 'AUTH_FORM',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
  PROGRESS_FILE = 'PROGRESS_FILE',
  ENTER_EMAIL_ADDRESS = 'ENTER_EMAIL_ADDRESS',
  ENTER_EMAIL_AI_SUMMARIZER = 'ENTER_EMAIL_AI_SUMMARIZER',
  CANCEL_PLAN = 'CANCEL_PLAN',
  CHECK_YOUR_EMAIL = 'CHECK_YOUR_EMAIL',
  RECOVER_PASSWORD = 'RECOVER_PASSWORD',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR',
  THANK_YOU_PAYMENT = 'THANK_YOU_PAYMENT',
  DARG_AND_DROP = 'DARG_AND_DROP',
  CHOOSE_FORMAT = 'CHOOSE_FORMAT',
  CHOOSE_FORMAT_IMPORT_EXPORT_EDITOR_244 = 'CHOOSE_FORMAT_IMPORT_EXPORT_EDITOR_244',
  CHOOSE_FORMAT_AND_CONVERT = 'CHOOSE_FORMAT_AND_CONVERT',
  PROGRESS_EDIT_FILE = 'PROGRESS_EDIT_FILE',
  PROCESSING_COMPRESS = 'PROCESSING_COMPRESS',
  PROCESSING_SPLIT = 'PROCESSING_SPLIT',
  PROCESSING_MERGE = 'PROCESSING_MERGE',
  TRUST_PILOT = 'TRUST_PILOT',
  THANK_SUBSCRIPTION_OFFER_MODAL = 'THANK_SUBSCRIPTION_OFFER_MODAL',
  GET_FREE_ACCESS = 'GET_FREE_ACCESS',
  MODAL_SELECT_PLAN = 'MODAL_SELECT_PLAN',
  PAYMENT_MODAL = 'PAYMENT_MODAL',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  PROGRESS_FILE_OCR = 'PROGRESS_FILE_OCR',
  CHOOSE_FORMAT_AND_PARSE_TEXT = 'CHOOSE_FORMAT_AND_PARSE_TEXT',
  CHOSE_TRANSLATE_FORMAT = 'CHOSE_TRANSLATE_FORMAT',
  PROGRESS_TRANSLATE_FILE = 'PROGRESS_TRANSLATE_FILE',
  ENTER_EMAIL_TRANSLATE = 'ENTER_EMAIL_TRANSLATE',
  MERGE_FILES = 'MERGE_FILES',
  SHARE_FILE_LINK = 'SHARE_LINK',
  SEND_FILE_VIA_EMAIL = 'SEND_FILE_VIA_EMAIL',
  UNDER_DEVELOPMENT = 'UNDER_DEVELOPMENT',
  RENAME_FILE = 'RENAME_FILE',
  DELETE_FILES = 'DELETE_FILES',
  MERGE_PDF_FILE_PREVIEW_MODAL = 'MERGE_PDF_FILE_PREVIEW_MODAL',
  ARE_YOU_SURE_LEAVE_PAGE = 'ARE_YOU_SURE_LEAVE_PAGE',
  CHOOSE_COMPRESSION_LEVEL = 'CHOOSE_COMPRESSION_LEVEL',
  AI_SUMMARIZER_DAILY_LIMIT = 'AI_SUMMARIZER_DAILY_LIMIT',
  AI_SUMMARIZER_MAX_SIZE_FILE = 'AI_SUMMARIZER_MAX_SIZEION_LEVEL',
  UPLOAD_MODAL_AB_IMPORT_EXPORT_EDITOR_2_4_4 = 'UPLOAD_MODAL_AB_IMPORT_EXPORT_EDITOR_2_4_4'
}
