import { getSubscriptionProducts } from 'helpers/getPlans';

import { EReduxTypes } from 'ts/enums/redux.types';
import type { IReduxAction } from 'ts/interfaces/redux/redux.action';
import type { ISubscription } from 'ts/interfaces/subscriptions/subscriptions';

const initialState: ISubscription = {
  subscription: null,
  selectedPlan: null,
  plans: [],
  cancelPlan: null,
  order: null,
  payment: null
};

const subscriptionsReducer = (state = initialState, action: IReduxAction): ISubscription => {
  switch (action.type) {
    case EReduxTypes.SIGN_OUT: {
      return initialState;
    }

    case EReduxTypes.SET_SELECTED_PLAN_ID: {
      return {
        ...state,
        selectedPlan: action.data.plan
      };
    }

    case EReduxTypes.SET_ORDER_DATA: {
      return {
        ...state,
        order: action.data
      };
    }

    case EReduxTypes.GET_PLANS: {
      const { plans, cancelPlan } = getSubscriptionProducts(
        action.data.userCountry,
        action.payload,
        action.data.newSalesVariant,
        action.data.germanyFreeTrial
      );

      return {
        ...state,
        // @ts-expect-error types seems wrong here, need to review
        plans,
        // @ts-expect-error types seems wrong here, need to review
        cancelPlan
      };
    }

    case EReduxTypes.GET_INVOICES: {
      return {
        ...state,
        // @ts-expect-error types seems wrong here, need to review
        payment: action.payload
      };
    }
    default:
      return state;
  }
};

export default subscriptionsReducer;
