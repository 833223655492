export const truncateFileName = (
  fileName: string,
  maxLength: number,
  endCutSize: number = 14,
  startCurSize: number = 10,
  internalFileType?: string
) => {
  if (fileName?.length <= maxLength) {
    return fileName;
  }
  let fileType: string = '';
  switch (localStorage.getItem('readyFileFormat')) {
    case 'PNG':
      fileType = 'png';
      break;
    case 'DOCX':
      fileType = 'docx';
      break;
    case 'DOC':
      fileType = 'doc';
      break;
    case 'EPUB':
      fileType = 'epub';
      break;
    case 'JPG':
      fileType = 'jpg';
      break;
    case 'PPTX':
      fileType = 'pptx';
      break;
    case 'PPT':
      fileType = 'ppt';
      break;
    case 'XLS':
      fileType = 'xls';
      break;
    default:
      fileType = 'pdf';
      break;
  }
  const firstPart = fileName?.slice(0, startCurSize);
  const secondPart = fileName?.slice(fileName?.length - endCutSize, fileName?.length - 4);
  return `${firstPart}......${secondPart}.${internalFileType ? internalFileType.toLowerCase() : fileType}`;
};
