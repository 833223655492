import React from 'react';

import classNames from 'classnames';

import s from './secondary-button.module.scss';

interface IProps {
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  dataTestId?: string;
}
export const SecondaryButton: React.FC<IProps & React.HTMLProps<HTMLButtonElement>> = (props) => {
  const { onClick, disabled = false, className, children, dataTestId } = props;

  const classes: Record<string, boolean> = {
    [s.secondaryButton]: true
  };

  if (className) {
    classes[className] = true;
  }

  return (
    <button
      className={classNames(classes)}
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestId}
    >
      {children}
    </button>
  );
};
