import React from 'react';

import classNames from 'classnames';

import { DownloadIcon } from 'components/icons/download';
import { LoadingAnimation } from 'components/loading-animation';

import s from './primary-button.module.scss';

interface IProps {
  onClick: (event?: any) => void;
  disabled?: boolean;
  className?: string;
  icon?: 'download' | 'arrow-right' | 'convert';
  withBorder?: boolean;
  isLoading?: boolean;
  iconPosition?: 'left' | 'right';
  dataTestId?: string;
}

export const PrimaryButton: React.FC<
  IProps & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = (props) => {
  const {
    onClick,
    disabled = false,
    withBorder = false,
    className,
    icon,
    children,
    isLoading = false,
    iconPosition = 'left',
    dataTestId,
    ...other
  } = props;

  const classes: Record<string, boolean> = {
    [s.primaryButton]: true
  };

  if (className) {
    classes[className] = true;
  }

  if (icon) {
    classes[s.withIcon] = true;
  }

  const renderIcon = () => {
    switch (icon) {
      case 'download':
        return <DownloadIcon />;
      case 'arrow-right':
        return (
          <svg
            width='24'
            height='24'
            style={{ marginLeft: '8px' }}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M21 12L16 7M21 12L16 17M21 12H3'
              stroke='white'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        );

      case 'convert':
        return (
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M21 12C21 16.2426 21 18.364 19.682 19.682C18.364 21 16.2426 21 12 21C7.75736 21 5.63604 21 4.31802 19.682C3 18.364 3 16.2426 3 12C3 7.75736 3 5.63604 4.31802 4.31802C5.63604 3 7.75736 3 12 3'
              stroke='#1D1D1D'
              stroke-width='1.5'
              stroke-linecap='round'
            />
            <path
              d='M16.362 3.69894L18.6438 5.4302C20.2146 6.62195 21 7.21783 21 7.99175C21 8.76566 20.2146 9.36154 18.6438 10.5533L16.362 12.2846C15.6713 12.8086 15.3259 13.0706 15.043 12.9575C14.76 12.8444 14.76 12.4443 14.76 11.6442V10.3524C11.856 10.3524 8.71 11.5328 7.5 13.5C7.5 7.20486 11.8022 5.63107 14.76 5.63107V4.33932C14.76 3.53917 14.76 3.13909 15.043 3.02598C15.3259 2.91287 15.6713 3.17489 16.362 3.69894Z'
              stroke='#1D1D1D'
              stroke-width='1.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        );
      default:
        return null;
    }
  };

  return (
    <button
      className={classNames(classes, {
        'border-0': withBorder
      })}
      onClick={onClick}
      {...other}
      disabled={disabled}
      data-testid={dataTestId}
    >
      {isLoading ? (
        <LoadingAnimation
          currentState='loading'
          className='h-[24px] w-[50px]'
        />
      ) : (
        <>
          {iconPosition === 'left' && renderIcon()}
          {children}
          {iconPosition === 'right' && renderIcon()}
        </>
      )}
    </button>
  );
};
