import type { FC, ReactNode } from 'react';

interface MobileModalLayoutProps {
  children: ReactNode;
  canClose?: boolean;
  closePopup?: () => void;
  dataTestId?: string;
  footer?: ReactNode;
}

const MobileModalLayout: FC<MobileModalLayoutProps> = ({ children, canClose, closePopup, dataTestId, footer }) => {
  return (
    <div className='fixed inset-0 !z-[9999] mobile:hidden'>
      <div
        className='fixed inset-0 bg-black/50'
        onClick={canClose ? closePopup : undefined}
        role='button'
      />
      <div
        className='fixed bottom-0 left-0 right-0 bg-white animate-slide-up max-h-full flex flex-col'
        data-testid={dataTestId}
      >
        <div className='overflow-y-auto flex-1 flex flex-col'>{children}</div>
        {footer && <div className='flex-shrink-0'>{footer}</div>}
      </div>
    </div>
  );
};

export default MobileModalLayout;
