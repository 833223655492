import { createSelector } from 'reselect';

import type { GrowthBookTestType, ResultTestVariants } from 'ts/enums/growthbook';
import { TestVariants } from 'ts/enums/growthbook';
import type { IAbTest } from 'ts/interfaces/abTest/abTest';

const baseAbTestsSelector = (state: any) => state?.abTests;

export const abTestsSelector = createSelector(baseAbTestsSelector, (abTests) => abTests);

export interface IGetTestVariantOptions {
  returnFalseValue?: boolean;
}

export const getAbTestVariant = <T extends GrowthBookTestType, R extends boolean = false>(
  featureKey: T,
  { returnFalseValue }: IGetTestVariantOptions & { returnFalseValue?: R } = {} as IGetTestVariantOptions & {
    returnFalseValue?: R;
  }
) =>
  createSelector(
    baseAbTestsSelector,
    (abTests): R extends true ? ResultTestVariants<T> | false : ResultTestVariants<T> => {
      const value = abTests?.tests.find((test: IAbTest) => test.feature === featureKey)
        ?.variant as ResultTestVariants<T>;

      if (!value || !(Object.values(TestVariants) as string[]).includes(value)) {
        return (returnFalseValue ? false : TestVariants.A) as R extends true
          ? ResultTestVariants<T> | false
          : ResultTestVariants<T>;
      }

      return value;
    }
  );
