import type { FC, ReactElement } from 'react';

import { useSelector } from 'react-redux';

import ModalLayout from 'components/modals/baseModal/base';

import { Analytics } from 'services/analytics';

import { modalOptionsSelector } from 'data/selectors/modals';

import ErrorStep from './steps/error';
import SuccessStep from './steps/success';

const GetFreeAccessModal: FC = () => {
  const options = useSelector(modalOptionsSelector);

  const onClosePopup = () => {
    Analytics.sendEvent({
      event: 'sent_file_24_hours_close_tap'
    });
  };

  const renderStep = (status: string): ReactElement => {
    if (status === 'error') return <ErrorStep />;
    return <SuccessStep />;
  };

  return <ModalLayout closePopup={onClosePopup}>{renderStep(options?.status || 'success')}</ModalLayout>;
};

export default GetFreeAccessModal;
