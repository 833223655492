export const getFileFormatFromFile = (files: File | File[]) => {
  const file = Array.isArray(files) ? files[0] : files;
  if (!file) return null;

  const formatMatch = file.name.match(/\.[0-9a-z]+$/i);

  if (formatMatch) {
    return formatMatch[0];
  }

  return null;
};

export const getFileNameWithoutFormatFromFile = (file: File) => {
  const fileFormat = getFileFormatFromFile(file);

  return file.name.replace(new RegExp(fileFormat + '$'), '');
};
