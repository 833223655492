import { config } from 'configuration';

import type { InternalFileType } from 'ts/types/file';
import { InternalType } from 'ts/types/file';

interface ValidateSelectedFilesProps {
  files: FileList | null;
  type: InternalFileType | InternalFileType[];
  translator: (key: string) => string;
  isPDFConverter?: boolean;
}

interface ValidateSuccess {
  valid: true;
  files: File[];
  error: null;
}

interface ValidateError {
  valid: false;
  files: File[];
  error: string;
  code: string;
}

export const validateSelectedFiles = (data: ValidateSelectedFilesProps): ValidateSuccess | ValidateError => {
  const { files, type, translator, isPDFConverter } = data;

  if (!files || files.length === 0) {
    return {
      valid: false,
      error: 'file missing',
      code: 'file-missing',
      files: []
    };
  }

  const validFiles: File[] = [];
  const errors: string[] = [];

  Array.from(files).forEach((file) => {
    if (file.size > config.api.maxFileSizeMb * 1024 * 1024) {
      errors.push(translator('api_errors.error.file.max-size'));
      return;
    }

    if (!isPDFConverter) {
      const types = Array.isArray(type) ? type : [type];
      if (!file.type || file.type === '') {
        if (!types.some((t) => file.name.toLowerCase().endsWith(t.toString().toLowerCase()))) {
          errors.push(translator('api_errors.error.file.wrong-format'));
          return;
        }
      } else {
        if (!InternalType.toMultipleMimetypes(types).includes(file.type)) {
          errors.push(translator('api_errors.error.file.wrong-format'));
          return;
        }
      }
    }

    validFiles.push(file);
  });

  if (errors.length > 0) {
    return {
      valid: false,
      error: errors.join(', '),
      code: 'validation-error',
      files: validFiles
    };
  }

  return {
    valid: true,
    files: validFiles,
    error: null
  };
};
