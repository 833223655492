import type { FC } from 'react';

import { useTranslation } from 'react-i18next';

export const RTLProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation();
  const isRTL = ['ar', 'he'].includes(i18n.language);

  return (
    <div
      dir={isRTL ? 'rtl' : 'ltr'}
      lang={i18n.language}
    >
      {children}
    </div>
  );
};
