import form443d from 'assets/forms/433-d.pdf';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';
import blue_number_icon_5 from 'assets/img/forms/5-step-blue-icon.svg';
import blue_number_icon_6 from 'assets/img/forms/6-step-blue-icon.svg';
import bgImgMob from 'assets/img/forms/background-433-d-mob.webp';
//import bgImgTab from 'assets/img/forms/background-941-tab.webp';
import bgImgWeb from 'assets/img/forms/background-433-d-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';

import type { IFormService } from 'ts/interfaces/services/service';
import { EServiceType } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

import { Answer2, Answer4 } from './parts/form-433-d/answer';
import { Description } from './parts/form-433-d/description';

export const form433DService = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    pagesCount: 6,
    path: '/form-433-d',
    filePath: form443d,
    fileName: '433-d.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_433d.title'),
      description: t('meta.form_433d.description')
    },
    getForm: {
      headline1: 'IRS',
      headline2: 'Form',
      formName: '433-D',
      subHeadline: '2022-2024 Printable Template',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM'
    },
    easilyComplete: {
      headline: 'How it works',
      description: [
        {
          icon: icon_1,
          iconMob: icon_mob_1,
          title: 'Start with PDF Guru',
          text: "Visit our platform and access the blank Form 433-D. Just click 'GET FORM' to begin."
        },
        {
          icon: icon_2,
          iconMob: icon_mob_2,
          title: 'Fill in your details',
          text: 'Enter your financial information accurately, following IRS guidelines to ensure everything is correct and compliant.'
        },
        {
          icon: icon_3,
          iconMob: icon_mob_3,
          title: 'Download and submit',
          text: 'Double-check all the information for accuracy, download your form, and prepare it for submission according to IRS requirements.'
        }
      ]
    },
    whatIsForm: {
      headline: 'What is Form 433-D?',
      text_1: [],
      text_2: [],
      text_3: [
        "If you owe taxes and can't pay them all at once, Form 433-D is for you. It's especially important for self-employed individuals, small business owners, and others with outstanding tax balances. Knowing when and how to file this form is key to a smooth tax process."
      ],
      text_4: [],
      title_1: 'What is Form 433-D used for?',
      title_2: 'Who should file Form 433-D?',
      title_3: '',
      subTitle:
        "This form is used to create a structured payment plan with the IRS for paying back taxes. It's ideal if you can't pay your tax dues in full immediately. With Form 433-D, you can settle your tax debts in a way that fits your budget, making it a helpful tool for financial planning and stability. To sum up, Form 433-D is used to:",
      sectionImage: section_img,
      sectionImageTab: section_img_tab,
      description: [],
      description2: Description,
      bullets: [],
      orangeList: [
        { text: 'Report income and expenses;' },
        { text: 'Determine tax liability;' },
        { text: 'Provide necessary information to the IRS for tax purposes.' }
      ]
    },
    howToFill: {
      headline: 'How to fill out Form 433-D',
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          strong: 'Start on PDF Guru:',
          text: ' Access our platform and open the blank Form 433-D.'
        },
        {
          icon: blue_number_icon_2,
          strong: 'Enter personal and financial information:',
          text: ' Fill in your name, address, social security number, and other relevant details.'
        },
        {
          icon: blue_number_icon_3,
          strong: 'Report income and expenses:',
          text: ' List all sources of income and expenses, including any deductions or credits you may be eligible for.'
        },
        {
          icon: blue_number_icon_4,
          strong: 'Sign the form:',
          text: ' Add your signature to the form.'
        },
        {
          icon: blue_number_icon_5,
          strong: 'Download and review:',
          text: ' Carefully check all details for accuracy. Download and print the form, as digital signatures are not accepted by the IRS.'
        },
        {
          icon: blue_number_icon_6,
          strong: 'File the form:',
          text: ' Either submit the signed form online via the IRS Electronic Filing System (EFS) or send it to the IRS by mail or fax, using the current fax number and mailing address from the IRS website.'
        }
      ]
    },
    blockWithBlueTitle2: {
      title: 'How to submit IRS Form 433-D',
      description: ['To submit Form 433-D to the IRS, you have two options:'],
      orangeList: [
        {
          strong: 'File online:',
          text: ' Use the Electronic Filing System (EFS) to submit your signed form electronically.'
        },
        {
          strong: 'Mail or fax:',
          text: ' Send your signed form to the IRS by mail or fax, using the current fax number and mailing address from the IRS website.'
        }
      ]
    },
    differenceBetween2: {
      headline: 'Where to send Form 433-D',
      text: 'The address for sending Form 433-D can change depending on your situation. To be sure, check the latest instructions on the IRS website or ask a tax professional.'
    },
    fillFormFree: {
      headline: 'Instantly Access Our 433-D Form Filler',
      subtitle: 'Simplify your tax filing',
      buttonLabel: 'Fill Form'
    },
    bannerQuickNEasy: {
      headline: 'Complete and Submit Your 433-D Today',
      subtitle: 'Navigate tax season with confidence',
      format: InternalFileType.PDF,
      buttonLabel: 'Fill Form'
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: 'When do you need to file Form 433-D?',
          answer: [
            "A joint sponsor is someone who meets the income requirements to sponsor an immigrant and agrees to accept legal responsibility for supporting the immigrant, along with the primary sponsor, if the primary sponsor's income is insufficient."
          ]
        },
        {
          question: 'Can you file Form 433-D online?',
          answer: [],
          answer2: Answer2
        },
        {
          question: 'Is Form 433-D suitable for all tax debts?',
          answer: [
            '"In Care Of Name" on Form I-864 refers to a person other than the applicant who receives the mail. This is often used if the immigrant does not have a permanent U.S. address yet.'
          ]
        },
        {
          question: 'What if you miss a payment under Form 433-D?',
          answer: [],
          answer2: Answer4
        }
      ]
    },
    fag: {
      question: 'How to fill out Form 1065?',
      answer:
        '1. Start on PDF Guru: Access our platform and open the blank Form 433-D. 2. Enter personal and financial information: Fill in your name, address, social security number, and other relevant details. 3. Report income and expenses: List all sources of income and expenses, including any deductions or credits you may be eligible for. 4. Sign the form: Add your signature to the form. 5. Download and review: Carefully check all details for accuracy. Download and print the form, as digital signatures are not accepted by the IRS. 6. File the form: Either submit the signed form online via the IRS Electronic Filing System (EFS) or send it to the IRS by mail or fax, using the current fax number and mailing address from the IRS website.'
    }
  };
};
