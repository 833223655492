import formSS5 from 'assets/forms/ss-5.pdf';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';
import blue_number_icon_5 from 'assets/img/forms/5-step-blue-icon.svg';
import bgImgMob from 'assets/img/forms/background-ss-5-mob.webp';
//import bgImgTab from 'assets/img/forms/background-941-tab.webp';
import bgImgWeb from 'assets/img/forms/background-ss-5-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';

import type { IFormService } from 'ts/interfaces/services/service';
import { EServiceType } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export const formSS5Service = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    pagesCount: 6,
    path: '/form-ss-5',
    filePath: formSS5,
    fileName: 'ss-5.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_ss5.title'),
      description: t('meta.form_ss5.description')
    },
    getForm: {
      headline1: 'SSA',
      headline2: 'Form',
      formName: 'SS-5',
      subHeadline: '2021-2025 Printable Template',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM'
    },
    easilyComplete: {
      headline: 'How it works',
      description: [
        {
          icon: icon_1,
          iconMob: icon_mob_1,
          title: 'Start with PDF Guru',
          text: 'Navigate to our platform and open a blank SS-5 Form. Our editor tools are ready for you to use.'
        },
        {
          icon: icon_2,
          iconMob: icon_mob_2,
          title: 'Enter your details',
          text: "Accurately enter your personal information, including name, address, etc. Follow the form's prompts to ensure no details are missed."
        },
        {
          icon: icon_3,
          iconMob: icon_mob_3,
          title: 'Review, download, and print',
          text: 'Double-check every entry for accuracy. Download the filled form and print it, as the SSA requires a physical copy with a manual signature.'
        }
      ]
    },
    whatIsForm: {
      headline: 'What is a Form SS-5?',
      text_1: [],
      text_2: [
        "Primarily, the SS-5 is necessary for acquiring a Social Security Number (SSN), which is essential for employment in the United States, receiving Social Security benefits, and other government services. It's also used for updating personal details in the SSA's records."
      ],
      text_3: [
        "Individuals who are applying for a Social Security card for the first time, whether they are U.S. citizens, permanent residents, or temporary working residents, need to fill out Form SS-5. It's also required for those who need to update their information or replace a lost card."
      ],
      text_4: [],
      title_1: 'What is Form SS-5 used for?',
      title_2: 'Who needs Form SS-5?',
      subTitle: '',
      sectionImage: section_img,
      sectionImageTab: section_img_tab,
      description: [
        `Form SS-5, issued by the Social Security Administration (SSA), is the official application for a Social Security card. This critical document is used both for obtaining a new card and for making changes to your existing record, such as name changes or updating personal information.`
      ],
      bullets: [],
      redList: []
    },
    differenceBetween1: {
      headline: 'How to get Form SS-5',
      text: "Getting your hands on a blank Form SS-5 is simple with PDF Guru. Visit our website, and with a click on 'Get Form,' you'll have access to the blank SS-5 Form, ready for you to fill out and download. Our intuitive interface allows you to fill out the form directly on our site, at your own pace and convenience. You can save your progress and return to it at any time, ensuring that filling out the form fits into your busy schedule seamlessly."
    },
    howToFill: {
      headline: 'How to fill out Form SS-5',
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          strong: 'Start on PDF Guru:',
          text: ' Access our platform and open the blank Form SS-5.'
        },
        {
          icon: blue_number_icon_2,
          strong: 'Enter your details:',
          text: ' Provide your full legal name, birth details, citizenship status, and other required personal information.'
        },
        {
          icon: blue_number_icon_3,
          strong: 'Review and download:',
          text: ' Double-check for accuracy, then download and print the form, as the SSA requires a physical copy.'
        },
        {
          icon: blue_number_icon_4,
          strong: 'Review and download:',
          text: ' Double-check for accuracy, then download and print the form, as the SSA requires a physical copy.'
        },
        {
          icon: blue_number_icon_5,
          strong: 'File the form:',
          text: ' Submit the signed form in person or by mail to your nearest Social Security office.'
        }
      ]
    },
    blockWithBlueTitle2: {
      title: 'Can you mail Form SS-5?',
      description: [
        'Yes, Form SS-5 can be mailed. Once you have filled out the form and attached the necessary identification documents, you can mail it to your local Social Security office.'
      ],
      orangeList: [
        {
          strong: 'Required Documentation:',
          text: ' Remember, when submitting your Form SS-5, you must include original documents proving your identity, age, and U.S. citizenship or lawful immigration status. These documents are critical to the processing of your application.'
        }
      ]
    },
    differenceBetween2: {
      headline: 'Can you submit Form SS-5 online?',
      text: 'Submitting Form SS-5 online is not currently an option offered by the Social Security Administration. This traditional approach is in place to ensure the security and accuracy of your sensitive personal information.'
    },
    fillFormFree: {
      headline: 'Streamline Your SS-5 Filling Today',
      subtitle: 'The easiest way to complete forms',
      buttonLabel: 'Fill Form'
    },
    bannerQuickNEasy: {
      headline: 'Complete Form SS-5 in Minutes',
      subtitle: 'Ease, efficiency & expertise',
      format: InternalFileType.PDF,
      buttonLabel: 'Fill Form'
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: 'Do I need to pay to file Form SS-5?',
          answer: [
            `No, there is no fee to file the Form SS-5 with the Social Security Administration. However, using PDF Guru's editing and saving features does involve a subscription fee.`
          ]
        },
        {
          question: 'What documents do I need to attach with Form SS-5?',
          answer: [
            `When submitting your Form SS-5, it's essential to include specific documents that verify your identity, age, and citizenship or immigration status. Here are the commonly required documents:`
          ]
        },
        {
          question: 'How long does it take to process Form SS-5?',
          answer: [
            `Processing time for the Form SS-5 can vary. Typically, it takes the SSA about 10-14 business days to issue a card once they receive the form.`
          ]
        },
        {
          question: 'Can I check the status of my Form SS-5 application?',
          answer: [
            `Yes, you can check the status of your Form SS-5 application by contacting the Social Security Administration. They can provide updates on the processing of your application and the issuance of your Social Security Card.`
          ]
        }
      ]
    },
    fag: {
      question: 'How to fill out Form SS-5',
      answer:
        '1. Start on PDF Guru: Access our platform and open the blank Form SS-5. 2. Enter your details: Provide your full legal name, birth details, citizenship status, and other required personal information. 3. Review and download: Double-check for accuracy, then download and print the form, as the SSA requires a physical copy. 4. Manual signing: Sign the printed form, as electronic signatures are not accepted by the SSA. 5. File the form: Submit the signed form in person or by mail to your nearest Social Security office.'
    }
  };
};
