import type { FC } from 'react';

export const DownloadIcon: FC = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 14V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V14.5'
        stroke='currentColor'
        strokeWidth='1.3'
        strokeLinecap='round'
      />
      <path
        d='M12.5 9C12.5 8.72386 12.2761 8.5 12 8.5C11.7239 8.5 11.5 8.72386 11.5 9H12.5ZM11.6464 17.3536C11.8417 17.5488 12.1583 17.5488 12.3536 17.3536L15.5355 14.1716C15.7308 13.9763 15.7308 13.6597 15.5355 13.4645C15.3403 13.2692 15.0237 13.2692 14.8284 13.4645L12 16.2929L9.17157 13.4645C8.97631 13.2692 8.65973 13.2692 8.46447 13.4645C8.2692 13.6597 8.2692 13.9763 8.46447 14.1716L11.6464 17.3536ZM11.5 9V17H12.5V9H11.5Z'
        fill='currentColor'
      />
      <circle
        cx='12'
        cy='5.75'
        r='0.5'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.5'
      />
      <circle
        cx='12'
        cy='2.75'
        r='0.5'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.5'
      />
    </svg>
  );
};
