import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import classNames from 'classnames';
import { getJsonFromStorage } from 'helpers/localStorageHelper';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';

import { PrimaryButton } from 'components/buttons/primary-button';
import { LoadingAnimation } from 'components/loading-animation';
import ModalLayout from 'components/modals/baseModal/base';

import { Analytics } from 'services/analytics';

import { updateEditDocumentData, updateEditFilename } from 'data/actions/documents';
import { convertDocumentDataSelector, editFilenameSelector } from 'data/selectors/documents';
import { getLoadingSelector } from 'data/selectors/loading';
import { modalOptionsSelector } from 'data/selectors/modals';

import { getFileFormatFromFile } from 'utils/getFileFormatFromFile';
import { getPrevPage } from 'utils/prevPath';

import downloadIcon from './assets/download.svg';
import sendEmailIcon from './assets/email.svg';
import printIcon from './assets/print.svg';
import { CompressSelect } from './components/compressSelect';
import { Dropdown } from './components/dropdown';
import type { Format } from './formatsList';
import { getFormatsList } from './formatsList';

const ChooseFormatModalImportExportEditor244B: FC = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isLoading = useSelector(getLoadingSelector('save_edit_document'));

  const { documentId, handleSaveFile, handleCompressFile, handleSendFileViaEmail, handlePrintFile, file } =
    useSelector(modalOptionsSelector);
  const editFilename = useSelector(editFilenameSelector()) || file?.name?.replace(/\.[0-9a-z]+$/i, '');

  useCloseModalOnBack();

  const dataToConvert = useSelector(convertDocumentDataSelector());
  const [searchParams] = useSearchParams();
  const convertTo = searchParams.get('to');

  const formatsList: Format[] = useMemo(() => getFormatsList(t), [t]);

  const [currentFormat, setCurrentFormat] = useState<Format>(formatsList[0]);
  const [isCompressed, setIsCompressed] = useState(false);

  const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleSelectCompression = (newValue: boolean) => {
    setIsCompressed(newValue);
  };

  useEffect(() => {
    if (searchParams.get('to')?.includes('COMPRESS')) {
      Analytics.sendEvent({
        event: 'export_modal_view',
        data: {
          screen_config_name: 'extended_options_without_compress'
        }
      });
    } else {
      Analytics.sendEvent({
        event: 'export_modal_view',
        data: {
          screen_config_name: 'extended_options_with_compress'
        }
      });
    }
  }, [searchParams]);

  useEffect(() => {
    const selectedFormat = formatsList.find((format) => format.to === convertTo);
    if (convertTo && selectedFormat) {
      handleSetCurrentFormat(selectedFormat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFileSize = useCallback(() => {
    if (file) {
      return file.size;
    }
    const dataFromStorage = getJsonFromStorage('dataToConvert');
    return dataFromStorage?.file?.size || 1000000;
  }, [file]);

  const handleSetCurrentFormat = (format: Format) => {
    const dataToEdit: any = getJsonFromStorage('dataToEdit');
    if (dataToEdit) {
      dataToEdit.from = 'PDF';
      dataToEdit.to = format.to;
      dataToEdit.documentId = documentId;
    }
    dispatch(updateEditDocumentData({ to: format.to === 'PDF' ? '' : format.to }));
    localStorage.setItem('dataToEdit', JSON.stringify(dataToEdit));

    setCurrentFormat(format);
  };

  const handleSendEmail = () => {
    setIsActionButtonDisabled(true);
    setIsSendingEmail(true);
    localStorage.removeItem('printDocument');
    Analytics.sendEvent({
      event: 'export_modal_confirm_tap',
      data: {
        type: 'email',
        export_type: getConverterName(currentFormat.to),
        name_changed: dataToConvert?.filename?.replace(/\.[0-9a-z]+$/i, '') !== editFilename,
        file_compressed: isCompressed,
        fromPage: getPrevPage() || ''
      }
    });

    localStorage.setItem('sendEmail', 'true');

    handleSendFileViaEmail(editFilename);
  };

  const handlePrint = () => {
    setIsActionButtonDisabled(true);
    setIsPrinting(true);
    localStorage.removeItem('sendEmail');
    Analytics.sendEvent({
      event: 'export_modal_confirm_tap',
      data: {
        type: 'print',
        export_type: getConverterName(currentFormat.to),
        name_changed: dataToConvert?.filename?.replace(/\.[0-9a-z]+$/i, '') !== editFilename,
        file_compressed: isCompressed,
        fromPage: getPrevPage() || ''
      }
    });

    localStorage.setItem('printDocument', 'true');

    handlePrintFile(editFilename);
  };

  const handleDownload = () => {
    setIsActionButtonDisabled(true);
    setIsDownloading(true);
    localStorage.removeItem('printDocument');
    localStorage.removeItem('sendEmail');
    Analytics.sendEvent({
      event: 'export_modal_confirm_tap',
      data: {
        type: 'download',
        export_type: getConverterName(currentFormat.to),
        name_changed: dataToConvert?.filename?.replace(/\.[0-9a-z]+$/i, '') !== editFilename,
        file_compressed: isCompressed,
        fromPage: getPrevPage() || ''
      }
    });

    if (currentFormat.to !== 'PDF' || !window.location.pathname.includes('editor')) {
      handleSaveFile(editFilename, currentFormat.to);
    } else {
      if (isCompressed) {
        handleCompressFile(editFilename);
      } else {
        handleSaveFile(editFilename, currentFormat.to);
      }
    }
    setIsActionButtonDisabled(false);
    setIsDownloading(false);
  };

  const getConverterName = (selectedFormat: string) => {
    if (file) {
      const fileExtension = getFileFormatFromFile(file)?.toLowerCase().slice(1);
      return `${fileExtension}_to_${selectedFormat.toLowerCase()}`;
    }

    // @NOTE: if file is not provided, user goes from editor page so the initial file is pdf
    return `pdf_to_${selectedFormat.toLowerCase()}`;
  };

  if (isLoading) {
    return (
      <>
        <div className='w-full h-full fixed top-0 left-0 z-[10006] bg-[rgba(0,0,0,0.6)] flex justify-center items-center px-4'>
          <div
            className='max-w-[557px] w-full h-[300px] tablet:h-[340px] p-6 flex flex-col justify-center items-center bg-white
            rounded-[10px] tablet:text-[22px] text-[18px] leading-[24px] text-[#373737]'
          >
            <LoadingAnimation
              currentState='loading'
              className='tablet:!w-[160px] tablet:!h-[160px] !w-[100px] !h-[100px]'
            />
            <p className='font-[700] tablet:my-4 my-2 text-center'>{t('editor_page.saving')}</p>
            <p className='text-[#878787] text-[16px]'>{t('editor_page.please_wait')}</p>
          </div>
        </div>
      </>
    );
  }

  return (
    <ModalLayout
      canClose
      closePopup={() => {
        Analytics.sendEvent({ event: 'export_modal_close_tap' });
        localStorage.removeItem('printDocument');
        localStorage.removeItem('sendEmail');
      }}
      dataTestId='choose-format-modal'
      overflowVisible={true}
    >
      <div
        className='flex flex-col text-[#1D1D1D] items-center w-full mobile:min-w-[630px]
      mobile:max-w-[600px] px-8 pb-8 pt-10'
      >
        <h2 className='text-[25px] font-extrabold leading-[32.5px]='>
          {t('popups.choose_format_modal_import_export_editor_244_b.title')}
        </h2>

        <div className='flex flex-col w-full mt-6'>
          <label
            htmlFor='fileName'
            className='right-0 text-[20px] font-bold'
          >
            {t('editor_page.convert_banner.file_name_placeholder')}
          </label>
          <input
            type='text'
            id='fileName'
            name='fileName'
            placeholder={t('editor_page.convert_banner.file_name_placeholder')}
            value={editFilename}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              dispatch(updateEditFilename(event.target.value));
            }}
            className='rounded-md border border-[#C7C7C7] py-3 px-4 mt-2'
          />
        </div>

        <div className='w-full mt-6'>
          <div className='text-[20px] font-bold mb-2'>
            {t('popups.choose_format_modal_import_export_editor_244_b.format')}
          </div>
          <Dropdown
            formatsList={formatsList}
            selectedFormat={currentFormat}
            onSelectFormat={handleSetCurrentFormat}
            getConverterName={getConverterName}
            data-testid='format-select'
          />
        </div>

        {!searchParams.get('to')?.includes('COMPRESS') && (
          <div className='w-full mt-6'>
            <CompressSelect
              isCompressed={isCompressed}
              setIsCompressed={handleSelectCompression}
              fileSize={getFileSize()}
            />
          </div>
        )}

        <div className='flex mt-6 items-center justify-between w-full'>
          <PrimaryButton
            onClick={handleSendEmail}
            className={classNames(
              'flex items-center justify-center bg-white border-[1px] border-[#C7C7C7] hover:bg-white text-[#1D1D1D] hover:text-[#1D1D1D] min-w-[130px] h-[57px] !mr-0',
              {
                'ml-6': i18n?.dir() === 'rtl',
                'mr-6': i18n?.dir() !== 'rtl'
              }
            )}
            dataTestId='cancel-button'
            disabled={isActionButtonDisabled}
          >
            {isSendingEmail ? (
              <LoadingAnimation
                currentState='loading'
                className='!w-5 mr-2'
              />
            ) : (
              <LazyLoadImage
                src={sendEmailIcon}
                alt='send email'
                className='w-6 h-6 mr-2'
              />
            )}
            {t('global.email')}
          </PrimaryButton>
          <PrimaryButton
            onClick={handlePrint}
            className={classNames(
              'flex items-center justify-center bg-white border-[1px] border-[#C7C7C7] hover:bg-white text-[#1D1D1D] hover:text-[#1D1D1D] min-w-[130px] h-[57px] !mr-0',
              {
                'ml-6': i18n?.dir() === 'rtl',
                'mr-6': i18n?.dir() !== 'rtl'
              }
            )}
            dataTestId='cancel-button'
            disabled={isActionButtonDisabled}
          >
            {isPrinting ? (
              <LoadingAnimation
                currentState='loading'
                className='!w-5 mr-2'
              />
            ) : (
              <LazyLoadImage
                src={printIcon}
                alt='print'
                className='w-6 h-6 mr-2'
              />
            )}
            Print
          </PrimaryButton>
          <PrimaryButton
            onClick={handleDownload}
            dataTestId='download-button'
            disabled={isActionButtonDisabled}
            className='flex items-center justify-center min-w-full tablet:min-w-[270px] bg-[#D2294B] h-[57px] hover:bg-[#f4476ae0] text-[20px] mobile:!leading-[30px] py-5 mobile:py-[17px]'
          >
            {isDownloading ? (
              <LoadingAnimation
                currentState='loading'
                className='!w-5 mr-2'
              />
            ) : (
              <LazyLoadImage
                src={downloadIcon}
                alt='download'
                className='w-6 h-6 mr-2'
              />
            )}
            {t('editor_page.convert_banner.convert')}
          </PrimaryButton>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ChooseFormatModalImportExportEditor244B;
