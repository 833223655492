import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getFileFormatFromFile } from 'helpers/getFileFormatFromFile';
import useFileUploadAndOCR from 'hooks/fileFlows/useFileUploadAndOCR';

import { PrimaryButton } from 'components/buttons/primary-button';
import ModalLayout from 'components/modals/baseModal/base';

import { sendAnalyticEvent } from 'data/actions/analytics';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector } from 'data/selectors/modals';

import { getPrevPage } from 'utils/prevPath';

import type { SupportedOcrExportFormat } from 'ts/interfaces/documents/document';
import { OcrExportFormatEnum } from 'ts/interfaces/documents/document';

import { FormatsList } from './formatsList';

const EXPORT_FORMATS_FEATURE_NAME_MAP: Record<SupportedOcrExportFormat, string> = {
  [OcrExportFormatEnum.docx]: 'pdf_to_doc',
  [OcrExportFormatEnum.pdfSearchable]: 'pdf_to_searchable',
  [OcrExportFormatEnum.txt]: 'pdf_to_txt'
};

const ChooseFormatAndParseTextModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { service, file } = useSelector(modalOptionsSelector);

  const [filename, setFilename] = useState<string>('');
  const [fileFormat, setFileFormat] = useState<string>('');
  const [isNameChanged, setIsNameChanged] = useState(false);

  const [currentFormat, setCurrentFormat] = useState<SupportedOcrExportFormat>(OcrExportFormatEnum.docx);
  const fileTo = useMemo(() => currentFormat, [currentFormat]);

  const { handleUploadFile } = useFileUploadAndOCR({
    service: { ...service, to: fileTo }
  });

  const handleSetCurrentFormat = (format: SupportedOcrExportFormat) => {
    setCurrentFormat(format);
  };

  const handleCancel = (type: string) => {
    dispatch(toggleModal({ visible: false }));
    dispatch(sendAnalyticEvent({ event: 'cancel_save_edited_file', data: { type } }));
  };

  const handleDownload = () => {
    dispatch(toggleModal({ visible: false }));
    const newFile = new File([file], `${filename}${fileFormat}`, {
      type: file.type
    });

    dispatch(
      sendAnalyticEvent({
        event: 'select_type_tap',
        data: {
          features_name: EXPORT_FORMATS_FEATURE_NAME_MAP[currentFormat],
          name_changed: isNameChanged,
          fromPage: getPrevPage() || ''
        }
      })
    );

    handleUploadFile(newFile, currentFormat, `${filename}${fileFormat}`);
  };

  useEffect(() => {
    dispatch(sendAnalyticEvent({ event: 'save_edited_file_modal_view' }));
    const fileFormat = getFileFormatFromFile(file);

    setFileFormat(fileFormat || '.pdf');
    setFilename(file.name.replace(new RegExp(fileFormat + '$'), ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalLayout
      canClose
      dataTestId='choose-format-modal'
    >
      <div
        className='flex flex-col items-center w-full mobile:min-w-[630px]
    mobile:max-w-[655px] pt-10 pb-8 mobile:pt-8 mobile:pb-7 tablet:pt-11 tablet:pb-[48px] px-4 tablet:px-[48px]'
      >
        <h2 className='mb-6 text-[#1D1D1D] font-[900] text-[22px] leading-[30px] tablet:text-[39px] tablet:leading-[54px] text-center'>
          {t('popups.converter_ocr_popup.title')}
        </h2>
        <div className='w-full mobile:flex mobile:flex-wrap justify-center gap-4 mb-4'>
          <FormatsList
            currentFormat={currentFormat}
            setCurrentFormat={handleSetCurrentFormat}
          />
        </div>
        <div className='flex flex-col w-full mb-8 px-4 tablet:px-0'>
          <label htmlFor='fileName'>{t('editor_page.convert_banner.file_name_placeholder')}</label>
          <input
            type='text'
            id='fileName'
            name='fileName'
            placeholder={t('editor_page.convert_banner.file_name_placeholder')}
            value={filename}
            data-testid='file-name-input'
            onChange={(e) => {
              setFilename(e.target.value);
              setIsNameChanged(true);
            }}
            className='rounded-md border border-[#C7C7C7] py-3 px-4 mt-2'
          />
        </div>
        <div className='flex flex-col gap-y-4 mobile:flex-row justify-center items-center mobile:justify-around tablet:justify-between w-full'>
          <PrimaryButton
            onClick={() => handleCancel('cancel')}
            dataTestId='cancel-button'
            className='bg-white border border-solid border-[#1D1D1D] hover:bg-[#1D1D1D] text-[#1D1D1D] py-[19px] mobile:py-4
          hover:!text-[#FFFFFF] min-w-[145px] w-full mobile:w-[267px] mobile:!text-[20px] mobile:!leading-[30px]'
          >
            {t('editor_page.convert_banner.cancel')}
          </PrimaryButton>
          <PrimaryButton
            dataTestId='convert-button'
            onClick={handleDownload}
            disabled={!filename}
            className='min-w-[145px] w-full mobile:w-[267px] border border-solid border-[#D2294B] py-[19px] mobile:py-4
            hover:border-[#f4476ae0] bg-[#D2294B] hover:bg-[#f4476ae0] mobile:!text-[20px] mobile:!leading-[30px]'
          >
            {t('editor_page.convert_banner.convert')}
          </PrimaryButton>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ChooseFormatAndParseTextModal;
