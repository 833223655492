import { useEffect } from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import backArrow from 'assets/img/icons/back-arrow.svg';
import multipleFiles from 'assets/img/icons/multiple-files.svg';
import plusIcon from 'assets/img/icons/plus.svg';
import classNames from 'classnames';
import { countPdfPages } from 'helpers/countPdfPages';
import { mergeImagesPageService } from 'helpers/services/mergeImageServiceList';
import { editorServicesList } from 'helpers/services/servicesList';
import useFileUploadAndConvertNewEditorFlow241, {
  MERGED_FILES_COUNT_KEY
} from 'hooks/fileFlows/ab_new_editor_flow_2_4_1/useFileUploadAndConvert';

import UploadButtonMerge from 'components/uploadButtons/uploadButtonMerge';

import { sendAnalyticEvent } from 'data/actions/analytics';
import { setMergeDocumentData } from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import { mergeDocumentsListSelector } from 'data/selectors/documents';

import { mergePdfFiles } from 'utils/mergePdf';

import { EModalTypes } from 'ts/enums/modal.types';
import type { IMergeDocument } from 'ts/interfaces/documents/document';

import FileList from './parts/fileList';

const MERGED_FILE_DEFAULT_NAME = 'merged.pdf';
export const MAX_FILES_ALLOWED = 30;

const MergePDFPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const filesList: IMergeDocument[] = useSelector(mergeDocumentsListSelector());

  const { handleUploadFile: handleUploadFileEditorFlow } = useFileUploadAndConvertNewEditorFlow241({
    service: editorServicesList(t)[0],
    modalType: EModalTypes.PROCESSING_MERGE
  });

  useEffect(() => {
    dispatch(
      sendAnalyticEvent({
        event: 'complete_merge_view',
        data: {
          screen_config_name: 'updated'
        }
      })
    );
  }, [dispatch]);

  const handleMergeFiles = async () => {
    const pagesCount = await Promise.all(filesList.map((item) => countPdfPages(item.file)));
    dispatch(
      sendAnalyticEvent({
        event: 'complete_merge_pdf_tap',
        data: {
          count: filesList.length,
          pages_count: pagesCount.reduce((acc, item) => acc + item, 0),
          show_pages: false
        }
      })
    );

    const resultFile = await mergePdfFiles(filesList, MERGED_FILE_DEFAULT_NAME);

    localStorage.removeItem(MERGED_FILES_COUNT_KEY);

    handleUploadFileEditorFlow(resultFile, { mergedFilesCount: filesList.length });
  };

  const handleLeavePage = () => {
    dispatch(sendAnalyticEvent({ event: 'merge_back_button_tap' }));
    dispatch(
      toggleModal({
        visible: true,
        type: EModalTypes.ARE_YOU_SURE_LEAVE_PAGE,
        options: {
          onAccept: () => {
            navigate(-1);
            dispatch(setMergeDocumentData([]));
          },
          type: 'merge'
        }
      })
    );
  };

  return (
    <div className='max-h-[100dvh] flex flex-col'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{t('complete_merge.title_all')}</title>
        <meta
          name='description'
          content='Merge'
        />
      </Helmet>
      <header className='flex flex-row gap-6 px-6 py-[30px] border-b items-center border-b-[#C7C7C7]'>
        <button
          onClick={() => handleLeavePage()}
          data-testid='back-button-merge-page'
        >
          <LazyLoadImage
            src={backArrow}
            alt='back arrow'
          />
        </button>
        <hr className='h-[24px] w-[1px] border-x-[0.5px] border-x-[#C7C7C7]'></hr>
        <h1 className='text-[24px] font-bold leading-none'>{t('complete_merge.title_all')}</h1>
      </header>
      <div className='px-6 py-3 flex gap-6 justify-between items-center'>
        <div className='flex flex-row px-2 py-3'>
          <UploadButtonMerge
            isDisabled={filesList && filesList?.length >= MAX_FILES_ALLOWED}
            service={mergeImagesPageService(t)}
            buttonText={t('complete_merge.add_file')}
            dataTestId='add-file-button'
            placeForAnalytics='button'
            customButton={
              <button
                className={classNames(
                  'flex items-center gap-2 transition-all bg-[#F6F6F6] hover:bg-[#E6E6E6] rounded-lg px-2 py-2 text-[16px] leading-none font-semibold h-[34px]',
                  'disabled:opacity-50  disabled:cursor-not-allowed disabled:pointer-events-none'
                )}
                disabled={filesList && filesList?.length >= MAX_FILES_ALLOWED}
              >
                <LazyLoadImage
                  className={classNames(filesList && filesList?.length >= MAX_FILES_ALLOWED && 'contrast-0')}
                  height={16}
                  width={16}
                  src={plusIcon}
                  alt='plus icon'
                />
                {t('complete_merge.add_file')}
              </button>
            }
          />
        </div>
        <button
          onClick={handleMergeFiles}
          disabled={filesList && filesList?.length < 2}
          data-testid='merge-files-button'
          className='px-6 py-3 flex items-center gap-2 bg-[#D2294B] hover:bg-[#B82442] rounded-xl text-[16px] leading-none text-white hover:cursor-pointer font-bold disabled:bg-[#E6E6E6] disabled:text-[#C7C7C7]'
        >
          <LazyLoadImage
            height={18}
            width={18}
            src={multipleFiles}
            className={classNames(filesList && filesList?.length < 2 && 'brightness-[0.75]')}
            alt='multiple files'
          />
          {t('complete_merge.merge_button')}
        </button>
      </div>
      <main className='py-6 overflow-y-auto'>
        <DndProvider backend={HTML5Backend}>
          <FileList />
        </DndProvider>
      </main>
    </div>
  );
};

export default MergePDFPage;
