import type { Dispatch, Store } from 'redux';
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import type { IReduxAction } from 'ts/interfaces/redux/redux.action';

import createAnalyticsMiddleware from './middleware/analytics';
import createApiMiddleware from './middleware/api';
import rootReducer from './reducers/rootReducer';

const apiMiddleware = createApiMiddleware();
const analyticsMiddleware = createAnalyticsMiddleware();
export default function configureStore(initialState: any = {}): Store<any, IReduxAction> & {
  dispatch: Dispatch;
} {
  const composeWithOptions =
    process.env.NODE_ENV === 'development'
      ? composeWithDevTools({ trace: true, maxAge: 100, traceLimit: 200 })
      : compose;

  return createStore(
    rootReducer,
    initialState,
    composeWithOptions(applyMiddleware(thunk, apiMiddleware, analyticsMiddleware))
  );
}
