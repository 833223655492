import { Analytics } from 'services/analytics';

import { EReduxTypes } from 'ts/enums/redux.types';
import type { IReduxAction } from 'ts/interfaces/redux/redux.action';
import type { IUserState } from 'ts/interfaces/user/user.state';

const initialState: IUserState = {
  userData: null,
  userId: null,
  countryCode: null,
  pageView: null,
  trustPilotLink: null,
  queryUserId: null,
  queryUserCountry: null
};

const userReducer = (state = initialState, action: IReduxAction): IUserState => {
  switch (action.type) {
    case EReduxTypes.LOGIN_BY_TOKEN:
    case EReduxTypes.SIGN_UP: {
      return state;
    }
    case EReduxTypes.SIGN_IN:
    case EReduxTypes.CONFIRM_ACCOUNT:
    case EReduxTypes.GOOGLE_AUTH: {
      return state;
    }
    case EReduxTypes.SET_QUERY_USER_ID: {
      if (action.data.userId) {
        return {
          ...state,
          queryUserId: action.data.userId
        };
      }
      return state;
    }

    case EReduxTypes.SET_QUERY_USER_COUNTRY: {
      if (action.data.userCountry) {
        return {
          ...state,
          queryUserCountry: action.data.userCountry
        };
      }
      return state;
    }
    case EReduxTypes.CREATE_ANONYMOUS_USER: {
      if (action?.payload?.id) {
        return {
          ...state,
          userId: action?.payload?.id
        };
      }
      return state;
    }
    case EReduxTypes.SIGN_OUT: {
      return initialState;
    }
    case EReduxTypes.SET_USER_EMAIL: {
      return {
        ...state,
        userData: {
          ...state.userData,
          email: action.data?.newEmail
        }
      };
    }

    case EReduxTypes.GET_USER: {
      return {
        ...state,
        // @ts-expect-error review this peace of code, it seems wrong
        userData: action?.payload
      };
    }

    case EReduxTypes.SET_USER_COUNTRY: {
      localStorage.setItem('userCountry', action?.data?.userCountry);
      return {
        ...state,
        countryCode: action?.data?.userCountry
      };
    }

    case EReduxTypes.GET_USER_COUNTRY: {
      localStorage.setItem('userCountry', action?.payload?.country);
      return {
        ...state,
        countryCode: action?.payload?.country
      };
    }

    case EReduxTypes.CANCEL_SUBSCRIPTION: {
      const newUserData = structuredClone(state?.userData);
      if (newUserData?.subscription) newUserData.subscription.cancelCode = '8.06';
      return {
        ...state,
        userData: newUserData
      };
    }

    case EReduxTypes.RESTORE_SUBSCRIPTION: {
      const newUserData = structuredClone(state?.userData);
      if (newUserData?.subscription) newUserData.subscription.cancelCode = null;
      return {
        ...state,
        userData: newUserData
      };
    }

    case EReduxTypes.SET_PAGE_VIEW: {
      return {
        ...state,
        pageView: action.data
      };
    }
    case EReduxTypes.GET_USER_SUBSCRIPTION: {
      return {
        ...state,
        userData: {
          ...state.userData,
          subscription: action.payload
        }
      };
    }

    case EReduxTypes.GET_UPLOAD_LINK: {
      const newUploadedFilesCount = action.payload?.length || 1;
      Analytics.sendEvent({
        event: 'file_upload_counter',
        data: { amount: newUploadedFilesCount }
      });
      return {
        ...state,
        userData: {
          ...state.userData,
          count_uploaded_files: (state?.userData?.count_uploaded_files || 0) + newUploadedFilesCount
        }
      };
    }

    case EReduxTypes.GET_TRUST_PILOT_LINK: {
      return {
        ...state,
        trustPilotLink: action.payload?.url
      };
    }
    default:
      return state;
  }
};

export default userReducer;
