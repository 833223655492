export enum CardPaymentError {
  SUSPECTED_FRAUD = 'SUSPECTED_FRAUD',
  DO_NOT_HONOR = 'DO_NOT_HONOR',
  DECLINED_BY_ISSUER = 'DECLINED_BY_ISSUER',
  CARD_IS_BLOCKED = 'CARD_IS_BLOCKED',
  GENERAL_DECLINE = 'GENERAL_DECLINE',
  ANTIFRAUD_SERVICE = 'ANTIFRAUD_SERVICE',
  INVALID_TRANSACTION = 'INVALID_TRANSACTION',
  INVALID_EXPIRATION_DATE = 'INVALID_EXPIRATION_DATE',
  STOLEN_CARD = 'STOLEN_CARD',
  UNKNOWN_DECLINE_CODE = 'UNKNOWN_DECLINE_CODE',
  THREE_D_SECURE = 'THREE_D_SECURE',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  CARD_BRAND_NOT_SUPPORTED = 'CARD_BRAND_NOT_SUPPORTED',
  RESTRICTED_CARD = 'RESTRICTED_CARD',
  INVALID_CVV_CODE = 'INVALID_CVV_CODE',
  INVALID_CARD_NUMBER = 'INVALID_CARD_NUMBER',
  ORDER_EXPIRED = 'ORDER_EXPIRED',
  ILLEGAL_OPERATION = 'ILLEGAL_OPERATION',
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
  INVALID_DATA = 'INVALID_DATA',
  INVALID_AMOUNT = 'INVALID_AMOUNT',
  INVALID_CURRENCY = 'INVALID_CURRENCY',
  ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
  REQUEST_IS_EMPTY = 'REQUEST_IS_EMPTY',
  INVALID_THREE_DS_FLOW_MERCHANT_SIDE = 'INVALID_THREE_DS_FLOW_MERCHANT_SIDE',
  INVALID_THREE_DS_FLOW_BANK_SIDE = 'INVALID_THREE_DS_FLOW_BANK_SIDE',
  INVALID_THREE_DS_FLOW = 'INVALID_THREE_DS_FLOW',
  INVALID_IP = 'INVALID_IP',
  SUBSCRIPTION_ERROR = 'SUBSCRIPTION_ERROR',
  SCA_REQUIRE_THREE_D_AUTHENTICATION = 'SCA_REQUIRE_THREE_D_AUTHENTICATION',
  SUBSCRIPTION_IS_LOCKED = 'SUBSCRIPTION_IS_LOCKED',
  PAYMENT_AMOUNT_LIMIT_EXCESS = 'PAYMENT_AMOUNT_LIMIT_EXCESS',
  DECLINED_BY_THE_ISSUER = 'DECLINED_BY_THE_ISSUER',
  CALL_YOUR_BANK = 'CALL_YOUR_BANK',
  DEBIT_CARD_NOT_SUPPORTED = 'DEBIT_CARD_NOT_SUPPORTED',
  THREE_D_SECURE_AUTH_REQUIRED = 'THREE_D_SECURE_AUTH_REQUIRED',
  RECURRING_PAYMENT_CANCELLED = 'RECURRING_PAYMENT_CANCELLED',
  CARD_IS_IN_A_BANK_LIST = 'CARD_IS_IN_A_BANK_LIST',
  LOST_CARD = 'LOST_CARD',
  PSP_FRAUD = 'PSP_FRAUD',
  BLOCKED_BY_COUNTRY_IP = 'BLOCKED_BY_COUNTRY_IP',
  TRUSTED_ANTIFRAUD_SYSTEM = 'TRUSTED_ANTIFRAUD_SYSTEM',
  AVS_MISMATCH = 'AVS_MISMATCH',
  INVALID_CARD_TOKEN = 'INVALID_CARD_TOKEN',
  APPLICATION_ERROR = 'APPLICATION_ERROR',
  MERCH_IS_NOT_CONFIG_CORRECTLY = 'MERCH_IS_NOT_CONFIG_CORRECTLY',
  MERCH_IS_NOT_ACTIVATED_YET = 'MERCH_IS_NOT_ACTIVATED_YET',
  DUPLICATE_ORDER = 'DUPLICATE_ORDER',
  EXCEEDED_API_CALLS_LIMITS = 'EXCEEDED_API_CALLS_LIMITS',
  MERCH_NOT_FOUND = 'MERCH_NOT_FOUND',
  PROCESSOR_NOT_SUPPORT_API = 'PROCESSOR_NOT_SUPPORT_API',
  INVALID_ROUTING = 'INVALID_ROUTING',
  ACCOUNT_IS_BLOCKED = 'ACCOUNT_IS_BLOCKED',
  CONNECTION_ERROR = 'CONNECTION_ERROR',
  CARD_TOKEN_NOT_FOUND = 'CARD_TOKEN_NOT_FOUND',
  GOOGLE_PAYMENT_ERROR = 'GOOGLE_PAYMENT_ERROR',
  SMART_CASCADE_DECLINE = 'SMART_CASCADE_DECLINE',
  THREE_D_CASCADE_TO_TWO_D = 'THREE_D_CASCADE_TO_TWO_D',
  APPLE_ONLINE_PAYMENT_ERROR = 'APPLE_ONLINE_PAYMENT_ERROR',
  TOKEN_GENERATION_ERROR = 'TOKEN_GENERATION_ERROR',
  SCA_ENGINE_ERROR = 'SCA_ENGINE_ERROR'
}

export const errorCodeMap: Record<string, CardPaymentError> = {
  '0.01': CardPaymentError.GENERAL_DECLINE,
  '0.02': CardPaymentError.ORDER_EXPIRED,
  '0.03': CardPaymentError.ILLEGAL_OPERATION,
  '1.01': CardPaymentError.AUTHENTICATION_FAILED,
  '2.01': CardPaymentError.INVALID_DATA,
  '2.02': CardPaymentError.INVALID_AMOUNT,
  '2.03': CardPaymentError.INVALID_CURRENCY,
  '2.05': CardPaymentError.ORDER_NOT_FOUND,
  '2.06': CardPaymentError.INVALID_CVV_CODE,
  '2.07': CardPaymentError.REQUEST_IS_EMPTY,
  '2.08': CardPaymentError.INVALID_CARD_NUMBER,
  '2.09': CardPaymentError.INVALID_EXPIRATION_DATE,
  '2.10': CardPaymentError.INVALID_THREE_DS_FLOW_MERCHANT_SIDE,
  '2.11': CardPaymentError.INVALID_THREE_DS_FLOW_BANK_SIDE,
  '2.12': CardPaymentError.INVALID_THREE_DS_FLOW,
  '2.13': CardPaymentError.INVALID_IP,
  '2.14': CardPaymentError.SUBSCRIPTION_ERROR,
  '2.15': CardPaymentError.SCA_REQUIRE_THREE_D_AUTHENTICATION,
  '2.16': CardPaymentError.SUBSCRIPTION_IS_LOCKED,
  '3.01': CardPaymentError.CARD_IS_BLOCKED,
  '3.02': CardPaymentError.INSUFFICIENT_FUNDS,
  '3.03': CardPaymentError.PAYMENT_AMOUNT_LIMIT_EXCESS,
  '3.04': CardPaymentError.DECLINED_BY_ISSUER,
  '3.05': CardPaymentError.CALL_YOUR_BANK,
  '3.06': CardPaymentError.DEBIT_CARD_NOT_SUPPORTED,
  '3.07': CardPaymentError.CARD_BRAND_NOT_SUPPORTED,
  '3.08': CardPaymentError.DO_NOT_HONOR,
  '3.09': CardPaymentError.THREE_D_SECURE_AUTH_REQUIRED,
  '3.10': CardPaymentError.SUSPECTED_FRAUD,
  '3.11': CardPaymentError.RECURRING_PAYMENT_CANCELLED,
  '4.01': CardPaymentError.CARD_IS_IN_A_BANK_LIST,
  '4.02': CardPaymentError.STOLEN_CARD,
  '4.03': CardPaymentError.RESTRICTED_CARD,
  '4.04': CardPaymentError.LOST_CARD,
  '4.05': CardPaymentError.PSP_FRAUD,
  '4.06': CardPaymentError.BLOCKED_BY_COUNTRY_IP,
  '4.07': CardPaymentError.TRUSTED_ANTIFRAUD_SYSTEM,
  '4.08': CardPaymentError.AVS_MISMATCH,
  '4.09': CardPaymentError.ANTIFRAUD_SERVICE,
  '5.01': CardPaymentError.THREE_D_SECURE,
  '5.02': CardPaymentError.INVALID_CARD_TOKEN,
  '5.03': CardPaymentError.APPLICATION_ERROR,
  '5.04': CardPaymentError.MERCH_IS_NOT_CONFIG_CORRECTLY,
  '5.05': CardPaymentError.MERCH_IS_NOT_ACTIVATED_YET,
  '5.06': CardPaymentError.DUPLICATE_ORDER,
  '5.07': CardPaymentError.EXCEEDED_API_CALLS_LIMITS,
  '5.08': CardPaymentError.INVALID_TRANSACTION,
  '5.09': CardPaymentError.MERCH_NOT_FOUND,
  '5.10': CardPaymentError.PROCESSOR_NOT_SUPPORT_API,
  '5.11': CardPaymentError.INVALID_ROUTING,
  '5.12': CardPaymentError.ACCOUNT_IS_BLOCKED,
  '6.01': CardPaymentError.UNKNOWN_DECLINE_CODE,
  '6.02': CardPaymentError.CONNECTION_ERROR,
  '7.01': CardPaymentError.CARD_TOKEN_NOT_FOUND,
  '7.02': CardPaymentError.GOOGLE_PAYMENT_ERROR,
  '7.03': CardPaymentError.SMART_CASCADE_DECLINE,
  '7.04': CardPaymentError.THREE_D_CASCADE_TO_TWO_D,
  '7.05': CardPaymentError.APPLE_ONLINE_PAYMENT_ERROR,
  '7.06': CardPaymentError.TOKEN_GENERATION_ERROR,
  '7.07': CardPaymentError.SCA_ENGINE_ERROR
};
