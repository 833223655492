// return a promise that resolves with a File instance
export function urltoFile(url: string, filename: string, mimeType: string) {
  if (url.startsWith('data:')) {
    const arr = url.split(',');
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file = new File([u8arr], filename, { type: mime || mimeType });
    return Promise.resolve(file);
  }
  return fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }));
}

// Usage example:
// urltoFile('data:text/plain;base64,aGVsbG8=', 'hello.txt','text/plain')
// .then(function(file){ console.log(file);});
