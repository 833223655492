import { useState } from 'react';

import { useDispatch } from 'react-redux';

import axios from 'axios';
import { countPdfPages } from 'helpers/countPdfPages';

import { getUploadLink, saveUploadedFile } from 'data/actions/documents';

import { getFileKeyFromAWSLink } from 'utils/getFileKeyFromAWSLink';

export interface UploadFileResponse {
  success: boolean;
  fileData?: {
    fileId: string | null;
    uploadLink: string;
    size: number;
    filename: string;
    key: string;
    pagesCount: number;
  };
  error?: string;
}

const useFileUpload = () => {
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleUploadFileToS3 = async (file: File, uploadLink: string): Promise<UploadFileResponse> => {
    try {
      setIsUploading(true);
      setError(null);

      const res = await axios.put(uploadLink, file, {
        headers: {
          'Content-Type': file.type
        }
      });

      const fileKey = getFileKeyFromAWSLink(res?.request?.responseURL);

      const fileExtension = file.name.split('.').pop()?.toUpperCase() || 'PDF';

      let pagesCount = 0;
      if (fileExtension === 'PDF') {
        try {
          pagesCount = (await countPdfPages(file)) || 1;
        } catch {
          throw new Error('Failed to count PDF pages');
        }
      }

      const fileData = {
        fileId: '',
        uploadLink,
        size: file.size,
        filename: file.name,
        key: fileKey,
        pagesCount
      };

      await new Promise<void>((resolve, reject) => {
        dispatch(
          saveUploadedFile({
            fileData,
            onSuccess: (res: any) => {
              fileData.fileId = res.id;

              resolve(res);
            },
            onFailed: () => reject(new Error('Failed to save file metadata'))
          })
        );
      });

      setIsUploading(false);
      return { success: true, fileData };
    } catch (error) {
      setIsUploading(false);
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred during file upload';
      setError(errorMessage);
      return { success: false, error: errorMessage };
    }
  };

  const uploadFile = async (file: File): Promise<UploadFileResponse> => {
    if (!file) {
      setError('No file selected');
      return { success: false, error: 'No file selected' };
    }

    try {
      const uploadLinkResponse = await new Promise<any[]>((resolve, reject) => {
        dispatch(
          getUploadLink({
            filename: file.name,
            onSuccess: resolve,
            onFailed: () => reject(new Error('Failed to get upload link'))
          })
        );
      });

      const uploadUrl = uploadLinkResponse[0]?.url;

      if (!uploadUrl) {
        throw new Error('No upload URL received');
      }

      return await handleUploadFileToS3(file, uploadUrl);
    } catch (err) {
      setIsUploading(false);
      const errorMessage = err instanceof Error ? err.message : 'An unknown error occurred during file upload';
      setError(errorMessage);
      return { success: false, error: errorMessage };
    }
  };

  return { uploadFile, isUploading, error };
};

export default useFileUpload;
