import * as process from 'process';

export const config = {
  foxit: {
    licenseSN: process.env.NEXT_PUBLIC_FOXIT_LICENSE_SN,
    licenseKey: process.env.NEXT_PUBLIC_FOXIT_LICENSE_KEY
  },
  api: {
    url: process.env.NEXT_PUBLIC_API_URL,
    maxFileSizeMb: parseInt(process.env.NEXT_PUBLIC_MAX_FILE_SIZE_MB || '100')
  },
  googleOneTap: process.env.NEXT_PUBLIC_GOOGLE_ONE_TAP_CLIENT_ID,
  appEnv: process.env.NEXT_PUBLIC_APP_ENV || 'local',
  gads: {
    pdfMaster: {
      trial: process.env.NEXT_PUBLIC_GADS_PDFM_TRIAL_HOOK || '',
      payment: process.env.NEXT_PUBLIC_GADS_PDFM_PAYMENT_HOOK || ''
    },
    pdf5: {
      trial: process.env.NEXT_PUBLIC_GADS_PDF5_TRIAL_HOOK || '',
      payment: process.env.NEXT_PUBLIC_GADS_PDF5_PAYMENT_HOOK || ''
    }
  },
  growthbook: {
    clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENTKEY
  }
};
