import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useCloseModalOnBack from 'hooks/useCloseModalOnBack';
import useDisableScroll from 'hooks/useDisableScroll';

import { PrimaryButton } from 'components/buttons/primary-button';

import { Analytics } from 'services/analytics';

import { toggleModal } from 'data/actions/modals';
import { modalFromEventSelector, modalOptionsSelector } from 'data/selectors/modals';
import { trustPilotLinkSelector, userDataSelector } from 'data/selectors/user';

import ModalLayout from '../baseModal/base';
import rateImage from './assets/illustration-contactus.svg';
import thankYouImage from './assets/thank-you-illustration.svg';
import { renderRating } from './renderRating';

const TrustPilotModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(userDataSelector);
  const trustPilotLink = useSelector(trustPilotLinkSelector);
  const fromEvent = useSelector(modalFromEventSelector);
  const { redirectFunction } = useSelector(modalOptionsSelector);
  const [userRate, setUserRate] = useState<number>(0);
  const [temporaryRating, setTemporaryRating] = useState(userRate);
  const [userAnswer, setUserAnswer] = useState<string>('');
  const [hasRated, setHasRated] = useState<boolean>(false);
  const [isViewed, setIsViewed] = useState<boolean>(false);

  const [showUserAnswerField, setShowUserAnswerField] = useState(false);
  useDisableScroll();
  useCloseModalOnBack();

  useEffect(() => {
    if (isViewed && !user?.email) {
      return;
    }
    Analytics.sendEvent({
      event: 'rate_us_modal_view',
      data: { email: user?.email, from_event: fromEvent }
    });
    setIsViewed(true);
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!hasRated) {
      return;
    }
    if (userRate > 3 && !showUserAnswerField) {
      Analytics.sendEvent({ event: 'rate_us_in_public_modal_view' });
      return;
    }

    Analytics.sendEvent({ event: 'rate_us_close_modal_view' });
  }, [dispatch, hasRated, showUserAnswerField, userRate, user]);

  const onSendFeedbackClick = () => {
    Analytics.sendEvent({
      event: 'rate_us_tap',
      data: {
        rate: userRate,
        answer: userAnswer,
        email: user?.email
      }
    });
    setHasRated(true);

    setTimeout(() => {
      dispatch(toggleModal({ visible: false }));
    }, 3000);
  };

  const onUserRateClick = (newRate: number) => {
    setUserRate(newRate);

    if (newRate > 3 && !showUserAnswerField) {
      window.open(trustPilotLink, '_blank');
      Analytics.sendEvent({
        event: 'rate_us_tap',
        data: {
          rate: newRate,
          answer: userAnswer,
          email: user?.email
        }
      });
      localStorage.removeItem('showRate');
      localStorage.removeItem('hasDownloadedFile');
      dispatch(toggleModal({ visible: false }));
      if (typeof redirectFunction === 'function') {
        redirectFunction();
      }
      return;
    }

    if (!showUserAnswerField) {
      setShowUserAnswerField(true);
    }

    if (typeof redirectFunction === 'function') {
      redirectFunction();
    }
  };

  const handleCloseModal = useCallback(() => {
    dispatch(toggleModal({ visible: false }));
    if (typeof redirectFunction === 'function') {
      redirectFunction();
    }

    if (!hasRated) {
      Analytics.sendEvent({ event: 'rate_us_close_tap' });
      return;
    }

    if (userRate > 3 && !showUserAnswerField) {
      Analytics.sendEvent({ event: 'rate_us_in_public_close_tap' });
      return;
    }
    Analytics.sendEvent({
      event: userRate > 0 ? 'rate_us_close_close_tap' : 'rate_us_close_tap'
    });
  }, [dispatch, hasRated, showUserAnswerField, userRate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (hasRated) {
    return (
      <ModalLayout
        canClose
        closePopup={handleCloseModal}
      >
        <div className='bg-white flex-col items-center py-8 px-4 tablet:py-8 tablet:px-[48px]'>
          <img
            src={thankYouImage}
            alt='thank you'
            className='mx-auto h-[130px] tablet:h-[160px]'
          />
          <div className='text-[20px] tablet:text-[25px] text-[#1D1D1D] font-bold text-center mt-4 tablet:mt-[25px] tablet:max-w-[460px]'>
            {t('popups.rate_us_popup.thanks_title_low_score')}
          </div>
        </div>
      </ModalLayout>
    );
  }

  return (
    <ModalLayout
      canClose
      closePopup={handleCloseModal}
      dataTestId='trust-pilot-modal'
    >
      <div className='bg-white flex-col items-center tablet:min-w-[560px] py-8 px-4 tablet:py-8 tablet:px-[48px]'>
        <img
          src={rateImage}
          alt='thank you'
          className='mx-auto h-[130px] tablet:h-[160px]'
        />
        <div className='text-[20px] tablet:text-[25px] text-[#1D1D1D] font-bold text-center mt-4 tablet:mt-[25px]'>
          {t('popups.rate_us_popup.rate_title')}
        </div>
        <div className='text-base tablet:text-[20px] text-[#575757] text-center mt-2 tablet:max-w-[430px]'>
          {t('popups.rate_us_popup.rate_subtitle')}
        </div>
        <div className='mt-4 tablet:mt-8'>
          {renderRating({
            temporaryRating,
            userRating: userRate,
            onClick: onUserRateClick,
            setTemporaryRating
          })}
        </div>
        {showUserAnswerField && (
          <>
            {' '}
            <div className='text-[#1D1D1D] text-base mt-4 tablet:mt-8'>{t('popups.rate_us_popup.rate_answer')}</div>
            <div>
              <textarea
                name='answer'
                id='answer'
                style={{ resize: 'none' }}
                placeholder={`${t('popups.rate_us_popup.rate_placeholder')}`}
                value={userAnswer}
                onChange={(event) => setUserAnswer(event?.target?.value)}
                className='border border-solid border-[#DEDEDE] rounded-[5px] w-full h-[140px] p-4'
              ></textarea>
            </div>
            <PrimaryButton
              className='w-full mt-4 tablet:mt-8'
              onClick={onSendFeedbackClick}
              disabled={userRate === 0}
            >
              {t('popups.rate_us_popup.rate_button')}
            </PrimaryButton>
          </>
        )}
      </div>
    </ModalLayout>
  );
};

export default TrustPilotModal;
