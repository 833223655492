import React, { useCallback, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useLocaleNavigate } from 'hooks/useLocaleNavigate';

import { Analytics } from 'services/analytics';

import { createAnonymousUser, getUser, signOut } from 'data/actions/user';
import { isUserAuthenticated, queryUserIdSelector } from 'data/selectors/user';

import type { DropDownList } from 'utils/links';
import {
  getLinksFormsShortList,
  getLinksFrom,
  getLinksMergeSplit,
  getLinksSecurity,
  getLinksTo,
  getLinksTools
} from 'utils/links';

import { PAGE_LINKS } from 'ts/constants/page-links';
import type { InternalFileType } from 'ts/types/file';
import { InternalType } from 'ts/types/file';

export interface IHeaderUploadSectionInteractor {
  clickOnLogo: () => void;
  convertDropDown: {
    toggle: () => void;
    isOpen: boolean;
    label: string;
    from: DropDownList;
    to: DropDownList;
    triggerRef: React.RefObject<HTMLDivElement>;
    onClickHeaderFeature: (
      from: InternalFileType,
      to: InternalFileType,
      featureName: string,
      linkType?: string
    ) => void;
  };
  links: {
    from: DropDownList;
    to: DropDownList;
    tools: DropDownList;
    mergeSplit: DropDownList;
    security: DropDownList;
    forms: DropDownList;
  };
  onLogin: () => void;
  onMobileLogin: () => void;
  onLogout: () => void;
  onDashboard: () => void;
  onMobileDashboard: () => void;
  mobileNav: {
    isVisible: boolean;
    toggle: () => void;
  };
  isAuthorized: boolean;

  isMainPage: boolean;
  onMobileContactUs: () => void;
  onContactUsClick: () => void;
}

export const useHeaderUploadSectionInteractor = (): IHeaderUploadSectionInteractor => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useLocaleNavigate();
  const location = useLocation();
  const isAuth = useSelector(isUserAuthenticated);
  const triggerRef = useRef<HTMLDivElement>(null);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);

  const queryUserId = useSelector(queryUserIdSelector);

  const toggleConvertDropDown = useCallback(() => {
    setIsDropDownOpen((prev) => !prev);
    void Analytics.sendEvent({
      event: 'tools_tap'
    });
  }, []);

  const toggleMobileNav = React.useCallback(() => {
    setIsMobileNavVisible((prev) => !prev);
  }, []);

  const onMobileLogin: IHeaderUploadSectionInteractor['onLogin'] = () => {
    const redirect = () => {
      navigate(PAGE_LINKS.LOGIN);
    };
    void Analytics.sendEvent({
      event: 'log_in_tap'
    })
      .then(redirect)
      .catch(redirect);
  };

  const onLogin: IHeaderUploadSectionInteractor['onLogin'] = () => {
    navigate(PAGE_LINKS.LOGIN);
    void Analytics.sendEvent({
      event: 'log_in_tap'
    });
  };

  const clickOnLogo = () => {
    void Analytics.sendEvent({
      event: 'main_page_tap'
    });
  };

  const onDashboard: IHeaderUploadSectionInteractor['onDashboard'] = () => {
    navigate(PAGE_LINKS.DASHBOARD);
    void Analytics.sendEvent({
      event: 'dashboard_tap'
    });
  };

  const onMobileDashboard: IHeaderUploadSectionInteractor['onDashboard'] = () => {
    const redirect = () => {
      navigate(PAGE_LINKS.DASHBOARD);
    };
    void Analytics.sendEvent({
      event: 'dashboard_tap'
    })
      .then(redirect)
      .catch(redirect);
  };

  const onLogout: IHeaderUploadSectionInteractor['onLogout'] = () => {
    const logoutAndRedirect = () => {
      dispatch(
        signOut(() => {
          dispatch(createAnonymousUser(queryUserId || '', () => dispatch(getUser({}))));
          navigate(PAGE_LINKS.MAIN);
        })
      );
    };

    void Analytics.sendEvent({
      event: 'log_out_tap'
    }).finally(logoutAndRedirect);
  };

  const onMobileContactUs: IHeaderUploadSectionInteractor['onDashboard'] = () => {
    navigate(PAGE_LINKS.CONTACT_US);
    Analytics.sendEvent({ event: 'contact_us_tap' });
  };

  const onContactUsClick = () => {
    Analytics.sendEvent({ event: 'contact_us_tap' });
  };

  const onClickHeaderFeature = (from: InternalFileType, to: InternalFileType, featureName: string) => {
    const fromTitle = InternalType.toTitle(from).toLowerCase();
    const toTitle = InternalType.toTitle(to).toLowerCase();

    if (['merge-pdf', 'split-pdf', 'compress-pdf', 'sign-pdf', 'password-protect-pdf'].includes(featureName)) {
      void Analytics.sendEvent({
        event: 'header_features_tap',
        data: { features_name: featureName }
      });

      return;
    }

    if (featureName.includes('form')) {
      Analytics.sendEvent({
        event: 'header_features_tap',
        data: { features_name: featureName }
      });

      return;
    }

    void Analytics.sendEvent({
      event: 'header_features_tap',
      data: {
        features_name: `${fromTitle}_to_${toTitle}`
      }
    });
  };

  return {
    clickOnLogo,
    convertDropDown: {
      toggle: toggleConvertDropDown,
      isOpen: isDropDownOpen,
      label: t('header.dropdown.label'),
      from: getLinksFrom(t),
      to: getLinksTo(t),
      triggerRef,
      onClickHeaderFeature
    },
    links: {
      from: getLinksFrom(t),
      to: getLinksTo(t),
      tools: getLinksTools(t),
      mergeSplit: getLinksMergeSplit(t),
      security: getLinksSecurity(t),
      forms: getLinksFormsShortList(t)
    },
    onLogin,
    onMobileLogin,
    onLogout,
    onDashboard,
    onMobileDashboard,
    mobileNav: {
      isVisible: isMobileNavVisible,
      toggle: toggleMobileNav
    },
    isAuthorized: isAuth,

    isMainPage: location.pathname === `/`,
    onMobileContactUs,
    onContactUsClick
  };
};
