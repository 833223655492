import type { FC } from 'react';
import React from 'react';

import { useTranslation } from 'react-i18next';

import { LoadingAnimation } from 'components/loading-animation';

export const LoadingOverlay: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='w-full h-full fixed top-0 left-0 z-[10006] bg-[rgba(0,0,0,0.6)] flex justify-center items-center px-4'>
      <div className='max-w-[557px] w-full h-[300px] tablet:h-[340px] p-6 flex flex-col justify-center items-center bg-white rounded-[10px] tablet:text-[22px] text-[18px] leading-[24px] text-[#373737]'>
        <LoadingAnimation
          currentState='loading'
          className='tablet:!w-[160px] tablet:!h-[160px] !w-[100px] !h-[100px]'
        />
        <p className='font-[700] tablet:my-4 my-2 text-center'>{t('editor_page.saving')}</p>
        <p className='text-[#878787] text-[16px]'>{t('editor_page.please_wait')}</p>
      </div>
    </div>
  );
};
