import type { AnyAction } from 'redux';

import { EReduxTypes } from 'ts/enums/redux.types';
import type { IReduxAction } from 'ts/interfaces/redux/redux.action';
import type { IGetInvoiceUrlResponse } from 'ts/interfaces/subscriptions/subscriptions';

export const getUserSubscription = (onSuccess?: unknown, onFailed?: unknown): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_USER_SUBSCRIPTION,
    payload: {
      method: 'GET',
      endpoint: 'api/v1/user/subscription'
    },
    components: ['get_user_subscription'],
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    }
  };
  return action;
};

export const getPlans = ({
  userCountry,
  onSuccess,
  newSalesVariant,
  germanyFreeTrial
}: {
  userCountry?: string;
  onSuccess?: unknown;
  newSalesVariant?: string;
  germanyFreeTrial?: string;
}): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_PLANS,
    payload: {
      method: 'GET',
      endpoint: 'api/v1/products'
    },
    data: {
      userCountry,
      newSalesVariant,
      germanyFreeTrial
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    }
  };
  return action;
};

export const initPaymentCard = (productId: string, onSuccess?: any): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.INIT_PAYMENT_CARD,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/payment/init/card/subscription',
      body: {
        productId
      }
    },
    onSuccess: (res: any) => {
      if (onSuccess) onSuccess(res);
    }
  };
  return action;
};

export const initAlternativeSubscription = ({
  productId,
  customerEmail,
  paymentMethod,
  onSuccess
}: {
  productId: string;
  customerEmail: string;
  paymentMethod: string;
  onSuccess?: unknown;
}): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.INIT_USER_SUBSCRIPTION,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/payment/init/alt/subscription',
      body: {
        productId,
        customerEmail,
        paymentMethod
      }
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    }
  };
  return action;
};

export const setSelectedPlan = (plan: any): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_SELECTED_PLAN_ID,
    data: {
      plan
    }
  };
  return action;
};

export const setOrderData = (order: any): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_ORDER_DATA,
    data: order
  };
  return action;
};

export const cancelSubscription = (subscriptionId: string, onSuccess?: unknown, onError?: unknown): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CANCEL_SUBSCRIPTION,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/subscription/cancel',
      body: {
        subscriptionId
      }
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (res: any) => {
      if (typeof onError === 'function') onError(res);
    }
  };
  return action;
};

export const restoreSubscription = (onSuccess?: unknown): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.RESTORE_SUBSCRIPTION,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/subscription/restore'
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    }
  };
  return action;
};

export const switchSubscription = ({
  subscriptionId,
  newProductId,
  onSuccess,
  onFailed
}: {
  subscriptionId: string;
  newProductId: string;
  onSuccess?: unknown;
  onFailed?: unknown;
}): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.RESTORE_SUBSCRIPTION,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/subscription/switch',
      body: {
        subscriptionId,
        newProductId
      }
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    }
  };
  return action;
};

export const getFreeAccess = (onSuccess?: unknown, onFailed?: unknown): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_FREE_ACCESS,
    payload: {
      method: 'GET',
      endpoint: 'api/v1/free-access'
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    }
  };
  return action;
};

export const getInvoices = (onSuccess?: unknown, onFailed?: unknown): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_INVOICES,
    payload: {
      method: 'GET',
      endpoint: 'api/v1/user/invoice'
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    }
  };
  return action;
};

export const getInvoiceUrl = ({
  invoiceId,
  onSuccess,
  onFailed
}: {
  invoiceId: string;
  onSuccess?: unknown;
  onFailed?: unknown;
}): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_INVOICE_URL,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/user/invoice',
      body: {
        invoiceId
      }
    },
    onSuccess: (res: IGetInvoiceUrlResponse) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    }
  };
  return action;
};
