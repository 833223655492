import type { AnyAction } from 'redux';

import { EReduxTypes } from 'ts/enums/redux.types';
import type { IBaseAnalyticEvent, IReduxAction } from 'ts/interfaces/redux/redux.action';

export const sendAnalyticEvent = (event: IBaseAnalyticEvent): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SEND_ANALYTIC_EVENT,
    analyticEvent: event
  };
  return action;
};
