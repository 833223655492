import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { featureFlags } from 'feature-flags';

import { IconButton } from 'components/buttons/icon-button-new-design';
import { PrimaryButton } from 'components/buttons/primary-button';
import { SecondaryButton } from 'components/buttons/secondary-button';
import LocalizedLink from 'components/localized-link';

import { Analytics } from 'services/analytics';

import type { DropDownList } from 'utils/links';
import {
  getLinksFormsShortList,
  getLinksFrom,
  getLinksMergeSplit,
  getLinksSecurity,
  getLinksTo,
  getLinksTools
} from 'utils/links';

import { PAGE_LINKS } from 'ts/constants/page-links';
import { InternalFileType, InternalType } from 'ts/types/file';

import s from './mobile-nav-new-design.module.scss';

interface IProps {
  isAuthorized: boolean;
  isContactUsShown: boolean;
  onDashboard: () => void;
  onLogout: () => void;
  onLogin: () => void;
  onMobileContactUs: () => void;
  isMainPage: boolean;
  toggleMobileNav?: () => void;
}

export const MobileNav: React.FC<IProps> = ({
  toggleMobileNav,
  isAuthorized,
  isMainPage,
  onDashboard,
  onLogin,
  onLogout,
  onMobileContactUs
}) => {
  const { t } = useTranslation();
  const onClickHeaderFeature = (
    from: InternalFileType,
    to: InternalFileType,
    featureName: string,
    linkType?: string
  ) => {
    if (linkType) {
      localStorage.setItem('successPageType', linkType);
    }
    const fromTitle = InternalType.toTitle(from).toLowerCase();
    const toTitle = InternalType.toTitle(to).toLowerCase();

    if (featureName.includes('form')) {
      Analytics.sendEvent({
        event: 'header_features_tap',
        data: { features_name: featureName }
      });

      return;
    }

    void Analytics.sendEvent({
      event: 'header_features_tap',
      data: {
        features_name: `${fromTitle}_to_${toTitle}`
      }
    });

    if (typeof toggleMobileNav === 'function') toggleMobileNav();
  };

  const renderActionButtons = useCallback(() => {
    if (isAuthorized) {
      return (
        <>
          {isMainPage && (
            <LocalizedLink href={PAGE_LINKS.CONTACT_US}>
              <SecondaryButton
                onClick={onMobileContactUs}
                className='bg-[#F7F7F7] border-solid border-[1px] border-[#1D1D1D] text-[#1D1D1D] w-full'
              >
                {t('footer.contact_us')}
              </SecondaryButton>
            </LocalizedLink>
          )}
          <SecondaryButton
            onClick={onLogout}
            className='bg-[#F7F7F7] border-solid border-[1px] border-[#1D1D1D] text-[#1D1D1D]'
          >
            {t('mobile_nav.logout')}
          </SecondaryButton>
          <PrimaryButton
            onClick={onDashboard}
            className='bg-[#F7F7F7] border-solid border-[1px] border-[#1D1D1D] text-[#1D1D1D]'
          >
            {t('mobile_nav.dashboard')}
          </PrimaryButton>
        </>
      );
    }

    return (
      <>
        {isMainPage && (
          <LocalizedLink href={PAGE_LINKS.CONTACT_US}>
            <SecondaryButton
              onClick={onMobileContactUs}
              className='bg-[#F7F7F7] border-solid border-[1px] border-[#1D1D1D] text-[#1D1D1D] w-full'
            >
              {t('footer.contact_us')}
            </SecondaryButton>
          </LocalizedLink>
        )}
        <SecondaryButton
          onClick={onLogin}
          className='bg-[#F7F7F7] border-solid border-[1px] border-[#1D1D1D] text-[#1D1D1D]'
        >
          {t('mobile_nav.login')}
        </SecondaryButton>
      </>
    );
  }, [isAuthorized, onDashboard, onLogin, onLogout, t]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderList = React.useCallback((list: DropDownList, linkType?: string) => {
    return list.links.map((link, index) => {
      return (
        <IconButton
          icon={link.icon}
          key={index}
          classname={s.item}
          onClick={() => onClickHeaderFeature(link.from, link.to || InternalFileType.PDF, link.href.slice(1), linkType)}
        >
          <LocalizedLink href={link.href}>{link.label}</LocalizedLink>
        </IconButton>
      );
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const linksFrom = getLinksFrom(t);
  const linksTo = getLinksTo(t);
  const linksTools = getLinksTools(t);
  const linksMergeSplit = getLinksMergeSplit(t);
  const linksSecurity = getLinksSecurity(t);
  const linksForms = getLinksFormsShortList(t);

  return (
    <div className={s.mobileNav}>
      <div className={s.lists}>
        <div className={s.list}>
          <span>{linksTools.label}</span>
          {renderList(linksTools)}
        </div>
        {featureFlags.featureLandings && (
          <div className={s.list}>
            <span>{linksMergeSplit.label}</span>
            {renderList(linksMergeSplit)}
          </div>
        )}
        <div className={s.list}>
          <span>{linksFrom.label}</span>
          {renderList(linksFrom, 'Converter')}
        </div>
        <div className={s.list}>
          <span>{linksTo.label}</span>
          {renderList(linksTo, 'Converter')}
        </div>
        {featureFlags.featureLandings && (
          <div className={s.list}>
            <span>{linksSecurity.label}</span>
            {renderList(linksSecurity)}
          </div>
        )}
        {featureFlags.featureLandings && (
          <div className={s.list}>
            <span>{linksForms.label}</span>
            {renderList(linksForms)}
          </div>
        )}
      </div>

      <div className={s.buttons}>{renderActionButtons()}</div>
    </div>
  );
};
