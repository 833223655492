import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import classNames from 'classnames';
import useFileUploadAndConvert from 'hooks/fileFlows/useFileUploadAndConvert';
import { useIsRtl } from 'hooks/isRtl';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';

import { PrimaryButton } from 'components/buttons/primary-button';
import ModalLayout from 'components/modals/baseModal/base';

import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector } from 'data/selectors/modals';

import imgEpub from '../../buttons/icon-button-new-design/assets/pdf-epub.svg';
import radio_off from './assets/radio-off.svg';
import radio_on from './assets/radio-on.svg';
import s from './converter.module.scss';
import { formatsDictionary } from './formatsList';

const ChooseFormatAndConvertModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { service, file } = useSelector(modalOptionsSelector);
  const [searchParams] = useSearchParams();
  const convertTo = searchParams.get('to');
  const isRtl = useIsRtl();

  useCloseModalOnBack();

  const [filename, setFilename] = useState<string>('');
  const [fileFrom, setFileFrom] = useState<string>('PDF');
  const [fileTo, setFileTo] = useState<string>(convertTo ?? 'DOCX');
  const [formatsList, setFormatsList] = useState<{ format: string; label: string; to: string; icon?: any }[]>(
    formatsDictionary['.pdf']
  );
  const [currentFormat, setCurrentFormat] = useState<{
    format: string;
    label: string;
    to: string;
    icon?: any;
  }>(formatsList[0]);

  const { handleUploadFile } = useFileUploadAndConvert({
    service: { ...service, from: fileFrom, to: fileTo }
  });

  const dynamicFormatsList = useMemo(
    () => [
      ...formatsList,
      ...(convertTo === 'EPUB'
        ? [
            {
              label: 'EPUB',
              icon: imgEpub,
              to: 'EPUB',
              format: '.epub'
            }
          ]
        : [])
    ],
    [convertTo, formatsList]
  );

  const handleSetCurrentFormat = (format: { format: string; label: string; to: string; icon?: any }) => {
    setFileTo(format.to);
    setCurrentFormat(format);
  };

  useEffect(() => {
    if (!file) return;
    const extension: string = file?.name?.split('.').pop() || [];
    if (!extension) return;

    const newFormatsList = formatsDictionary[`.${extension}`] || formatsDictionary['default'];

    setFilename(file?.name?.replace(`.${extension}`, ''));
    setFileFrom(extension?.toUpperCase());
    setFormatsList(newFormatsList);
    setCurrentFormat(newFormatsList[0]);
    setFileTo(newFormatsList[0].to);
  }, [file]);

  const handleCancel = () => {
    dispatch(toggleModal({ visible: false }));
  };

  const handleDownload = () => {
    dispatch(toggleModal({ visible: false }));
    const newFile = new File([file], `${filename}.${fileFrom?.toLocaleLowerCase()}`, {
      type: file.type
    });
    handleUploadFile(newFile);
  };

  return (
    <ModalLayout
      canClose
      dataTestId='choose-format-modal'
    >
      <div
        className='flex flex-col items-center w-full mobile:min-w-[630px]
    mobile:max-w-[655px] pt-10 pb-8 mobile:pt-8 mobile:pb-7 tablet:pt-11 tablet:pb-[48px] px-4 tablet:px-[48px]'
      >
        <h2 className='mb-2 text-[#1D1D1D] font-[900] text-[22px] leading-[30px] tablet:text-[39px] tablet:leading-[54px]'>
          {t('editor_page.convert_banner.title_b')}
        </h2>
        <div className='text-base tablet:text-lg mb-5 tablet:mb-11 text-[#1D1D1D]'>
          {t('editor_page.convert_banner.description')}
        </div>
        <div
          className='w-full mobile:flex mobile:flex-wrap justify-center gap-4 mb-6 tablet:mb-[48px]
        grid grid-cols-2'
        >
          {dynamicFormatsList?.map((item, index) => (
            <div
              key={`modal-format-item-${index + 1}`}
              className={classNames(s.converter, {
                '!border-[2px] !border-solid !border-[#5F30E2] !bg-white flex flex-wrap':
                  currentFormat.format === item.format
              })}
              onClick={() => handleSetCurrentFormat(item)}
              role='button'
            >
              <div className='flex'>
                {currentFormat.format === item.format ? (
                  <img
                    src={radio_on}
                    alt='radio-off'
                    className={classNames('w-5 tablet:w-7 cursor-pointer', {
                      'ml-2': isRtl,
                      'mr-2': !isRtl
                    })}
                  />
                ) : (
                  <img
                    src={radio_off}
                    alt='radio-off'
                    className={classNames('w-5 tablet:w-7 cursor-pointer', {
                      'ml-2': isRtl,
                      'mr-2': !isRtl
                    })}
                  />
                )}
                <span>{item.label}</span>
              </div>
              {
                <img
                  src={item.icon}
                  alt='icon'
                  className={s.icons}
                />
              }
            </div>

            // <FormatItem
            //   key={`modal-format-item-${index + 1}`}
            //   current={currentFormat.format === item.format}
            //   onClick={() => handleSetCurrentFormat(item)}
            // >
            //   <FormatLabel>{item.label}</FormatLabel>
            //   <LazyLoadImage className="format-icon" src={item.icon} alt="format icon" />
            // </FormatItem>
          ))}
        </div>
        <div className='flex flex-col gap-y-4 mobile:flex-row justify-center items-center mobile:justify-around tablet:justify-between w-full'>
          <PrimaryButton
            onClick={handleCancel}
            className='bg-white  border border-solid border-[#1D1D1D] hover:bg-[#1D1D1D] text-[#1D1D1D] py-[19px] mobile:py-4
          hover:!text-[#FFFFFF] min-w-[145px] w-full mobile:w-[267px] mobile:!text-[20px] mobile:!leading-[30px] hidden tablet:block'
          >
            {t('editor_page.convert_banner.cancel')}
          </PrimaryButton>
          <PrimaryButton
            onClick={handleDownload}
            className='min-w-[145px] w-full tablet:ml-4 mobile:w-[267px] border border-solid border-[#D2294B] py-[19px] mobile:py-4
            hover:border-[#f4476ae0] bg-[#D2294B] hover:bg-[#f4476ae0] mobile:!text-[20px] mobile:!leading-[30px]'
          >
            {t('editor_page.convert_banner.convert')}
          </PrimaryButton>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ChooseFormatAndConvertModal;
