import type { FC } from 'react';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useLocaleNavigate } from 'hooks/useLocaleNavigate';

import { PrimaryButton } from 'components/buttons/primary-button';

import { Analytics } from 'services/analytics';

import { toggleModal } from 'data/actions/modals';

import { PAGE_LINKS } from 'ts/constants/page-links';

import icon from '../assets/icon.svg';

const FinalStep: FC = () => {
  const dispatch = useDispatch();
  const navigate = useLocaleNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    void Analytics.sendEvent({
      event: 'already_used_free_access_modal_view'
    });
  }, []);

  const handleContinue = () => {
    Analytics.sendEvent({ event: 'already_used_free_access_tap' });
    navigate(PAGE_LINKS.CHOOSING_PLAN);
    dispatch(toggleModal({ visible: false }));
  };

  return (
    <div
      className='bg-white rounded-2xl tablet:pt-[64px] tablet:pb-8 tablet:px-[74px] pt-[44px] pb-4 px-4
        max-w-[657px] w-full relative text-[#575757] flex flex-col justify-center items-center text-center'
    >
      <img
        src={icon}
        alt='icon'
      />
      <div className='mt-6 text-[#1D1D1D] text-[20px] font-bold max-w-[392px]'>
        {t('popups.special_offer_already_used.description')}
      </div>
      <PrimaryButton
        onClick={handleContinue}
        className='w-full mt-8 rounded-xl py-4'
      >
        {t('popups.special_offer_already_used.button')}
      </PrimaryButton>
    </div>
  );
};

export default FinalStep;
