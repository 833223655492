import imgExel from 'assets/img/icons/20px-new-design/Exel.svg';
import imgImage from 'assets/img/icons/20px-new-design/IMG.svg';
import imgPdf from 'assets/img/icons/20px-new-design/PDF.svg';
import imgPng from 'assets/img/icons/20px-new-design/PNG.svg';
import imgPtt from 'assets/img/icons/20px-new-design/PTT.svg';
import imgWord from 'assets/img/icons/20px-new-design/Word.svg';

export const formatsList = [
  {
    label: 'PDF',
    icon: imgPdf,
    to: 'PDF',
    format: '.pdf'
  },
  {
    label: 'PNG',
    icon: imgPng,
    to: 'PNG',
    format: '.png'
  },
  {
    label: 'Word',
    to: 'DOCX',
    icon: imgWord,
    format: '.docx'
  },
  {
    label: 'Excel',
    icon: imgExel,
    to: 'XLS',
    format: '.xls'
  },
  {
    label: 'JPG',
    to: 'JPG',
    icon: imgImage,
    format: '.jpg'
  },
  {
    label: 'PPTX',
    to: 'PPTX',
    icon: imgPtt,
    format: '.pptx'
  }
];
