import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { useIsRtl } from 'hooks/isRtl';

import { PrimaryButton } from 'components/buttons/primary-button';
import { SecondaryButton } from 'components/buttons/secondary-button';
import LocalizedLink from 'components/localized-link';
import { NavToggler } from 'components/nav-toggler';

import { Analytics } from 'services/analytics';

import { PAGE_LINKS } from 'ts/constants/page-links';

import { LogoNewDesign } from '../../components/logo-new-design';
import { MobileNav } from '../../components/mobile-nav';
import { HeaderNav } from './components/nav';
import s from './header-for-upload-section.module.scss';
import type { IHeaderUploadSectionInteractor } from './interactor';

export interface IProps {
  interactor: IHeaderUploadSectionInteractor;
  withContactUsLink?: boolean;
}
export const HeaderUploadSectionRouter: React.FC<IProps> = ({ interactor }) => {
  const { t } = useTranslation();
  const renderActionButtons = useCallback(() => {
    if (interactor.isAuthorized) {
      return (
        <>
          <PrimaryButton
            onClick={interactor.onDashboard}
            className={classNames(
              s.dashboardButton,
              { 'bg-[#F6F6F6]': interactor.convertDropDown.toggle },
              { '!bg-[#FFFFFF]': interactor.convertDropDown.isOpen }
            )}
          >
            <div>{t('header.dashboard')}</div>
          </PrimaryButton>
          <SecondaryButton
            onClick={interactor.onLogout}
            className={classNames(
              s.logoutButton,
              { 'bg-[#F6F6F6]': interactor.convertDropDown.toggle },
              { '!bg-[#FFFFFF]': interactor.convertDropDown.isOpen }
            )}
          >
            {t('header.logout')}
          </SecondaryButton>
        </>
      );
    }

    return (
      <SecondaryButton
        onClick={interactor.onLogin}
        className={classNames(
          s.loginButton,
          { 'bg-[#F6F6F6]': interactor.convertDropDown.toggle },
          { '!bg-[#FFFFFF]': interactor.convertDropDown.isOpen }
        )}
      >
        <LocalizedLink href={PAGE_LINKS.LOGIN}>{t('header.login')}</LocalizedLink>
      </SecondaryButton>
    );
  }, [interactor.isAuthorized, interactor.onDashboard, interactor.onLogin, interactor.onLogout, t]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderMobileNav = () => {
    if (interactor.mobileNav.isVisible) {
      return (
        <MobileNav
          isAuthorized={interactor.isAuthorized}
          isContactUsShown={interactor.isMainPage}
          onLogin={interactor.onMobileLogin}
          onDashboard={interactor.onMobileDashboard}
          onLogout={interactor.onLogout}
          onMobileContactUs={interactor.onMobileContactUs}
          isMainPage={interactor.isMainPage}
        />
      );
    }

    return null;
  };

  return (
    <div
      className={classNames(
        'mobile:px-[46px] mx-auto w-full py-[19px] bg-[#F6F6F6] rounded-[44px_44px_0_0] hidden mobile:block',
        {
          'bg-[#FFFFFF] fixed z-[1000] top-0 left-0 py-0 pt-[43px]': interactor.convertDropDown.isOpen
        }
      )}
    >
      <div className='container'>
        <div className='flex justify-between w-full'>
          <div className='flex items-center'>
            <div
              className={classNames(s.logoWrapper, { [s.logoWrapperRtl]: useIsRtl() })}
              onClick={interactor.clickOnLogo}
              role='button'
            >
              <LocalizedLink href={PAGE_LINKS.MAIN}>
                <LogoNewDesign />
              </LocalizedLink>
            </div>
            <div className={s.desktopNav}>
              <HeaderNav interactor={interactor} />
            </div>
            <LocalizedLink
              href={PAGE_LINKS.CONTACT_US}
              onClick={() => Analytics.sendEvent({ event: 'ppc_landing_native_support' })}
            >
              <div className='text-[18px] font-[700]'>{t('footer.contact_us')}</div>
            </LocalizedLink>
            <NavToggler
              isOpen={interactor.mobileNav.isVisible}
              toggle={interactor.mobileNav.toggle}
            />
            {renderMobileNav()}
          </div>
          <div className={s.desktopNav}>{renderActionButtons()}</div>
        </div>
      </div>
    </div>
  );
};
