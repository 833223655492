import type { Reducer } from 'redux';
import { combineReducers } from 'redux';

import type { IReduxAction } from 'ts/interfaces/redux/redux.action';

import abTests from './abTests';
import aiSummarizer from './aiSummarizer';
import credits from './credits';
import documents from './documents';
import loading from './loading';
import modals from './modals';
import subscriptions from './subscriptions';
import user from './user';

const combinedReducer = combineReducers({
  loading,
  user,
  credits,
  modals,
  subscriptions,
  documents,
  abTests,
  aiSummarizer
});

const rootReducer: Reducer = (state: any, action: IReduxAction) => {
  return combinedReducer(state, action);
};

export default rootReducer;
export type RootState = ReturnType<typeof combinedReducer>;
