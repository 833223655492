import type { ChangeEvent, FC, KeyboardEvent } from 'react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';

import { PrimaryButton } from 'components/buttons/primary-button';
import { SecondaryButton } from 'components/buttons/secondary-button';
import ModalLayout from 'components/modals/baseModal/base';

import { Analytics } from 'services/analytics';

import { getDocuments, updateFileName } from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector } from 'data/selectors/modals';

import { sliceFileName } from 'utils/sliceFileName';

const RenameFileModal: FC = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { file } = useSelector(modalOptionsSelector);

  const splittedFileName = file.filename?.split('.') || [];
  const [userFileName, setUserFileName] = useState(
    splittedFileName.length > 2 ? splittedFileName.slice(0, splittedFileName.length - 1).join('.') : splittedFileName[0]
  );

  useEffect(() => {
    Analytics.sendEvent({ event: 'rename_file_modal_view' });
  }, []);

  const handleRenameFile = () => {
    dispatch(
      updateFileName(file.id, userFileName, undefined, () => {
        dispatch(getDocuments());
      })
    );
    dispatch(toggleModal({ visible: false }));
    Analytics.sendEvent({ event: 'rename_file_confirm_tap' });
  };

  const handleFileNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserFileName(event.target.value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleRenameFile();
    }
  };

  const handleClose = () => {
    dispatch(toggleModal({ visible: false }));
    Analytics.sendEvent({ event: 'rename_file_cancel_tap' });
  };

  return (
    <ModalLayout canClose>
      <div className='p-4'>
        <h2 className='text-[25px] text-center font-extrabold leading-[32.5px] mt-6 tablet:mt-6'>
          {t('popups.rename_file_popup.title', { filename: sliceFileName(file.filename || '') })}
        </h2>
        <div className='flex flex-col w-full mt-6 tablet:mt-8 tablet:px-6'>
          <label
            htmlFor='fileName'
            className='right-0'
          >
            {t('editor_page.convert_banner.file_name_placeholder')}
          </label>
          <input
            type='text'
            id='fileName'
            name='fileName'
            placeholder={t('editor_page.convert_banner.file_name_placeholder')}
            value={userFileName}
            onChange={handleFileNameChange}
            onKeyDown={handleKeyDown}
            className='rounded-md border border-[#C7C7C7] py-3 px-4 mt-2 w-[420px]'
          />
        </div>
        <div className='flex mt-6 tablet:mt-8 items-center justify-center tablet:mb-4 w-full'>
          <SecondaryButton
            onClick={handleClose}
            className={classNames(
              'bg-white border-2 border-[#1D1D1D] hover:bg-white text-[#1D1D1D] hover:text-[#1D1D1D] min-w-[200px] h-[57px] mr-6 hidden tablet:block',
              {
                'ml-6': i18n?.dir() === 'rtl',
                'mr-6': i18n?.dir() !== 'rtl'
              }
            )}
          >
            {t('popups.rename_file_popup.cancel')}
          </SecondaryButton>
          <PrimaryButton
            onClick={handleRenameFile}
            className='min-w-full tablet:min-w-[200px] bg-[#D2294B] h-[57px] hover:bg-[#f4476ae0] text-[20px] mobile:!leading-[30px] py-5 mobile:py-[17px]'
          >
            {t('popups.rename_file_popup.confirm')}
          </PrimaryButton>
        </div>
      </div>
    </ModalLayout>
  );
};

export default RenameFileModal;
