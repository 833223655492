export enum FileType {
  PDF = 'PDF',
  JPEG = 'JPEG',
  JPG = 'JPG',
  PNG = 'PNG',
  PPT = 'PPT',
  PPTX = 'PPTX',
  RTF = 'RTF',
  XLS = 'XLS',
  XLSX = 'XLSX',
  DOC = 'DOC',
  DOCX = 'DOCX',
  TXT = 'TXT'
}
