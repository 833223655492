export const openPrintModalFromURL = async (response: { url?: string; aws_url?: string }) => {
  console.log('openPrintModalFromURL', response);
  try {
    console.log('response', response.url);
    const urlToFetch = response.url ? response.url : response.aws_url;

    if (!urlToFetch) {
      throw new Error('No URL to fetch');
    }

    const fileResponse = await fetch(urlToFetch);

    if (!fileResponse.ok) {
      throw new Error(`Failed to fetch file: ${fileResponse.statusText}`);
    }

    const blob = await fileResponse.blob();

    const url = URL.createObjectURL(blob);

    const iframe = document.createElement('iframe');
    iframe.style.visibility = 'hidden';
    iframe.src = url;

    document.body.appendChild(iframe);

    iframe.onload = function () {
      try {
        iframe.contentWindow?.focus();
        iframe.contentWindow?.print();

        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error during printing:', error);
      }
    };
  } catch (error) {
    console.error('Error fetching or displaying PDF:', error);
  }
};
