import { useDispatch, useSelector } from 'react-redux';

import axios, { HttpStatusCode } from 'axios';
// Helpers
import { getFileKeyFromAWSLink } from 'helpers/getFileKeyFromAWSLink';
// Hooks
import { useLocaleNavigate } from 'hooks/useLocaleNavigate';

// Services
import { Analytics } from 'services/analytics';

// Actions
import {
  convertMultipleDocuments,
  downloadDocument,
  getUploadLink,
  setConvertDocumentData,
  updateDownloadProgress
} from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
// Selectors
import {
  isUserAuthenticated,
  userDataSelector,
  userEmailSelector,
  userSubscriptionSelector
} from 'data/selectors/user';

// Constants
import { PAGE_LINKS } from 'ts/constants/page-links';
import { EModalTypes } from 'ts/enums/modal.types';
import { UserStatus } from 'ts/enums/user.status';
import type { IService } from 'ts/interfaces/services/service';

const useFileUploadAndConvertMultiple = ({ service }: { service?: IService }) => {
  const navigate = useLocaleNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector(isUserAuthenticated);
  const userSubscription = useSelector(userSubscriptionSelector);
  const email = useSelector(userEmailSelector);
  const user = useSelector(userDataSelector);

  const handleConvertDocument = (dataToConvert: any, signUp?: boolean, subscription?: any) => {
    // TODO: check this
    if (userSubscription || subscription?.id) {
      return;
    }

    const onFail = (error: any) => {
      if (error?.response?.data?.message === 'error.convert.unsupported-formats') {
        dispatch(toggleModal({ visible: true, type: EModalTypes.FILE_UPLOAD_ERROR }));
        dispatch(updateDownloadProgress(0));
        return;
      }

      if (error?.response?.status === HttpStatusCode.PaymentRequired) {
        dispatch(toggleModal({ visible: false }));
        dispatch(updateDownloadProgress(0));
        navigate(PAGE_LINKS.CHOOSING_PLAN);
        window.scrollTo(0, 0); // Scrolls to the top of the page

        if (error?.response?.data?.fileId) {
          localStorage.setItem('fileId', error.response.data.fileId);
        }
        return;
      }

      dispatch(toggleModal({ visible: true, type: EModalTypes.FILE_UPLOAD_ERROR }));
      dispatch(updateDownloadProgress(0));
    };

    if (!userSubscription && !subscription?.id && user?.status !== UserStatus.TEMPORARY) {
      dispatch(updateDownloadProgress(100));

      const onSuccessWithoutSubscription = () => {
        dispatch(toggleModal({ visible: false }));
        navigate(PAGE_LINKS.CHOOSING_PLAN);
        window.scrollTo(0, 0); // Scrolls to the top of the pag
      };

      return setTimeout(
        () => {
          dispatch(convertMultipleDocuments(dataToConvert, onSuccessWithoutSubscription, onFail));
        },
        signUp ? 0 : 2000
      );
    }

    const onSuccess = (res: any) => {
      if (!res?.fileId) {
        return;
      }

      dispatch(updateDownloadProgress(100));

      if (!userSubscription) {
        dispatch(toggleModal({ visible: false }));
        return;
      }

      let countRetries = 0;
      const maxRetries = 45;
      const retryInterval = 1500;

      const pollForDownload = () => {
        if (countRetries >= maxRetries) {
          dispatch(
            toggleModal({
              type: EModalTypes.FILE_UPLOAD_ERROR,
              visible: true
            })
          );
          return;
        }

        countRetries++;

        dispatch(
          downloadDocument(
            res?.fileId,
            async (downloadRes: any) => {
              if (downloadRes?.url && !downloadRes.url.includes('rawd')) {
                try {
                  window.location.href = downloadRes.url;

                  dispatch(updateDownloadProgress(100));

                  setTimeout(() => {
                    dispatch(toggleModal({ visible: false }));
                  }, 500);
                } catch {
                  dispatch(
                    toggleModal({
                      type: EModalTypes.FILE_UPLOAD_ERROR,
                      visible: true
                    })
                  );
                }
              } else {
                setTimeout(pollForDownload, retryInterval);
              }
            },
            (error: any) => {
              if (error.response?.status === HttpStatusCode.NotFound) {
                setTimeout(pollForDownload, retryInterval);
              } else {
                dispatch(
                  toggleModal({
                    visible: true
                  })
                );
              }
            }
          )
        );
      };

      // Start polling
      pollForDownload();
    };

    dispatch(convertMultipleDocuments(dataToConvert, onSuccess, onFail));
  };

  const handleConvertFile = (dataToConvert: any) => {
    Analytics.sendEvent({
      event: 'converting_with_progress_view'
    });
    if (!isAuth) {
      setTimeout(() => {
        dispatch(updateDownloadProgress(100));
      }, 500);
      return setTimeout(() => {
        dispatch(
          toggleModal({
            type: EModalTypes.ENTER_EMAIL_ADDRESS,
            visible: true,
            options: {
              handleConvertDocument: (subscription: any) => handleConvertDocument(dataToConvert, true, subscription),
              signUp: true,
              servicePath: service?.path
            }
          })
        );
      }, 1000);
    }
    handleConvertDocument(dataToConvert);
  };

  const handleUploadFileByLinkToS3 = async (files: File[], uploadLinks: any, actionType: string) => {
    Analytics.sendEvent({
      event: 'upload_link_received'
    });
    try {
      const formattedFiles: any[] = [];
      const promises = files?.map((file: any, index: number) => {
        return (async () => {
          const res = await axios.put(uploadLinks[index]?.url as string, file);
          formattedFiles.push({
            size: file.size,
            filename: file.name,
            key: getFileKeyFromAWSLink(res?.request?.responseURL),
            pagesCount: file.pagesCount || 1,
            orderIndex: index
          });

          return true;
        })();
      });

      dispatch(updateDownloadProgress(0));
      dispatch(
        toggleModal({
          type: EModalTypes.PROGRESS_EDIT_FILE,
          visible: true
        })
      );
      await Promise.allSettled(promises);

      const dataToConvert: any = {
        files: formattedFiles,
        to: service?.to || 'PDF',
        result_filename: 'result',
        post_process_action: actionType,
        serviceType: service?.serviceType
      };
      setTimeout(() => {
        dispatch(setConvertDocumentData(dataToConvert));

        // set converted data to localStorage and use this data for google auth
        localStorage.setItem(
          'dataToConvert',
          JSON.stringify({
            file: dataToConvert,
            service: service?.path,
            email: email,
            serviceType: service?.serviceType
          })
        );

        handleConvertFile(dataToConvert);
      }, 3000);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // upload to s3 bucket and convert file
  const handleUploadFile = (files: File[], actionType: string) => {
    if (!files) return;

    console.log(actionType, 'actionType');

    void Analytics.sendEvent({
      event: 'file_from_provider_chosen',
      data: {
        features_name: service?.path?.replace('/', '') || '',
        method: 'click'
      }
    });

    const fileNames = files.map((file) => file.name);
    console.log('fileNames', fileNames);
    dispatch(
      getUploadLink({
        filename: fileNames,
        onSuccess: (res: any) => {
          handleUploadFileByLinkToS3(files, res, actionType);
        },
        service
      })
    );
  };

  return {
    handleUploadFile,
    handleConvertDocument
  };
};

export default useFileUploadAndConvertMultiple;
