import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import type { SupportedOcrExportFormat } from 'ts/interfaces/documents/document';
import { OcrExportFormatEnum } from 'ts/interfaces/documents/document';

import wordIcon from '../assets/docx.svg';
import pdfIcon from '../assets/pdf.svg';
import radio_off from '../assets/radio-off.svg';
import radio_on from '../assets/radio-on.svg';
import txtIcon from '../assets/txt.svg';
import s from './converter.module.scss';

export interface IConverter {
  to: SupportedOcrExportFormat;
  icon: string;
  converterName: string;
  title: string;
  description: string;
}

export type IConverterFromEditor = Omit<IConverter, 'from'>;

export const FormatsList = ({
  currentFormat,
  setCurrentFormat
}: {
  currentFormat: SupportedOcrExportFormat;
  setCurrentFormat: (format: SupportedOcrExportFormat) => void;
}) => {
  const { t } = useTranslation();
  const converters: IConverter[] = [
    {
      to: OcrExportFormatEnum.docx,
      converterName: 'pdf_to_doc',
      title: t('popups.converter_ocr_popup.word.title'),
      description: t('popups.converter_ocr_popup.word.description'),
      icon: wordIcon
    },
    {
      to: OcrExportFormatEnum.pdfSearchable,
      converterName: 'pdf_to_searchable',
      title: t('popups.converter_ocr_popup.pdf.title'),
      description: t('popups.converter_ocr_popup.pdf.description'),
      icon: pdfIcon
    },
    {
      to: OcrExportFormatEnum.txt,
      converterName: 'pdf_to_txt',
      title: t('popups.converter_ocr_popup.txt.title'),
      description: t('popups.converter_ocr_popup.txt.description'),
      icon: txtIcon
    }
  ];

  return (
    <>
      {converters.map((item: IConverter, i: number) => {
        return (
          <div
            className={classNames(s.converter, {
              '!border-[2px] !border-solid !border-[#5F30E2] !bg-white flex flex-wrap': currentFormat === item.to
            })}
            onClick={() => setCurrentFormat(item.to)}
            key={`modal-format-item-${i + 1}`}
            data-testid={`modal-format-item-${i + 1}`}
            role='button'
          >
            <div className='flex'>
              {currentFormat === item.to ? (
                <img
                  src={radio_on}
                  alt='radio-off'
                  className='w-5 tablet:w-7 mr-2 tablet:mr-4 cursor-pointer'
                />
              ) : (
                <img
                  src={radio_off}
                  alt='radio-off'
                  className='w-5 tablet:w-7 mr-2 tablet:mr-4 cursor-pointer'
                />
              )}
              <div>
                <div className='font-bold text-xl'>{item.title}</div>
                <div className='text-base'>{item.description}</div>
              </div>
            </div>
            {
              <img
                src={item.icon}
                alt='icon'
                className={s.icons}
              />
            }
          </div>
        );
      })}
    </>
  );
};
