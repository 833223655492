import type { FC } from 'react';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { PrimaryButton } from 'components/buttons/primary-button';
import ModalLayout from 'components/modals/baseModal/base';

import { Analytics } from 'services/analytics';

import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector } from 'data/selectors/modals';

import cross from './assets/cross.svg';
import { renderIconError, renderTextError, renderTitleError } from './getContentFunctions';

const PaymentErrorModal: FC = () => {
  const dispatch = useDispatch();
  const options = useSelector(modalOptionsSelector);
  const { t } = useTranslation();

  useEffect(() => {
    Analytics.sendEvent({
      event: 'payment_declined_modal_view',
      data: {
        error_code: options?.errorCode || '',
        error_message: options?.errorMessage || ''
      }
    });
  }, []); // eslint-disable-line

  const handleTryAgain = () => {
    Analytics.sendEvent({
      event: 'payment_declined_confirm_tap'
    });
    if (typeof options?.handleTryAgain === 'function') options?.handleTryAgain();
    window.scrollTo(0, 0);
    dispatch(toggleModal({ visible: false }));
  };

  return (
    <ModalLayout canClose>
      <div className='w-full h-full bg-[rgba(6,6,6,0.3)] absolute top-0 left-0 z-[191919]'>
        <div
          className='fixed translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%]
        flex flex-col justify-center items-center p-4 tablet:p-0 tablet:px-8 tablet:pt-8
        tablet:pb-10 bg-[#FFFFFF] w-[calc(100%-32px)] mobile:w-[430px] rounded-2xl'
        >
          <button
            onClick={handleTryAgain}
            className='w-full flex justify-end items-center mb-1'
          >
            <img
              className='cursor-pointer'
              src={cross}
              alt='cross'
            />
          </button>
          {renderIconError(options?.errorCode)}
          <h3
            className='font-[700] text-[20px] leading-[30px] tablet:text-[24px]
            tablet:leading-[34px] text-[#373737] text-center tablet:mt-8 mt-6'
            data-testid='payment-fail-headline'
          >
            {renderTitleError(options?.errorCode, t)}
          </h3>
          <p
            className='text-[16px] leading-[24px] tablet:text-[18px]
            tablet:leading-[24px] text-[#575757] text-center mt-1'
          >
            {renderTextError(options?.errorCode, t)}
          </p>
          <PrimaryButton
            className='w-full tablet:mt-10 mt-8'
            onClick={handleTryAgain}
          >
            {t('card_error_popup.button_try_again')}
          </PrimaryButton>
        </div>
      </div>
    </ModalLayout>
  );
};

export default PaymentErrorModal;
