import type { FC } from 'react';

export const Description: FC = () => {
  return (
    <div className='flex flex-col gap-y-4'>
      <p>
        The I-9 form, also known as the Employment Eligibility Verification Form, is used by employers to verify an
        employee's identity and work authorization status for employment in the United States. The form was developed by
        the US Citizenship and Immigration Services (USCIS).
      </p>
      <p>
        The purpose of the I-9 employment verification form is to ensure that employers hire only individuals who are
        legally authorized to work in the country. They are required to complete this form for every employee hired
        after November 6, 1986, regardless of their citizenship status.
      </p>
      <p>
        The form I-9, fillable by two parties, contains sections where employees must provide their personal
        information. You can use{' '}
        <a
          href='/'
          className='underline'
        >
          PDF Guru
        </a>{' '}
        to add all these details with ease. We've created a readily editable I-9 form for your convenience.
      </p>
      <p>
        The employee must then present the form along with valid papers proving their identity and eligibility to work.
        This can be a passport, driver's license, or Social Security card — more on this later. The employer is
        responsible for reviewing these docs and determining their authenticity.
      </p>
    </div>
  );
};
