import React from 'react';

import s from './fullwidth-dropdown.module.scss';

interface IProps {
  isVisible: boolean;
  children: React.ReactNode;
  onClickOutside: () => void;
  triggerRef: React.RefObject<HTMLDivElement>;
}
export const FullwidthDropdown: React.FC<IProps> = ({ isVisible, onClickOutside, triggerRef, children }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      const el = ref?.current;
      const target = event?.target as Node;
      if (!el || el.contains(target)) {
        return;
      }

      if (event.target && triggerRef.current && triggerRef.current.contains(target)) {
        return;
      }

      onClickOutside();
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [onClickOutside, triggerRef]);

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={s.fullWidthDropdown}
      ref={ref}
    >
      {children}
    </div>
  );
};
