import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import useKeyPress from 'hooks/useKeyPress';

import { PrimaryButton } from 'components/buttons/primary-button';
import { EmailInput } from 'components/inputs/email';

import { Analytics } from 'services/analytics';

import { toggleModal } from 'data/actions/modals';
import { sendForgotPassword } from 'data/actions/user';

import { EModalTypes } from 'ts/enums/modal.types';

import ModalLayout from '../baseModal/base';
import s from './restore-password-popup.module.scss';

export interface EmailInputResult {
  email: string;
  valid: boolean;
}

const ForgotPasswordModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [errors, setErrors] = useState({ email: '' });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  useEffect(() => {
    Analytics.sendEvent({ event: 'recover_pass_modal_view' });
  }, []);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrors((prev) => ({
        ...prev,
        email: t('global.please_enter_valid_email')
      }));
      return false;
    }
    setErrors((prev) => ({ ...prev, email: '' }));
    return true;
  };
  const onFailed = () => {
    // do nothing
  };
  const onSuccess = () => {
    dispatch(
      toggleModal({
        visible: true,
        type: EModalTypes.CHECK_YOUR_EMAIL,
        options: { email }
      })
    );
  };
  const handleResetPassword = () => {
    const isValidEmail = validateEmail(email);
    if (!isValidEmail) return;
    Analytics.sendEvent({ event: 'recover_pass_confirm_tap' });
    dispatch(sendForgotPassword(email, onSuccess, onFailed));
    dispatch(toggleModal({ visible: false }));
  };

  useKeyPress({ targetKey: 'Enter', onKeyPressDown: handleResetPassword });

  useEffect(() => {
    setIsButtonDisabled(!!errors?.email?.length || !email?.length);
  }, [errors, email]);

  return (
    <ModalLayout canClose>
      <div className={s.restorePasswordPopup}>
        <h2>{t('popups.restore_password_popup.headline')}</h2>
        <div className={s.form}>
          <div className={s.inputWrapper}>
            <EmailInput onInput={(result: EmailInputResult) => setEmail(result.email)} />
          </div>
          <PrimaryButton
            onClick={handleResetPassword}
            className={s.actionButton}
            disabled={isButtonDisabled}
          >
            {t('popups.restore_password_popup.restore_password')}
          </PrimaryButton>
        </div>
      </div>
    </ModalLayout>
  );
};
export default ForgotPasswordModal;
