/**
 * @description parsed query object of key-value pairs
 */
export type ParsedUrlQuery = Record<string, string>;

/**
 * @description parse any url to query object.
 * @example
 * `https://any-url.com?utm_source=source&utm_campaign=campaign` ->
 *  ```{
 *    utm_source: 'source',
 *    utm_campaign: 'campaign'
 *  }```.
 */
export function parseQuery(urlPath: string): ParsedUrlQuery {
  try {
    const queryParams = new URLSearchParams(urlPath.split(/\?/)[1]);

    // Convert the query parameters to an object
    const queryObject: Record<string, string> = {};
    queryParams.forEach((value, key) => {
      queryObject[key] = value;
    });

    return queryObject;
  } catch (err) {
    console.error('parse query error', err);
    return {};
  }
}
