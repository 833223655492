import type { AnyAction } from 'redux';

import { EReduxTypes } from 'ts/enums/redux.types';
import type { IToggleModal } from 'ts/interfaces/modal/modal';
import type { IReduxAction } from 'ts/interfaces/redux/redux.action';

export const toggleModal = (data: IToggleModal): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.TOGGLE_MODAL,
    data
  };
  return action;
};
