import { useCallback, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import buildOauthRequestUrl from 'helpers/buildOauthRequestUrl';
import i18n from 'i18n';

import { PrimaryButton } from 'components/buttons/primary-button';
import { EmailInput } from 'components/inputs/email-registration-modal';
import { LoadingAnimation } from 'components/loading-animation';
import LocalizedLink from 'components/localized-link';
import ModalLayout from 'components/modals/baseModal/base';

import { Analytics } from 'services/analytics';

import { sendAnalyticEvent } from 'data/actions/analytics';
import { toggleModal } from 'data/actions/modals';
import { getUser, signInWithGoogle } from 'data/actions/user';
import { modalOptionsSelector } from 'data/selectors/modals';
import { isUSAUserSelector } from 'data/selectors/user';

import { getPrevPage } from 'utils/prevPath';
import showToast from 'utils/toast';

import { IS_AUTO_TEST_LOCAL_STORAGE_KEY } from 'ts/constants/general';
import { PAGE_LINKS } from 'ts/constants/page-links';

import googleIcon from './assets/google_icon.svg';
import trustpilot from './assets/image_variant_c.svg';
import list_item_1 from './assets/list_item_1.svg';
import list_item_2 from './assets/list_item_2.svg';
import list_item_3 from './assets/list_item_3.svg';
import list_item_4 from './assets/list_item_4.svg';
import list_item_5 from './assets/list_item_5.svg';

const EmailModalC = ({
  onClosePopup,
  setEmail,
  email,
  errors,
  isLoading,
  handleDownLoadFile
}: {
  onClosePopup: () => void;
  setEmail: (email: string) => void;
  email: string;
  errors: any;
  isLoading: boolean;
  handleDownLoadFile: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isUSAUser = useSelector(isUSAUserSelector);
  const displayNotice = useMemo(() => {
    const isAutoTest = !!localStorage.getItem(IS_AUTO_TEST_LOCAL_STORAGE_KEY);
    return isUSAUser && !isAutoTest;
  }, [isUSAUser]);

  const { handleConvertDocument, keepOpenedOnSuccess, handleTranslateDocument, handleCustomNavigate } =
    useSelector(modalOptionsSelector);

  useEffect(() => {
    if (window.Trustpilot) {
      const widgets = document.querySelectorAll('.trustpilot-widget');
      widgets.forEach((widget) => {
        window.Trustpilot.loadFromElement(widget);
      });
    }
  }, []);

  const onFailSignIn = () => {
    Analytics.sendEvent({
      event: 'registration_status',
      data: {
        placement: 'email_modal',
        funnel: getPrevPage() || '',
        email: '',
        status: 'fail',
        type: 'auth_google'
      }
    });
    showToast('error', 'error', 5000, 'error');
  };

  const onSuccessSignIn = useCallback(async () => {
    const handleNavigate = (route: string) => {
      dispatch(toggleModal({ visible: false }));
      navigate(route);
      window.scrollTo(0, 0);
    };
    window.onbeforeunload = null;

    Analytics.sendEvent({
      event: 'registration_status',
      data: {
        placement: 'email_modal',
        funnel: getPrevPage() || '',
        email: '',
        status: 'success',
        type: 'auth_google'
      }
    });

    dispatch(
      getUser({
        onFailed: () => {
          // do nothing
        }
      })
    ).then(() => {
      if (typeof handleConvertDocument === 'function') {
        handleConvertDocument();
        if (!keepOpenedOnSuccess) {
          dispatch(toggleModal({ visible: false }));
        }
        return;
      }
      if (typeof handleTranslateDocument === 'function') {
        handleTranslateDocument();
        if (!keepOpenedOnSuccess) {
          dispatch(toggleModal({ visible: false }));
        }
        return;
      }
      if (typeof handleCustomNavigate === 'function') {
        handleCustomNavigate();
        if (!keepOpenedOnSuccess) {
          dispatch(toggleModal({ visible: false }));
        }
        return;
      }
      handleNavigate(PAGE_LINKS.DASHBOARD);
      if (!keepOpenedOnSuccess) {
        dispatch(toggleModal({ visible: false }));
      }
    });
  }, [dispatch, navigate, handleConvertDocument, handleTranslateDocument, handleCustomNavigate, keepOpenedOnSuccess]);

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: '504300416078-mt3oo39fdrg5ov14mjfd38mmefjrtft1.apps.googleusercontent.com',
        callback: (response: any, ...other: any[]) => {
          // TODO: remove this console.log
          console.log('response', response, other);
          dispatch(signInWithGoogle(onSuccessSignIn, onFailSignIn, response.credential));
        },
        native_callback: (response: any, ...other: any[]) => {
          // TODO: remove this console.log
          console.log('response', response, other);
        },
        auto_select: false,
        cancel_on_tap_outside: false
      });
      window.google.accounts.id.prompt(() => {
        // do nothing
      });
    }
  }, [dispatch, onSuccessSignIn]); // one time

  const rightBlockItemsList = [
    {
      title: t('popups.new_enter_email_popup_test.bullet_1'),
      icons: list_item_1
    },
    {
      title: t('popups.new_enter_email_popup_test.bullet_2'),
      icons: list_item_2
    },
    {
      title: t('popups.new_enter_email_popup_test.bullet_3'),
      icons: list_item_3
    },
    {
      title: t('popups.new_enter_email_popup_test.bullet_4'),
      icons: list_item_4
    },
    {
      title: t('popups.new_enter_email_popup_test.bullet_5'),
      icons: list_item_5
    }
  ];

  const connectGmailAccount = () => {
    window.onbeforeunload = null;
    const url = buildOauthRequestUrl();
    window.location.href = url;
    dispatch(sendAnalyticEvent({ event: 'continue_google_tap' }));
  };

  const closeGoogleOneTap = useCallback(() => {
    if (window.google?.accounts?.id) {
      window.google.accounts.id.cancel();
    }
  }, []);

  return (
    <ModalLayout
      canClose
      closePopup={() => {
        onClosePopup();
        closeGoogleOneTap();
      }}
      dataTestId='enter-email-modal'
    >
      <div className='flex flex-row'>
        <div id='google-one-tap'></div>
        <div className='px-4 pt-10 pb-4 tablet:px-8 tablet:pt-12 max-w-[453px]'>
          <h2 className='text-[25px] leading-[32px] text-center font-[700] text-[#1D1D1D]'>
            {t('popups.new_enter_email_popup_test.b_variant_title')}
            {i18n.language === 'ja' && (
              <div className='mb-4 text-[10px] text-center'>
                {t('popups.new_enter_email_popup_test.already_have_footer')}
              </div>
            )}
          </h2>

          <button
            onClick={connectGmailAccount}
            className='w-full flex items-center justify-center rounded-[10px] border border-[#DEDEDE] mt-4 mb-3 tablet:mt-6 tablet:mb-4'
          >
            <img
              src={googleIcon}
              alt='Google icon'
            />
            <span className='text-[18px] font-[700] text-[#575757]'>
              {t('popups.new_enter_email_popup_test.google_login_button')}
            </span>
          </button>

          <div className='mb-4 tablet:mb-6'>
            <div className='flex justify-center items-center'>
              <div className='w-full h-[1px] bg-[#E0E0E0]' />
              <p className='text-[16px] font-[700] px-3 leading-[21.6px] text-[#A5A5A5] text-center'>
                {t('global.login_method_separator')}
              </p>
              <div className='w-full h-[1px] bg-[#E0E0E0]' />
            </div>
          </div>
          <EmailInput
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            value={email}
            error={errors?.email}
            onDomainClick={(email: string) => setEmail(email)}
          />
          <PrimaryButton
            onClick={() => {
              handleDownLoadFile();
              closeGoogleOneTap();
            }}
            className='!bg-[#5F30E2] mt-3 tablet:mt-4 px-6 py-4 text-[18px] font-[700] text-[#FFF] w-full'
            disabled={isLoading}
            data-testid='enter-email-modal-submit'
          >
            {isLoading ? (
              <LoadingAnimation
                currentState='loading'
                className='!h-[22px]'
              />
            ) : (
              t('popups.enter_email_popup.download')
            )}
          </PrimaryButton>
          <p
            className='mt-2 text-[12px] leading-[16.2px]
          text-[#1D1D1D] w-full max-w-[500px] text-center'
          >
            {t('popups.enter_email_popup.by_clicking')}{' '}
            <a
              href={PAGE_LINKS.TERMS}
              rel='noreferrer'
              target='_blank'
              className='text-[#5F30E2]'
            >
              {t('popups.enter_email_popup.terms_and_conditions')}
            </a>
            {displayNotice && (
              <>
                ,{' '}
                <a
                  href={PAGE_LINKS.SEO_LINKS.NOTICE}
                  rel='noreferrer'
                  target='_blank'
                  className='text-[#5F30E2]'
                >
                  {t('popups.enter_email_popup.notice')}
                </a>
              </>
            )}{' '}
            {t('popups.enter_email_popup.and')}{' '}
            <a
              href={PAGE_LINKS.PRIVACY_POLICY}
              rel='noreferrer'
              target='_blank'
              className='text-[#5F30E2]'
            >
              {t('popups.enter_email_popup.privacy_policy')}
            </a>
          </p>

          <div className='text-[13.2px] text-[#616161] leading-[17.7px] mt-4 tablet:mt-6 text-center w-full'>
            {t('popups.new_enter_email_popup_test.already_have_footer')}{' '}
            <LocalizedLink
              href={PAGE_LINKS.LOGIN}
              className='text-[#5F30E2] font-[700] underline'
              onClick={() => {
                closeGoogleOneTap();
                dispatch(toggleModal({ visible: false }));
                Analytics.sendEvent({
                  event: 'log_in_tap',
                  data: {
                    placement: `email_modal/${location.pathname}`
                  }
                });
              }}
            >
              {t('header.login')}
            </LocalizedLink>{' '}
          </div>
        </div>
        <div className='px-8 py-10 tablet:pt-12 min-w-[243px] hidden tablet:block bg-[#F6F6F6]'>
          <h2 className='text-[18px] font-[700] leading-[32px] align-bottom'>
            {t('popups.new_enter_email_popup_test.why_pdf_guru_title')}
          </h2>
          <ul className='flex flex-col gap-y-5 mt-4 tablet:mt-6'>
            {rightBlockItemsList.map((item, index) => (
              <li
                key={index}
                className='flex gap-x-2 items-center'
              >
                <img
                  src={item.icons}
                  alt={item.title}
                />
                <p className='text-[16px] leading-[20.8px]'>{item.title}</p>
              </li>
            ))}
          </ul>
          <div className='mt-10'>
            <img
              src={trustpilot}
              alt='trustpilot'
            />
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default EmailModalC;
