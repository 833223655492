import form1095a from 'assets/forms/1095-a.pdf';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';
import blue_number_icon_5 from 'assets/img/forms/5-step-blue-icon.svg';
import bgImgMob from 'assets/img/forms/background-1095-a-mob.webp';
//import bgImgTab from 'assets/img/forms/background-941-tab.webp';
import bgImgWeb from 'assets/img/forms/background-1095-a-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';

import type { IFormService } from 'ts/interfaces/services/service';
import { EServiceType } from 'ts/interfaces/services/service';

export const form1095AService = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    pagesCount: 6,
    path: '/form-1095-a',
    filePath: form1095a,
    fileName: '1095-a.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_1095a.title'),
      description: t('meta.form_1095a.description')
    },
    getForm: {
      headline1: 'IRS',
      headline2: 'Form',
      formName: '1095-A',
      subHeadline: '2024-2025 Printable Template',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM'
    },
    easilyComplete: {
      headline: 'A quick guide to handling Form 1095-A',
      description: [
        {
          icon: icon_1,
          iconMob: icon_mob_1,
          title: 'Access and fill',
          text: "Click 'GET FORM' to open a blank 1095-A Form, and fill in client details accurately using our user-friendly editing tools."
        },
        {
          icon: icon_2,
          iconMob: icon_mob_2,
          title: 'Validate client details',
          text: 'Review the completed 1095-A Form for accuracy to ensure compliance with IRS requirements.'
        },
        {
          icon: icon_3,
          iconMob: icon_mob_3,
          title: 'Download and submit',
          text: 'After validation, download the finalized form for client use and maintain a copy for your records.'
        }
      ]
    },
    whatIsForm: {
      headline: 'What is Form 1095-A?',
      text_1: [
        'For individuals, the 1095-A Form plays a vital role in their tax filing process. It provides the necessary information to reconcile or claim premium tax credits. Though not submitted to the IRS, the information from this form is crucial for completing other tax-related forms, such as Form 8962. Understanding and using the data from the 1095-A Form Ensures individuals accurately fulfill their income tax obligations and utilize eligible federal tax benefits.',
        'In addition to its role in the tax filing process, Form 1095-A also serves as a tool for Marketplaces to verify the eligibility of their enrollees for premium tax credits. This verification process helps ensure that only eligible individuals receive the tax credits, which in turn helps maintain the financial stability of the Marketplaces and the overall healthcare system.'
      ],
      text_2: [],
      text_3: [],
      text_4: [],
      title_1: '',
      title_2: '',
      subTitle: '',
      sectionImage: section_img,
      sectionImageTab: section_img_tab,
      description: [
        `IRS Form 1095-A, also known as the Health Insurance Marketplace Statement, is crucial for both Health Insurance Marketplaces and individual taxpayers. Marketplaces issue this form as a record of health insurance coverage provided to their enrollees. It includes vital details like types of coverage, coverage periods, and premium tax credits offered. Ensuring the accuracy of the 1095-A Form PDF is imperative for Marketplaces, as it directly affects their clients' ability to claim premium tax credits and reconcile advance payments.`
      ],
      bullets: [],
      redList: []
    },
    differenceBetween1: {
      headline: 'Who has to fill out form 1095-A?',
      text: "Health Insurance Marketplaces are responsible for accurately completing and sending out Form 1095-A to everyone who enrolled in a health plan through them. This form includes essential details like the type of insurance provided, coverage periods, and any advance payments of premium tax credits. It's crucial for Marketplaces to ensure the form is precise, as it directly impacts their clients' tax filings. If you're enrolled in a health plan through a Marketplace, you'll receive a Form 1095-A."
    },
    howToFill: {
      headline: 'How to fill out the 1095-A form',
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          strong: 'Start with PDF Guru:',
          text: ' Open the blank 1095-A Form on our platform.'
        },
        {
          icon: blue_number_icon_2,
          strong: 'Input Enrollee Data:',
          text: ' Enter all necessary information, including coverage details and other information.'
        },
        {
          icon: blue_number_icon_3,
          strong: 'Check Accuracy:',
          text: ' Review the form thoroughly to ensure all information is correct and complete.'
        },
        {
          icon: blue_number_icon_4,
          strong: 'Distribute:',
          text: ' Provide the completed form to enrollees promptly for their tax preparation needs.'
        },
        {
          icon: blue_number_icon_5,
          strong: 'Guide Clients:',
          text: ' Offer simple instructions on how enrollees should use the form for their tax filings, especially for Form 8962.'
        }
      ]
    },
    blockWithBlueTitle2: {
      title: 'What does a 1095-A form look like?',
      description: [
        "You can see what a blank 1095-A Form looks like by clicking the 'GET FORM' button on this very page. It is a standardized IRS document containing specific sections for enrollee information, coverage details, and financial information regarding premium tax credits. It's essential for Marketplaces to familiarize themselves with the form's layout to ensure accurate and efficient processing."
      ],
      orangeList: []
    },
    fillFormFree: {
      headline: 'Empowering Marketplaces for Better Tax Reporting',
      subtitle: 'Filling tax forms is now easier than ever',
      buttonLabel: 'Fill Form'
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: 'How to get 1095-A form online?',
          answer: [
            `Marketplaces can use PDF Guru to access a blank Form 1095-A and efficiently fill it out for each enrolled individual. Once completed, the forms can be downloaded and then distributed to individuals as per IRS requirements.`,
            `If you're enrolled in a health plan through a Marketplace, you will receive your Form 1095-A Directly from the Marketplace to complete Form 8962 for federal tax. You can access it online on the Marketplace's website.`
          ]
        },
        {
          question: 'How does Form 1095-A affect my taxes?',
          answer: [
            `The 1095-A Tax Form plays a crucial role in individual tax filings. It provides detailed information about health insurance coverage, which is necessary for accurately reporting and calculating premium tax credits on tax returns. For enrollees who received advance payments of premium tax credits, this form helps them reconcile the amount on their tax returns, ensuring they claim the correct tax benefits and comply with IRS regulations.`
          ]
        },
        {
          question: 'How to fill out an 8962 Form with 1095-A?',
          answer: [
            `To fill out Form 8962, refer to the information on your 1095-A Form about your insurance premiums and any advance premium tax credits you've received. This information is used on Form 8962 to calculate and reconcile your actual premium tax credit eligibility with any advance payments. This ensures your tax credits are correctly reflected in your tax return.`
          ]
        },
        {
          question: 'What is a 1095-C form?',
          answer: [
            `Form 1095-C is issued by employers and provides information about the health insurance coverage offered to employees. It's different from the 1095-A Form, which is provided by the Health Insurance Marketplace.`
          ]
        },
        {
          question: 'Can I download the 1095-A form for future use?',
          answer: [
            `The deadline for the Marketplace to provide Form 1095-A is January 31. It's important to ensure the accuracy of the information on Form 1095-A before using it to file your taxes. If you didn't receive the form or if there are any errors, you should contact the Marketplace Call Center.`
          ]
        }
      ]
    },
    fag: {
      question: 'Can I download the 1095-A form for future use?',
      answer:
        '1. Start with PDF Guru: Open the blank 1095-A Form on our platform. 2. Input Enrollee Data: Enter all necessary information, including coverage details and other information. 3. Check Accuracy: Review the form thoroughly to ensure all information is correct and complete. 4. Distribute: Provide the completed form to enrollees promptly for their tax preparation needs. 5. Guide Clients: Offer simple instructions on how enrollees should use the form for their tax filings, especially for Form 8962.'
    }
  };
};
