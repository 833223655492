import formDS82 from 'assets/forms/ds82.pdf';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';
import blue_number_icon_5 from 'assets/img/forms/5-step-blue-icon.svg';
import blue_number_icon_6 from 'assets/img/forms/6-step-blue-icon.svg';
import bgImgMob from 'assets/img/forms/background-ds82-mob.webp';
//import bgImgTab from 'assets/img/forms/background-ds82-tab.webp';
import bgImgWeb from 'assets/img/forms/background-ds82-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';

import type { IFormService } from 'ts/interfaces/services/service';
import { EServiceType } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export const formDS82Service = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    pagesCount: 6,
    path: '/form-ds82',
    filePath: formDS82,
    fileName: 'ds82.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_ds82.title'),
      description: t('meta.form_ds82.description')
    },
    getForm: {
      headline1: '',
      headline2: 'Passport Renewal Form',
      formName: 'DS-82',
      subHeadline: '2022-2024 Printable Template',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM'
    },
    easilyComplete: {
      headline: 'We make passport renewal easy',
      description: [
        {
          icon: icon_1,
          iconMob: icon_mob_1,
          title: 'Start with PDF Guru',
          text: 'Access our platform and open a fillable DS-82 form. Our intuitive interface guides you smoothly through the initial steps.'
        },
        {
          icon: icon_2,
          iconMob: icon_mob_2,
          title: 'Edit and personalize',
          text: 'Easily add all the required details following DS-82 form instructions.'
        },
        {
          icon: icon_3,
          iconMob: icon_mob_3,
          title: 'Download and submit',
          text: 'After editing, download your form for submission as per IRS guidelines.'
        }
      ]
    },
    whatIsForm: {
      headline: 'What is a DS-82 form?',
      text_1: [],
      text_2: [
        "The DS-82 cannot be used for first-time applicants, name changes, or other special situations requiring form DS-11. PDF Guru's DS-82 form filler assists users in accurately completing their applications, ensuring compliance."
      ],
      text_3: [
        "Traditionally, obtaining a DS-82 passport renewal form required visiting a government office or printing it from a website. However, now you can download an online DS-82 form from the U.S. Department of State's website. Our platform, PDF Guru, also provides an editable DS-82 form, ready for you to fill out and download, streamlining your passport renewal process."
      ],
      text_4: [],
      orangeList: [
        {
          text: 'Having a valid passport issued less than 15 years ago;'
        },
        {
          text: 'Being over 16 years old;'
        },
        {
          text: 'Having the same name as on the most recent passport.'
        }
      ],
      title_1: 'Who needs the DS-82 passport form?',
      title_2: 'Where can I get a DS-82 passport renewal form?',
      subTitle:
        'U.S. citizens who meet the renewal eligibility requirements can use form DS-82. Those criteria include:',
      sectionImage: section_img,
      sectionImageTab: section_img_tab,
      description: [
        'The DS-82 form is a crucial document for U.S. citizens seeking passport renewal by mail. It is specifically designed for individuals whose current passport is eligible for renewal without an in-person appointment.',
        'Filling a DS-82 passport form online is a convenient and efficient way to update your passport, ensuring continued eligibility for international travel. Our platform, PDF Guru, offers an easy-to-use interface for completing a DS-82 printable form, making the renewal process smoother and more accessible.'
      ],
      bullets: []
    },
    blockWithBlueTitle1: {
      title: 'What do I submit with a DS 82 form?',
      description: [
        "When submitting your Form DS-82, make sure to include your current passport, a new passport photo, and the applicable DS-82 form fee. Using PDF Guru, you can efficiently complete your form DS-82 application for a U.S. passport by mail. It's also crucial to follow the specific guidelines for the photo to avoid processing delays. The photo must be 2x2 inches in size and attached to the completed application with 4 staples. "
      ],
      title_list: {
        strong: "Here's a checklist of things you need to include:",
        text: ''
      },
      orangeList: [
        {
          text: 'Your current passport;'
        },
        {
          text: 'A check or money order for the DS-82 form fee;'
        },
        {
          text: 'A passport photo;'
        },
        {
          text: 'A completed DS-82 form. '
        }
      ]
    },
    howToFill: {
      headline: 'How to fill out Form DS-82',
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          strong: 'Start with PDF Guru:',
          text: ' Access the DS-82 passport form online on our platform.'
        },
        {
          icon: blue_number_icon_2,
          strong: 'Input personal information:',
          text: ' Fill out your personal details, including name, address, and previous passport number.'
        },
        {
          icon: blue_number_icon_3,
          strong: 'Photo attachment:',
          text: ' Attach a passport-sized photo according to the specific requirements.'
        },
        {
          icon: blue_number_icon_4,
          strong: 'Review and download:',
          text: ' Ensure all information isсaccurate before downloading the fillable DS-82 form.'
        },
        {
          icon: blue_number_icon_5,
          strong: 'Print the form:',
          text: ' Print your form on standard white paper. Note that the DS-82 form does not require a signature before submission.'
        },
        {
          icon: blue_number_icon_6,
          strong: 'Mail the form:',
          text: ' Send your completed form along with your current passport and photo to the designated processing center.'
        }
      ]
    },
    fillFormFree: {
      headline: 'Fill out Form DS-82 Online with PDF Guru',
      subtitle: 'We make passport renewal hassle-free',
      buttonLabel: 'Fill Form'
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: 'How to print the DS-82 form?',
          answer: [
            'After filling out the DS-82 form online using PDF Guru, you can easily print it. Our platform ensures that the passport renewal application form maintains the correct format and size. Always use plain, white paper of standard size for printing.',
            'The DS-82 passport application form is printed on A4 format paper, with each page on a separate sheet. Forms printed on both sides of the paper will not be accepted. The passport application form can be printed in black and white or in color.'
          ]
        },
        {
          question: 'How much does it cost to get a passport using form DS-82',
          answer: [
            'The total cost for renewing a passport using Form DS-82 includes the renewal fee and any applicable expedite fees. The standard renewal fee can be found on the IRS website. Remember, this does not include additional costs like photos or postage.'
          ]
        },
        {
          question: 'How to answer question 1 on form DS-82?',
          answer: [
            "Question 1 on the passport renewal application form DS-82 asks for your personal information, such as your full legal name, date of birth, and social security number. It's crucial to provide accurate and current details, matching your existing passport and legal documents."
          ]
        },
        {
          question: 'How to fill out form DS-82 for a name change?',
          answer: [
            'The DS-82 form cannot be used for name changes. Instead, you should use Form DS-11 or Form DS-5504 for name changes due to marriage, divorce, or court order.'
          ]
        },
        {
          question: 'How long does it take to process Form DS-82 for passport renewal?',
          answer: [
            "The processing time for Form DS-82 can vary. However, in general, it takes approximately 4-6 weeks for routine service. If you require expedited service, which incurs an additional fee, the processing time is typically reduced to 2-3 weeks. It's important to plan ahead and submit your renewal application in a timely manner to avoid any potential travel disruptions due to passport expiration."
          ]
        }
      ]
    },
    fag: {
      question: 'How to fill out a 1040 Form',
      answer:
        '1. Start on PDF Guru: Access the DS-82 passport form online on our platform. 2. Input personal information: Fill out your personal details, including name, address, and previous passport number. 3.Photo attachment: Attach a passport-sized photo according to the specific requirements. 4.Review and download: Ensure all information is accurate before downloading the fillable DS-82 form. 5.Print the form: Print your form on standard white paper. Note that the DS-82 form does not require a signature before submission. 6.Mail the form: Send your completed form along with your current passport and photo to the designated processing center.'
    },
    bannerQuickNEasy: {
      headline: 'Get Your DS-82 Fast with PDF Guru',
      subtitle: 'Streamline passport renewal today',
      format: InternalFileType.PDF,
      buttonLabel: 'Fill Form'
    }
  };
};
