import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import { calculateProgress, useAnimatedProgress } from 'hooks/useAnimatedProgress';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';

import { Analytics } from 'services/analytics';
import { FileUploadingModalViewTypes } from 'services/analytics/events';

import { updateDownloadProgress } from 'data/actions/documents';
import { uploadProgressSelector } from 'data/selectors/documents';
import { modalOptionsSelector } from 'data/selectors/modals';

import ModalLayout from '../baseModal/base';
import Banner from './components/banner';
import { ProgressBar } from './components/progress-bar';

const ProgressEditFileModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const downloadProgress = useSelector(uploadProgressSelector());
  const animatedProgress = useAnimatedProgress(5);
  const progressValue = calculateProgress(animatedProgress, 0, 90);
  const [barValue, setBarValue] = useState(0);
  const modalOptions = useSelector(modalOptionsSelector);

  useCloseModalOnBack({ onModalClose: modalOptions?.onUserClose });

  const [isForm, setIsForm] = useState(false);

  const eventsMap = useMemo(
    () =>
      new Map([
        [10, 'file_uploading_10'],
        [35, 'file_uploading_35'],
        [70, 'file_uploading_70'],
        [100, 'file_uploading_100']
      ]),
    []
  );

  useEffect(() => {
    if (progressValue > downloadProgress) {
      dispatch(updateDownloadProgress(progressValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, progressValue]);

  useEffect(() => {
    setIsForm(modalOptions?.isForm);
    return () => {
      dispatch(updateDownloadProgress(0));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const newBarValue = downloadProgress.toFixed(0);

    if (newBarValue < 20) setBarValue(10);
    else if (newBarValue < 35) setBarValue(20);
    else if (newBarValue < 50) setBarValue(35);
    else if (newBarValue < 70) setBarValue(50);
    else if (newBarValue < 80) setBarValue(70);
    else if (newBarValue <= 90) setBarValue(93);
    else setBarValue(100);
  }, [downloadProgress]);

  useEffect(() => {
    Analytics.sendEvent({
      event: 'file_uploading_modal_view',
      data: {
        type: FileUploadingModalViewTypes.editFunnels,
        screen_config_name: 'advanced_loaders_animations'
      }
    });
  }, []);

  useEffect(() => {
    const event = eventsMap.get(barValue);
    if (event) {
      Analytics.sendEvent({ event });
    }
  }, [barValue, eventsMap]);

  return (
    <ModalLayout dataTestId='progress-edit-file-modal'>
      <div className='flex flex-col items-center gap-6 tablet:w-[468px] w-full min-w-[calc(100%_-_32px)] p-8'>
        <div className='flex flex-col gap-3 w-full'>
          <div
            className={classNames('flex flex-col font-bold text-center text-[25px] leading-[32.5px] text-[#1D1D1D]')}
          >
            {isForm
              ? t('popups.processing_edit_pdf_popup.uploading_form')
              : t('popups.processing_edit_pdf_popup.uploading')}
          </div>
          <div className='flex flex-col gap-3 items-center w-full'>
            <ProgressBar
              className='mt-0'
              progress={barValue}
            />
            <div className='font-bold text-[16px] text-[#1D1D1D]'>{barValue}%</div>
          </div>
        </div>
        <Banner />
      </div>
    </ModalLayout>
  );
};

export default ProgressEditFileModal;
