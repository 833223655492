import * as React from 'react';

import checkAnimation from 'assets/animations/check-animation.json';
import crossAnimation from 'assets/animations/cross-animation.json';
import loadingAnimation from 'assets/animations/loading-animation.json';
import lottie from 'lottie-web';

export type LoadingState = 'loading' | 'completed' | 'error';
// export type LoadingAnimationColor = 'default' | 'red'
interface IProps {
  currentState: LoadingState;
  // color?: LoadingAnimationColor
  className?: string;
}
export const LoadingAnimation: React.FC<IProps> = ({
  currentState,
  // color = 'default',
  className
}) => {
  const container = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const animations: Record<LoadingState, any> = {
      loading: loadingAnimation,
      completed: checkAnimation,
      error: crossAnimation
    };

    if (!container.current) return;
    if (typeof window === 'undefined') return;

    const animationPlayer = lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      autoplay: true,
      loop: currentState === 'loading',
      animationData: animations[currentState]
    });

    return () => {
      animationPlayer.destroy();
    };
  }, [container, currentState]);

  if (className) {
    return (
      <div
        ref={container}
        className={className}
      />
    );
  }

  return <div ref={container} />;
};
