import type { FC } from 'react';
import { useState } from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Analytics } from 'services/analytics';

import arrowDownIcon from '../assets/arrow.svg';
import checkIcon from '../assets/check.svg';
import type { Format } from '../formatsList';

interface DropdownProps {
  formatsList: Format[];
  selectedFormat: Format;
  onSelectFormat: (format: Format) => void;
  getConverterName: (format: string) => string;
  className?: string;
}

export const Dropdown: FC<DropdownProps> = ({
  formatsList,
  selectedFormat,
  onSelectFormat,
  className = '',
  getConverterName
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectFormat = (format: Format) => {
    onSelectFormat(format);
    Analytics.sendEvent({ event: 'select_format_tap', data: { format: getConverterName(format.to) } });
    setIsOpen(false);
  };

  return (
    <div className={`relative w-full ${className}`}>
      <div
        className='w-full p-4 bg-white border-[1px] border-[#C7C7C7] rounded-[6px] text-[16px] font-semibold cursor-pointer flex justify-between items-center'
        onClick={() => setIsOpen(!isOpen)}
        role='button'
        data-testid='choose-format-modal'
      >
        <div className='flex items-center'>
          <LazyLoadImage
            src={selectedFormat.icon}
            alt={selectedFormat.label}
            className='w-6 h-6 mr-2'
          />
          <span>{selectedFormat.label}</span>
        </div>
        <LazyLoadImage
          src={arrowDownIcon}
          alt='arrow down'
          className={`w-5 ${isOpen ? 'rotate-180' : ''}`}
        />
      </div>

      {isOpen && (
        <div className='absolute top-full left-0 w-full mt-1 bg-white border-[1px] border-[#C7C7C7] rounded-[6px] shadow-lg z-40 max-h-[320px] overflow-y-scroll'>
          {formatsList.map((option) => (
            <div
              key={option.format}
              data-testid={`format-item`}
              className={`p-4 pr-[48px] hover:bg-[#f6f6f6] cursor-pointer flex w-full items-center justify-between`}
              onClick={() => {
                handleSelectFormat(option);
              }}
              role='button'
            >
              <div className='flex items-center'>
                <LazyLoadImage
                  src={option.icon}
                  alt={option.label}
                  className='w-6 h-6 mr-2'
                />
                {option.label}
              </div>
              <div className='text-[#616161]'>{option.description}</div>
              {option.format === selectedFormat.format && (
                <LazyLoadImage
                  src={checkIcon}
                  alt='check'
                  className='absolute right-4 w-5'
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
