import { matchPath } from 'react-router-dom';

import { DEFAULT_LANGUAGE, languages } from 'i18n';

type Language = (typeof languages)[number];

export function getLocaleByPath(path: string): Language {
  const matchPattern = path.startsWith('/') ? '/app/:locale?/*' : 'app/:locale?/*';
  const matches = matchPath(matchPattern, path);

  const locale = matches?.params?.locale as Language | undefined;

  if (!locale) {
    return DEFAULT_LANGUAGE;
  }

  if (languages.includes(locale)) {
    return locale;
  }

  return DEFAULT_LANGUAGE;
}
