import React, { useState } from 'react';

import { useDrag, useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';

import cross from 'assets/img/icons/16px/cross.svg';
import dragDoc from 'assets/img/icons/16px/drag_doc.svg';
import classNames from 'classnames';
import { t } from 'i18next';

import { Analytics } from 'services/analytics';

import { setEditMultipleDocumentsDataABImportExportEditor244 } from 'data/actions/documents';
import { selectEditMultipleDocuments } from 'data/selectors/documents';

import { formatBytes } from 'utils/formatBytes';
import { truncateFileName } from 'utils/truncateFileName';

import { UploadFileIcon } from '../UploadFIleIcon';

interface IUploadFileItem {
  item: File;
  index: number;
  moveFile: (from: number, to: number) => void;
  unsupportedFiles: File[];
}

export const UploadFileItem = ({ item, index, moveFile, unsupportedFiles }: IUploadFileItem) => {
  const ref = React.useRef(null);
  const files = useSelector(selectEditMultipleDocuments);
  const dispatch = useDispatch();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [{ isDragging }, drag] = useDrag({
    type: 'FILE',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [, drop] = useDrop({
    accept: 'FILE',
    hover: (draggedItem: any) => {
      if (draggedItem.index !== index) {
        moveFile(draggedItem.index, index);
        draggedItem.index = index;
      }
    }
  });

  drag(drop(ref));

  const deleteFile = () => {
    Analytics.sendEvent({ event: 'upload_modal_action', data: { action_type: 'delete' } });
    const updatedFiles = files.filter((file: File) => file !== item);
    dispatch(setEditMultipleDocumentsDataABImportExportEditor244(updatedFiles));
    setShowConfirmDelete(false);
  };

  const isUnsupportedFile = unsupportedFiles.includes(item);

  return (
    <div
      ref={ref}
      className={classNames(
        'flex flex-row items-center justify-between w-full cursor-pointer p-4 bg-[#ffffff] border-b border-[#E9E9E9] last:border-none transition-all duration-100',
        { 'opacity-50': isDragging },
        'hover:bg-[#F6F6F6]'
      )}
      data-testid={`format-item`}
    >
      <div className='flex items-center'>
        <img
          src={dragDoc}
          alt='docs'
          className='h-[14px] w-[10px] mr-3'
        />
        <UploadFileIcon
          type={item.type}
          size={32}
        />
        <div className='flex flex-col gap-1 ml-3'>
          <span className='text-base'>{showConfirmDelete ? truncateFileName(item.name, 20) : item.name}</span>
          {isUnsupportedFile ? (
            <h2 className='text-[13px] text-[#D90A0A] font-bold'>
              {t('popups.upload_modal_ab_import_export_editor_2_4_4.error_message')}
            </h2>
          ) : (
            <span className='text-sm text-[#616161]'>{formatBytes(item.size)}</span>
          )}
        </div>
      </div>
      <div className='cursor-pointer'>
        {showConfirmDelete ? (
          <div className='flex items-center gap-2'>
            <div
              onClick={() => setShowConfirmDelete(false)}
              className='cursor-pointer border-2 rounded-xl py-2 px-4 border-[#000000] font-bold text-[#000000] text-[13px]'
              role='button'
            >
              {t('popups.upload_modal_ab_import_export_editor_2_4_4.cancel')}
            </div>
            <div
              onClick={deleteFile}
              className='cursor-pointer border-2 rounded-xl py-2 px-4 border-[#D3294B] font-bold text-[#D3294B] text-[13px]'
              role='button'
            >
              {t('popups.upload_modal_ab_import_export_editor_2_4_4.delete')}
            </div>
          </div>
        ) : (
          <img
            src={cross}
            onClick={() => setShowConfirmDelete(true)}
            alt='cross'
            className='w-8 h-8'
          />
        )}
      </div>
    </div>
  );
};
