export const sliceFileName = (str: string): string => {
  const OK_LENGTH = 22;
  const MAX_LENGTH_WITH_SPACES = 40;
  const MAX_LENGTH_WO_SPACES = 15;
  if (str.length < OK_LENGTH) {
    return str;
  }

  const hasSpaces = str.includes(' ');
  const formatDotPos = str.split('').reverse().join('').indexOf('.');
  const format = str.slice(formatDotPos * -1);
  const fileNameWoFormat = str.replace(format, '');

  if (fileNameWoFormat.length < MAX_LENGTH_WITH_SPACES && hasSpaces) {
    return str;
  }

  const cutStr = fileNameWoFormat.slice(0, MAX_LENGTH_WO_SPACES);
  return `${cutStr}...${format}`;
};
