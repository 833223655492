import form1040 from 'assets/forms/1040.pdf';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';
import blue_number_icon_5 from 'assets/img/forms/5-step-blue-icon.svg';
import blue_number_icon_6 from 'assets/img/forms/6-step-blue-icon.svg';
import blue_number_icon_7 from 'assets/img/forms/7-step-blue-icon.svg';
import bgImgMob from 'assets/img/forms/background-1040-mob.webp';
//import bgImgTab from 'assets/img/forms/background-941-tab.webp';
import bgImgWeb from 'assets/img/forms/background-1040-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';

import type { IFormService } from 'ts/interfaces/services/service';
import { EServiceType } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export const form1040Service = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    pagesCount: 6,
    path: '/form-1040',
    filePath: form1040,
    fileName: '1040.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_1040.title'),
      description: t('meta.form_1040.description')
    },
    getForm: {
      headline1: 'IRS',
      headline2: 'Form',
      formName: '1040',
      subHeadline: '2024-2025 Printable Template',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM'
    },
    easilyComplete: {
      headline: 'How to use PDF Guru for your 1040 form',
      description: [
        {
          icon: icon_1,
          iconMob: icon_mob_1,
          title: 'Fill form',
          text: 'Use PDF Guru to instantly access a blank 1040 form in our online editor. No downloads or software installations required!'
        },
        {
          icon: icon_2,
          iconMob: icon_mob_2,
          title: 'Edit and review',
          text: 'Effortlessly fill in the form fields, add signatures, and edit text directly within your browser. Our intuitive tools make the process seamless. Review the form one last time.'
        },
        {
          icon: icon_3,
          iconMob: icon_mob_3,
          title: 'Download and file',
          text: 'Your completed IRS 1040 form is now ready to be submitted to the IRS, either electronically or by mail.'
        }
      ]
    },
    whatIsForm: {
      headline: 'What is the IRS 1040 Form?',
      text_1: [],
      text_2: [],
      text_3: [
        "Form 1040 serves multiple purposes. It's essential for reporting annual income, calculating tax liability, and determining if you're eligible for tax credits or deductions. It's also a critical document for the IRS to assess your financial situation and ensure compliance with tax laws."
      ],
      text_4: [],
      text_5: [
        "It's important to be extremely careful when completing your federal tax form to ensure accuracy and compliance with IRS regulations.  Errors can lead to delays in processing your return, penalties, or even audits from the IRS. Double-check your entries and consult a tax professional if you have any doubts or complex tax situations."
      ],
      title_1: 'There are also several types of 1040 Form',
      title_2: 'What is Form 1040 used for?',
      title_3: 'Why is it important to be extremely careful when completing your 1040 Form?',
      subTitle: '',
      sectionImage: section_img,
      sectionImageTab: section_img_tab,
      description: [
        "IRS Form 1040, formally known as the U.S. Individual Income Tax Return, is the main form people use to file their income tax returns. It is provided by the Internal Revenue Service. This form allows taxpayers to calculate their total taxable income, subtract any allowable deductions, and determine the amount of tax owed or refund due. It's the primary form used for personal federal income tax returns. Form 1040 comes with various schedules and additional forms that may need to be filed along with it, depending on the taxpayer's situation."
      ],
      bullets: [],
      orangeList: [
        {
          strong: 'What is Form 1040-SR?',
          isColumn: true,
          text: 'IRS Form 1040-SR is a version of the 1040 tax form designed for senior taxpayers, aged 65 and older, which takes into account social security benefits. It offers larger print and a more readable layout without impacting the reporting requirements or calculations.'
        },
        {
          strong: 'What is Form 1040-ES?',
          isColumn: true,
          text: 'Tax Form 1040-ES is used to make estimated tax payments throughout the year, especially for self-employed individuals or those with unpredictable income sources.'
        },
        {
          strong: 'What is a 1040-X Form?',
          isColumn: true,
          text: 'The IRS 1040-X form is used to amend a previously filed 1040 tax return. It allows taxpayers to correct errors and claim a more favorable tax status.'
        },
        {
          strong: 'What is Form 1040-NR?',
          isColumn: true,
          text: 'Tax Form 1040-NR is for non-resident aliens of the United States who need to file a tax return for U.S. sourced income.'
        }
      ]
    },
    differenceBetween1: {
      headline: 'What does a 1040 Form look like?',
      text: "You can see what a blank 1040 Form looks like by clicking the 'GET FORM' button on this very page. Basically, it is a two-page document that requires various personal and financial information. It includes sections for income, deductions, credits, and other relevant details."
    },
    blockWithBlueTitle1: {
      title: 'Who needs to fill out Form 1040?',
      description: [
        'Most U.S. taxpayers need to file the Form 1040. This includes those with varied sources of income like wages, dividends, or business earnings. Different forms like 1040-SR or 1040-NR might be required based on age or residency status.'
      ],
      title_list: {
        text: '',
        strong: 'You are obligated to file tax Form 1040 if you fall under any of the following categories:'
      },
      orangeList: [
        {
          strong: 'Earned income above the IRS filing threshold:',
          text: ' The specific threshold depends on your age, filing status, and other factors. Check the IRS website for current thresholds.'
        },
        {
          strong: 'Owe taxes:',
          text: ' Even if your income falls below the filing threshold, you still need to file if you owe federal income tax. This could be due to self-employment income, investment income, or other sources.'
        },
        {
          strong: 'Claim certain tax credits:',
          text: " Some tax credits, like the Earned Income Tax Credit (EITC), require filing a return even if you wouldn't otherwise be obligated."
        },
        {
          strong: 'Received unemployment benefits:',
          text: ' Even though unemployment benefits are not taxable income, you still need to report them on your tax Form 1040.'
        }
      ]
    },
    howToFill: {
      headline: 'How to fill out a 1040 Form',
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          strong: 'Start with PDF Guru:',
          text: ' Access our platform and open the blank 1040 form.'
        },
        {
          icon: blue_number_icon_2,
          strong: 'Enter personal information:',
          text: ' Fill in your name, address, SSN, and filing status.'
        },
        {
          icon: blue_number_icon_3,
          strong: 'Report income:',
          text: ' Detail all sources of income as per IRS Form 1040 instructions.'
        },
        {
          icon: blue_number_icon_4,
          strong: 'Claim deductions:',
          text: ' Reduce your taxable income by claiming eligible deductions.'
        },
        {
          icon: blue_number_icon_5,
          strong: 'Calculate tax:',
          text: ' Follow the instructions to compute your tax liability or refund.'
        },
        {
          icon: blue_number_icon_6,
          strong: 'Review and download:',
          text: ' Ensure accuracy and download the form for submission. You can either e-sign it digitally within PDF Guru or print it for manual signing.'
        },
        {
          icon: blue_number_icon_7,
          strong: 'Submission:',
          text: ' Submit your form to the IRS as per their submission guidelines.'
        }
      ]
    },
    fillFormFree: {
      headline: 'Simplify Your Tax Filing Today',
      subtitle: 'Effortless, accurate, and quick',
      buttonLabel: 'Fill Form'
    },
    bannerQuickNEasy: {
      headline: 'Get Your 1040 Form Now',
      subtitle: 'PDF Guru makes tax season stress-free',
      format: InternalFileType.PDF,
      buttonLabel: 'Fill Form'
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: 'How to get a 1040 Form online?',
          answer: [
            'You can use PDF Guru to get a 1040 tax form online. Our platform provides an easily accessible, editable version of the form which you can fill out and download for your IRS submission.'
          ]
        },
        {
          question: 'Where to file Form 1040 online?',
          answer: [
            "You can file your 1040 tax form online through the IRS's electronic filing options, such as IRS Free File. Ensure your form is complete and accurate before submission."
          ]
        },
        {
          question: 'Where to mail Form 1040?',
          answer: [
            'If you choose to file a paper return, you should mail your 1040 tax form to the IRS. The specific mailing address depends on your state, which you can find on the IRS website.'
          ]
        },
        {
          question: 'Where to report 1099-S on form 1040?',
          answer: [
            "Report any income from Form 1099-S, related to real estate transactions, on Schedule D of the 1040 tax form if it's capital gains, or on Schedule E if it's rental income."
          ]
        }
      ]
    },
    fag: {
      question: 'How to fill out a 1040 Form',
      answer:
        '1.Start with PDF Guru: Access our platform and open the blank 1040 form. 2.Enter personal information: Fill in your name, address, SSN, and filing status. 3.Report income: Detail all sources of income as per IRS Form 1040 instructions. 4.Claim deductions: Reduce your taxable income by claiming eligible deductions. 5.Calculate tax: Follow the instructions to compute your tax liability or refund. 6.Review and download: Ensure accuracy and download the form for submission. You can either e-sign it digitally within PDF Guru or print it for manual signing. 7.Submission: Submit your form to the IRS as per their submission guidelines.'
    }
  };
};
