import type { FC } from 'react';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import EmailSentIcon from 'assets/img/icons/emailsent.svg';

import ModalLayout from 'components/modals/baseModal/base';

import { Analytics } from 'services/analytics';

import { modalOptionsSelector } from 'data/selectors/modals';

import s from './restore-password-sent-popup.module.scss';

const CheckYourEmailModal: FC = () => {
  const { t } = useTranslation();
  const options = useSelector(modalOptionsSelector);

  useEffect(() => {
    Analytics.sendEvent({ event: 'recover_pass_email_sent_modal_view' });
  }, []);

  return (
    <ModalLayout canClose>
      <div className={s.restorePasswordSentPopup}>
        <img
          src={EmailSentIcon}
          width={161}
          height={146}
          alt='Email sent'
        />
        <h2>{t('popups.restore_password_sent_popup.headline')}</h2>
        <p>{t('popups.restore_password_sent_popup.description')}</p>
        <b>{options.email}</b>
      </div>
    </ModalLayout>
  );
};

export default CheckYourEmailModal;
