import form1098t from 'assets/forms/1098-t.pdf';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';
import blue_number_icon_5 from 'assets/img/forms/5-step-blue-icon.svg';
import blue_number_icon_6 from 'assets/img/forms/6-step-blue-icon.svg';
import blue_number_icon_7 from 'assets/img/forms/7-step-blue-icon.svg';
import blue_number_icon_8 from 'assets/img/forms/8-step-blue-icon.svg';
import blue_number_icon_9 from 'assets/img/forms/9-step-blue-icon.svg';
import bgImgMob from 'assets/img/forms/background-1098-t-mob.webp';
//import bgImgTab from 'assets/img/forms/background-941-tab.webp';
import bgImgWeb from 'assets/img/forms/background-1098-t-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';

import type { IFormService } from 'ts/interfaces/services/service';
import { EServiceType } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export const form1098tService = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    pagesCount: 6,
    path: '/form-1098-t',
    filePath: form1098t,
    fileName: '1098-t.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_1098t.title'),
      description: t('meta.form_1098t.description')
    },
    getForm: {
      headline1: 'IRS',
      headline2: 'Form',
      formName: '1098-T',
      subHeadline: '2020-2024 Printable Template',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM'
    },
    easilyComplete: {
      headline: 'How to fill out the 1098-T form',
      description: [
        {
          icon: icon_1,
          iconMob: icon_mob_1,
          title: 'Access the form',
          text: 'Log in and click "GET FORM" to open a blank 1098-T in our versatile PDF editor, already set up and ready for you.'
        },
        {
          icon: icon_2,
          iconMob: icon_mob_2,
          title: 'Edit and customize',
          text: "Use our wide range of editing tools to fill in all the necessary details. Ensure every detail is captured correctly, reflecting the student's educational expenses and scholarships."
        },
        {
          icon: icon_3,
          iconMob: icon_mob_3,
          title: 'Save and submit',
          text: "Once you've filled out the form, save your progress and download the form. It should be formatted correctly and ready for submission according to IRS requirements."
        }
      ]
    },
    whatIsForm: {
      headline: 'What is a 1098-T Form?',
      text_1: [],
      text_2: [],
      text_3: [],
      text_4: [],
      title_1: 'Understanding box amounts on the 1098-T',
      title_2: '',
      subTitle:
        "Each box on the 1098-T form carries specific information critical to accurate reporting. Understanding the information in these boxes is crucial for accurately claiming education-related tax credits and deductions. It's important to cross-reference your own records with the 1098-T to ensure all information is correct and complete.",
      sectionImage: section_img,
      sectionImageTab: section_img_tab,
      description: [
        'The 1098-T tax form, commonly known as the "Tuition Statement", is a vital document that colleges, universities, and other eligible educational institutions are mandated to issue. This form plays a crucial role in the tax reporting process, detailing payments received and amounts billed for qualified tuition and related educational expenses.',
        'It also serves as a key document for students, enabling them to claim education tax credits, such as the American Opportunity Credit and the Lifetime Learning Credit. The 1098-T form reports specific expenses, such as tuition fees and charges for necessary course materials. However, it does not include personal expenses like room and board, insurance, or transportation.'
      ],
      bullets: [],
      orangeList: [
        {
          strong: 'Box 1:',
          text: ' Displays the total payments received for qualified tuition and related expenses during the calendar year.'
        },
        {
          strong: 'Box 2:',
          text: ' (No longer in use as of tax year 2018) Previously indicated amounts billed for qualified tuition and related expenses.'
        },
        {
          strong: 'Box 3:',
          text: ' If checked, signifies a change in reporting method from the previous year.'
        },
        {
          strong: 'Box 4:',
          text: " Shows any adjustments made by the institution for a prior year's qualified tuition and related expenses."
        },
        {
          strong: 'Box 5:',
          text: ' Reports the total of all scholarships or grants that the institution administered and processed for the student’s account.'
        },
        {
          strong: 'Box 6:',
          text: ' Indicates adjustments to scholarships or grants for a prior year.'
        },
        {
          strong: 'Box 7:',
          text: ' If checked, means that Box 1 includes amounts for an academic period beginning in the first three months of the next year.'
        },
        {
          strong: 'Box 8:',
          text: ' Shows whether the student is considered to be carrying at least a half-time course load.'
        },
        {
          strong: 'Box 9:',
          text: ' Indicates if the student is enrolled in a program leading to a graduate degree, graduate-level certificate, or other recognized graduate-level educational credential.'
        },
        {
          strong: 'Box 10:',
          text: ' (For insurer use only) Reports insurance contract reimbursements or refunds.'
        }
      ]
    },
    differenceBetween1: {
      headline: 'Who files a 1098-T form?',
      text: 'The responsibility of filing the 1098-T form primarily lies with educational institutions, not the students. Colleges, universities, and other eligible educational entities are required to file this form for each student who pays for qualified educational expenses. Students who have paid tuition or related expenses in the past tax year need to file a copy of the 1098-T form issued by their educational institution. This includes most college and university students in the United States.'
    },
    howToFill: {
      headline: 'How to fill out the 1098-T form?',
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          text: ' Open PDF Guru and select the blank 1098-T form.'
        },
        {
          icon: blue_number_icon_2,
          text: " Enter the student's name, address, and identification number."
        },
        {
          icon: blue_number_icon_3,
          text: ' Fill in your institution’s name, address, and federal ID number.'
        },
        {
          icon: blue_number_icon_4,
          text: ' In Box 1, input total payments received for qualified tuition and related expenses.'
        },
        {
          icon: blue_number_icon_5,
          text: ' Report any scholarships or grants in Box 5.'
        },
        {
          icon: blue_number_icon_6,
          text: ' Include adjustments from prior years in Boxes 4 and 6.'
        },
        {
          icon: blue_number_icon_7,
          text: ' Fill in Boxes 7, 8, and 9 for academic period, student status, and level of study.'
        },
        {
          icon: blue_number_icon_8,
          text: ' Thoroughly review the 1098-T form and make any necessary corrections.'
        },
        {
          icon: blue_number_icon_9,
          text: ' Save the completed form and distribute it to the student; file with the IRS as required.'
        }
      ]
    },
    blockWithBlueTitle2: {
      title: "Why it's important to file form 1098-T",
      description: [
        "The 1098-T IRS form is more than just a tax document; it's a critical piece in the financial relationship between students and educational institutions. Here's why it's significant for both parties:"
      ],
      title_list: {
        text: '',
        strong: 'For colleges and universities:'
      },
      orangeList: [
        {
          strong: 'Compliance with federal regulations:',
          text: ' Colleges are required by the IRS to provide 1098-T forms to students who incur qualified educational expenses. This compliance ensures that institutions maintain their eligibility to offer federally backed student aid programs.'
        },
        {
          strong: 'Record keeping and financial reporting:',
          text: " The 1098-T form helps colleges maintain accurate financial records. It's an essential part of their internal accounting and external reporting, providing a transparent record of tuition-related transactions."
        },
        {
          strong: 'Supporting students’ financial planning:',
          text: ' By issuing 1098-T forms, colleges play a role in helping students understand and plan for their educational expenses and potential tax benefits.'
        }
      ],
      title_list2: {
        text: '',
        strong: 'For students:'
      },
      orangeList2: [
        {
          strong: 'Eligibility for tax credits:',
          text: ' Colleges are required by the IRS to provide 1098-T forms to students who incur qualified educational expenses. This compliance ensures that institutions maintain their eligibility to offer federally backed student aid programs.'
        },
        {
          strong: 'Understanding educational expenses:',
          text: " The 1098-T form helps colleges maintain accurate financial records. It's an essential part of their internal accounting and external reporting, providing a transparent record of tuition-related transactions."
        },
        {
          strong: 'Reconciling scholarships and grants:',
          text: ' By issuing 1098-T forms, colleges play a role in helping students understand and plan for their educational expenses and potential tax benefits.'
        },
        {
          strong: 'Facilitating loan forgiveness programs:',
          text: ' By issuing 1098-T forms, colleges play a role in helping students understand and plan for their educational expenses and potential tax benefits.'
        }
      ]
    },
    fillFormFree: {
      headline: 'Streamline Your 1098-T Filing with PDF Guru',
      subtitle: '',
      buttonLabel: 'Fill Form'
    },
    bannerQuickNEasy: {
      headline: 'Optimize Your Tax Reporting With Ease',
      subtitle: '',
      format: InternalFileType.PDF,
      buttonLabel: 'Fill Form'
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: 'How do I get my 1098-T form online?',
          answer: [
            "Colleges can access a blank 1098-T form online through PDF Guru. Simply select 'GET FORM' to start filling out the form for each student.",
            'Students can obtain their 1098-T form online directly from their educational institution. Most colleges and universities provide these forms through their student portals or financial services websites.'
          ]
        },
        {
          question: 'How does a 1098-T affect my taxes?',
          answer: [
            "For educational institutions, the 1098-T form itself doesn't affect your taxes, but it's crucial for students. It provides them with the information needed to claim education tax credits, which can affect their tax liabilities."
          ]
        },
        {
          question: 'Where to put 1098-T on tax return 1040?',
          answer: [
            "If you're a student, you should use the information from the 1098-T form to fill out your 1040 tax return. Specifically, you'll include it in the sections related to education credits and deductions."
          ]
        },
        {
          question: 'When do 1098-T forms come out?',
          answer: [
            'Educational institutions are required to issue 1098-T forms by January 31st of each year. This deadline ensures students have the information in time for their tax filing.'
          ]
        },
        {
          question: 'Is filing form 1098-T difficult?',
          answer: [
            'Not at all! PDF Guru makes filing your 1098-T super easy. Our platform simplifies the process, offering tools for accurate and efficient form completion.'
          ]
        }
      ]
    },

    fag: {
      question: 'How to fill out the 1098-T form?',
      answer:
        "1. Open PDF Guru and select the blank 1098-T form. 2. Enter the student's name, address, and identification number. 3. Fill in your institution’s name, address, and federal ID number. 4. In Box 1, input total payments received for qualified tuition and related expenses. 5. Report any scholarships or grants in Box 5. 6. Include adjustments from prior years in Boxes 4 and 6. 7. Fill in Boxes 7, 8, and 9 for academic period, student status, and level of study. 8. Thoroughly review the 1098-T form and make any necessary corrections. 9. Save the completed form and distribute it to the student; file with the IRS as required."
    }
  };
};
