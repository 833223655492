import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { toggleModal } from 'data/actions/modals';

export const MaxSizeFileModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className='fixed inset-0 z-[9999] flex items-center justify-center bg-black/50'>
      <div className='relative w-full max-w-md rounded-xl bg-white p-6 shadow-2xl'>
        <div className='mb-4 text-center'>
          <div className='mx-auto mb-4 flex h-12 w-12 items-center justify-center rounded-full bg-red-100'>
            <svg
              className='h-6 w-6 text-red-600'
              fill='none'
              stroke='currentColor'
              viewBox='0 0 24 24'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
              />
            </svg>
          </div>
          <p className='text-sm text-gray-500'>{t('ai_summarizer.errors.error_size_limit')}</p>
        </div>
        <div className='flex justify-center'>
          <button
            onClick={() => dispatch(toggleModal({ visible: false }))}
            className='rounded-lg bg-red-600 px-4 py-2 text-sm font-medium text-white transition-colors hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2'
          >
            {t('ai_summarizer.close')}
          </button>
        </div>
      </div>
    </div>
  );
};
