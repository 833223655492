import type { AnalyticsEvent } from 'services/analytics/events';
import type { AnalyticsService, AnalyticsUserProp } from 'services/analytics/index';
import { Analytics } from 'services/analytics/index';

import { getDeviceType } from 'utils/getDeviceType';
import { Logger } from 'utils/logger';

export class PixelAnalytics implements AnalyticsService {
  private initialized = false;
  private eventBuffer: AnalyticsEvent[] = [];
  private readonly logger = new Logger('Pixel');

  public init() {
    const interval = setInterval(() => {
      if (window?.fbq === undefined) {
        return;
      }

      this.initialized = true;
      this.logger.log('Init');
      this.processBuffer();

      clearInterval(interval);
    }, 50);
  }

  public track(event: AnalyticsEvent) {
    if (!this.initialized) {
      this.eventBuffer.push(event);
      return;
    }

    this.sendEvent(event);
  }

  public updateUser(_: AnalyticsUserProp | AnalyticsUserProp[]) {
    // do nothing
  }

  public setUserId(_: string): void {
    // do nothing
  }

  private processBuffer() {
    this.eventBuffer.map((event) => this.sendEvent(event));
    this.eventBuffer = [];
  }

  private sendEvent(event: AnalyticsEvent | any): void {
    const page = Analytics.getCurrentPageName();
    const localPage = Analytics.getCurrentPageLocale();
    const deviceType = getDeviceType();
    let props = {
      page,
      local_page: localPage,
      device: deviceType
    };

    if ('data' in event && event.data) {
      props = { ...props, ...event.data };
    }

    if (event.event === 'sale_confirmation_success') {
      window?.fbq('track', 'Purchase', {
        value: event.data.value,
        price: event.data.price,
        currency: 'USD'
      });
    } else {
      window?.fbq('trackCustom', event.event, props);
    }
    this.logger.log('Send event', {
      name: event.event,
      data: JSON.stringify(props)
    });
  }
}

export const FBPixel = new PixelAnalytics();
