import form1099Misc from 'assets/forms/1099-misc.pdf';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';
import bgImgMob from 'assets/img/forms/background-1099-misc-mob.webp';
//import bgImgTab from 'assets/img/forms/background-1099-nec-tab.webp';
import bgImgWeb from 'assets/img/forms/background-1099-misc-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';

import type { IFormService } from 'ts/interfaces/services/service';
import { EServiceType } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export const form1099MiscService = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    pagesCount: 6,
    path: '/form-1099-misc',
    filePath: form1099Misc,
    fileName: '1099-misc.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_1099misc.title'),
      description: t('meta.form_1099misc.description')
    },
    getForm: {
      headline1: 'IRS',
      headline2: 'Form',
      formName: '1099-MISC',
      subHeadline: '2024-2025 Printable Template',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM'
    },
    easilyComplete: {
      headline: 'How to fill out a 1099 MISC Form',
      description: [
        {
          icon: icon_1,
          iconMob: icon_mob_1,
          title: 'Add your info',
          text: 'Use our PDF filler to input your personal details, contacts, and other required information.'
        },
        {
          icon: icon_2,
          iconMob: icon_mob_2,
          title: 'Save or print out the form',
          text: 'Save or print the 1099-MISC Form 2023 using our tool from any device.'
        },
        {
          icon: icon_3,
          iconMob: icon_mob_3,
          title: 'Submit your tax form',
          text: 'Submit your tax form. Choose whether to send the form by regular post or file it electronically.'
        }
      ]
    },
    whatIsForm: {
      headline: 'What is a 1099-MISC Form?',
      text_1: [],
      text_2: [],
      text_3: [],
      text_4: [],
      title_1: 'What is a 1099-MISC Form used for?',
      title_2: '',
      subTitle:
        "1099-MISC Form (2023 version) must be filled out when you have to report income that's been earned outside a standard salary or wage. The most common use we have of this is to show payments to non-employees such as independent contractors or freelancers. However, it can be used to report a variety of income types, such as the following:",
      sectionImage: section_img,
      sectionImageTab: section_img_tab,
      description: [
        `A 1099-MISC Form is an important document used by the IRS to report on specific types of non-employment income. The 1099 MISC Form is generally used to record specific income, like royalties, rents, prizes, and other expenses like medical or attorney payments. For example, landlords might issue a Form 1099-MISC to report rent they receive in certain business-related rental situations. This shows the total rent they've paid during the tax year, along with the associated taxable amount.`
      ],
      bullets: [],
      orangeListSmall: [
        'At least $10 in royalties or broker payments in lieu of dividends or tax-exempt interest.',
        'At least $5,000 for consumer products you sold anywhere other than a permanent retail establishment.'
      ],
      title_list: 'Alternatively, if you earned at least $600 from:',
      orangeList: [
        {
          text: 'Rental payments (e.g., real estate agents and property managers reporting rent to property owners or reporting office space rent paid)'
        },
        {
          text: 'Prizes and awards'
        },
        {
          text: 'Medical and healthcare payments within your business activities'
        },
        {
          text: 'Proceeds from crop insurance'
        },
        {
          text: 'Cash payments for fish or aquatic life acquired from fishermen'
        },
        {
          text: 'Cash payments from specific contracts to individuals, partnerships, or estates'
        },
        {
          text: 'Payments made to attorneys'
        },
        {
          text: 'Other miscellaneous income payments'
        }
      ]
    },
    blockWithBlueTitle1: {
      title: 'Who must file a 1099-MISC?',
      description: [
        'Anyone involved in a business or trade receiving payment from another business entity will have these payments reported to the IRS. But only if they are over a certain amount during the course of the tax year. More precisely, any person or organization that pays another person or business $600 or more in a calendar year is obliged to send out a 1099 MISC Form.',
        "Such payments must be reported to the IRS (Internal Revenue Service) and to the income recipient. So if you receive a 1099-MISC form, make sure to include this income on your tax returns, as you're liable to pay any taxes due on this sum. However, all payments made to employees, including income, insurance payments, retirement contributions, and travel expenses, are to be included on Form W-2.",
        'The 1099 MISC Form 2023 comes in several parts. All the parts in black should be completed, while Copy A (in red) is for IRS use only. You must include your name, address, and tax number, as well as the recipient’s name, address, and Social Security number. You’ll find that using PDF Guru makes the whole process quick, easy, and precise.'
      ],
      orangeList: [
        {
          strong: '',
          text: 'Copy 1 - to the recipient’s state tax department'
        },
        {
          strong: '',
          text: 'Copy B - to the recipient'
        },
        {
          strong: '',
          text: 'Copy 2 - to the recipient for their state tax return'
        },
        {
          strong: '',
          text: 'Copy C - retained by the payer for their records'
        }
      ]
    },
    howToFill: {
      headline: 'How to File a 1099-MISC?',
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          strong: '',
          text: 'Click on the Form Fill button below'
        },
        {
          icon: blue_number_icon_2,
          strong: '',
          text: 'Complete the tax form 1099 MISC in the editor window'
        },
        {
          icon: blue_number_icon_3,
          strong: '',
          text: 'Use the electronic signature to sign and date the PDF'
        },
        {
          icon: blue_number_icon_4,
          strong: '',
          text: 'All copies can be sent in 1099-MISC printable form via regular mail or electronically using the IRS’s FIRE system'
        }
      ]
    },
    fillFormFree: {
      headline: 'Fastest Way to Fill 1099-MISC',
      subtitle: '',
      buttonLabel: 'Fill Form'
    },
    bannerQuickNEasy: {
      headline: 'File Your Taxes Easily With PDF Guru',
      subtitle: 'Lightning Fast 1099-MISC Form Filling',
      format: InternalFileType.PDF,
      buttonLabel: 'Fill Form'
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: 'What is the deadline for Form 1099-MISC?',
          answer: [
            'The deadline for sending the 1099-MISC Form 2023 to recipients is January 31, 2024. For those filing papers, reporting the 1099 to the IRS should be completed by February 28, 2024. However, if you’re sending the form electronically, the IRS Form 1099 eFile due date is extended to March 31, 2024.'
          ]
        },
        {
          question: 'Where do you send 1099-MISC forms?',
          answer: [
            'Within the instructions for Form 1099-MISC 2023, you’ll see that the 1099 MISC printable form Copy A is sent to the IRS, with Copy B and Copy 2 sent to recipients. Depending on your state, there may be state filing requirements.'
          ]
        },
        {
          question: 'How much taxes do you pay on 1099-MISC Forms?',
          answer: [
            'Just because you receive a 1099 MISC Form doesn’t necessarily mean you have taxes to pay. It might be possible that you have greater deductions than your income. Simply follow the 1099 MISC Form instructions and the IRS will let you know your tax obligations in due course.'
          ]
        },
        {
          question: 'What is the Difference Between 1099-MISC and 1099-NEC?',
          answer: [
            "The main distinction between the 1099-MISC and 1099-NEC forms lies in the kind of income they're designed to report. In 2020 there was a big change when the IRS reintroduced the 1099-NEC form. This was a means to separate out non-employee compensation. Previously this income was reported in Box 7 of the 1099-MISC. Now, non-employee compensation goes on the 1099-NEC, while the 1099 MISC Form 2023 is used for other types of miscellaneous income. Traditionally, you would use the 1099 MISC Form to report various types of miscellaneous income to the IRS. This includes things like rents, royalties, prizes, and other payments made to individuals or entities who aren't employees. On the other hand, the 1099-NEC form is for reporting non-employee compensation. It's meant for payments of $600 or more that are made to independent contractors, freelancers, or self-employed persons."
          ]
        }
      ]
    },
    fag: {
      question: 'How to File a 1099-MISC?',
      answer:
        '1. Click on the Form Fill button below. 2. Complete the tax form 1099 MISC in the editor window 3. Submit your tax form. Choose whether to send the form by regular post or file it electronically'
    }
  };
};
