import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const getLocalizedHref = (preparedHref: string, language: string) => {
  if (preparedHref.includes('/app/v1')) {
    return `/app/v1/${language}${preparedHref?.replace('/app/v1', '')}`;
  } else if (preparedHref.includes('/app')) {
    return `/app/${language}${preparedHref?.replace('/app', '')}`;
  }
  return `/${language}${preparedHref}`;
};

const LocalizedLink = ({ children, href, ...rest }: any) => {
  const { i18n } = useTranslation();

  // not localized links
  if (href.includes('http') || href.startsWith('mailto') || href.startsWith('#')) {
    return (
      <>
        <Link
          {...rest}
          to={href}
          onClick={(e) => {
            // TODO: remove this after migration to new site version
            e.preventDefault();
            e.stopPropagation();
            window.location.href = href;
          }}
        >
          {children}
        </Link>
      </>
    );
  }

  const preparedHref = href.startsWith('/') ? href : `/${href}`;
  const withLocale = i18n.language === 'en' ? preparedHref : getLocalizedHref(preparedHref, i18n.language);

  if (preparedHref.includes('form')) {
    return (
      <>
        <Link
          {...rest}
          to={withLocale}
        >
          {children}
        </Link>
      </>
    );
  }

  return (
    <>
      <Link
        {...rest}
        to={withLocale}
      >
        {children}
      </Link>
    </>
  );
};

export default LocalizedLink;
