import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import classNames from 'classnames';
import { calculateProgress, useAnimatedProgress } from 'hooks/useAnimatedProgress';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';

import { Analytics } from 'services/analytics';
import { FileUploadingModalViewTypes } from 'services/analytics/events';

import { updateDownloadProgress } from 'data/actions/documents';
import { uploadProgressSelector } from 'data/selectors/documents';
import { modalOptionsSelector } from 'data/selectors/modals';

import ModalLayout from '../baseModal/base';
import { ProgressBar } from './components/progress-bar';

interface IProgressModifyFileModalProps {
  type: 'compress' | 'merge' | 'split';
}

const ProgressModifyFileModal: FC<IProgressModifyFileModalProps> = ({ type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const downloadProgress = useSelector(uploadProgressSelector());
  const animatedProgress = useAnimatedProgress(5);
  const progressValue = calculateProgress(animatedProgress, 0, 90);
  const [barValue, setBarValue] = useState(0);
  const options = useSelector(modalOptionsSelector);

  useCloseModalOnBack({ onModalClose: options?.onUserClose });

  const eventsMap = useMemo(
    () =>
      new Map([
        [10, 'file_uploading_10'],
        [35, 'file_uploading_35'],
        [70, 'file_uploading_70'],
        [100, 'file_uploading_100']
      ]),
    []
  );

  const textLabel = useMemo(() => {
    if (type === 'compress') return t('popups.processing_modify_pdf_popup.compress');
    if (type === 'merge') return t('popups.processing_modify_pdf_popup.merge');
    if (type === 'split') return t('popups.processing_modify_pdf_popup.split');
  }, [type, t]);

  const dataTestId = useMemo(() => {
    if (type === 'compress') return 'compressing-file-modal';
    if (type === 'merge') return 'merging-file-modal';
    if (type === 'split') return;
  }, [type]);

  useEffect(() => {
    if (progressValue > downloadProgress) {
      dispatch(updateDownloadProgress(progressValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, progressValue]);

  useEffect(() => {
    return () => {
      dispatch(updateDownloadProgress(0));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const newBarValue = downloadProgress.toFixed(0);

    if (newBarValue < 20) setBarValue(10);
    else if (newBarValue < 35) setBarValue(20);
    else if (newBarValue < 50) setBarValue(35);
    else if (newBarValue < 70) setBarValue(50);
    else if (newBarValue < 80) setBarValue(70);
    else if (newBarValue <= 90) setBarValue(93);
    else setBarValue(100);
  }, [downloadProgress]);

  useEffect(() => {
    Analytics.sendEvent({
      event: 'file_uploading_modal_view',
      data: {
        type: FileUploadingModalViewTypes.otherFunnels,
        screen_config_name: 'advanced_loaders_animations'
      }
    });
  }, []);

  useEffect(() => {
    const event = eventsMap.get(barValue);
    if (event) {
      Analytics.sendEvent({ event });
    }
  }, [barValue, eventsMap]);

  return (
    <ModalLayout dataTestId={dataTestId}>
      <div className='flex flex-col items-center tablet:w-[328px] w-full min-w-[calc(100%_-_32px)]'>
        <div className='flex w-full items-center justify-center h-[170px] bg-[#5D2EE1]'>
          <DotLottieReact
            src='https://lottie.host/aeda5eb3-aa34-45f9-8cf6-7bd01593f949/NK74yF7BAk.lottie'
            loop
            autoplay
          />
        </div>
        <div className='flex flex-col gap-3 w-full px-8 py-6'>
          <div
            className={classNames('flex flex-col font-bold text-center text-[25px] leading-[32.5px] text-[#1D1D1D]')}
          >
            {textLabel}
          </div>
          <div className='flex flex-col gap-3 items-center w-full'>
            <ProgressBar
              className='mt-0'
              progress={barValue}
            />
            <div className='font-bold text-[16px] text-[#1D1D1D]'>{barValue}%</div>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ProgressModifyFileModal;
