import { Fragment } from 'react';

import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';

import deleteIcon from 'assets/img/merge/delete.svg';
import duplicateIcon from 'assets/img/merge/duplicate.svg';
import previewIcon from 'assets/img/merge/preview.svg';
import rotateIcon from 'assets/img/merge/rotate.svg';
import classNames from 'classnames';
import { mergeImagesPageService } from 'helpers/services/mergeImageServiceList';
import useFileUploadAndMerge from 'hooks/fileFlows/useFileUploadAndMerge';
import { v4 as uuidv4 } from 'uuid';

import { sendAnalyticEvent } from 'data/actions/analytics';
import { pushMergeDocument } from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import { mergeDocumentsListSelector } from 'data/selectors/documents';

import { rotatePdfPages } from 'utils/rotatePdf';

import { EModalTypes } from 'ts/enums/modal.types';
import type { IMergeDocument } from 'ts/interfaces/documents/document';

import { MAX_FILES_ALLOWED } from '..';

interface IFileActionsProps {
  file: File;
  thumbnail?: string | null;
  index: number;
}

const FileActions = ({ file, thumbnail, index }: IFileActionsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSetMergeFilesList } = useFileUploadAndMerge({
    service: mergeImagesPageService(t)
  });
  const filesList: IMergeDocument[] = useSelector(mergeDocumentsListSelector());

  const deleteFile = () => {
    dispatch(sendAnalyticEvent({ event: 'merge_page_action', data: { action_type: 'delete' } }));
    const updatedFiles = filesList.filter((item, i) => i !== index);
    handleSetMergeFilesList(updatedFiles);
  };

  const duplicateFile = () => {
    dispatch(sendAnalyticEvent({ event: 'merge_page_action', data: { action_type: 'duplicate' } }));
    dispatch(pushMergeDocument({ file, thumbnail, hashId: uuidv4() }, { index: index + 1, ignoreEvent: true }));
  };

  const rotateFile = async () => {
    dispatch(sendAnalyticEvent({ event: 'merge_page_action', data: { action_type: 'rotate' } }));
    return rotatePdfPages(file).then((newFile) => {
      const updatedFiles = filesList.map((item, i) =>
        i === index ? { ...item, file: newFile, thumbnail: null } : item
      );
      handleSetMergeFilesList(updatedFiles);
    });
  };

  const openPreview = () => {
    dispatch(sendAnalyticEvent({ event: 'merge_page_action', data: { action_type: 'preview' } }));
    dispatch(
      toggleModal({
        visible: true,
        type: EModalTypes.MERGE_PDF_FILE_PREVIEW_MODAL,
        options: { file, pageMaxWidth: 522 }
      })
    );
  };

  const actionsList = [
    {
      icon: duplicateIcon,
      onClick: duplicateFile,
      label: t('complete_merge.actions.duplicate'),
      disabled: filesList && filesList?.length >= MAX_FILES_ALLOWED,
      dataTestId: `duplicate-file-button-${index}`
    },
    {
      icon: previewIcon,
      onClick: openPreview,
      label: t('complete_merge.actions.preview'),
      dataTestId: `preview-file-button-${index}`
    },
    {
      icon: rotateIcon,
      onClick: rotateFile,
      label: t('complete_merge.actions.rotate'),
      dataTestId: `rotate-file-button-${index}`
    },
    {
      icon: deleteIcon,
      onClick: deleteFile,
      label: t('complete_merge.actions.delete'),
      dataTestId: `delete-file-button-${index}`
    }
  ];

  return (
    <div className='group-hover:opacity-100 group-active:opacity-0 opacity-0 absolute top-[-6px] right-[-12px] bg-[#FFF] rounded-md z-20 flex overflow-hidden'>
      {actionsList.map((action, index) => (
        <Fragment key={index}>
          <button
            onClick={action.onClick}
            className={classNames(
              'p-[7px] hover:bg-[#d9d8d8]',
              action.disabled && 'opacity-50 cursor-not-allowed pointer-events-none'
            )}
            aria-label={action.label}
            data-testid={action.dataTestId}
          >
            <LazyLoadImage
              src={action.icon}
              alt={action.label}
              width={11}
              height={11}
            />
          </button>
          {index !== actionsList.length - 1 && (
            <div className='my-[2px] h-[23px] w-[1px] self-stretch bg-[#C7C7C7]'></div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default FileActions;
