import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import type { SupportedLanguage } from '../../i18n';
import { languages } from '../../i18n';

export const LocaleLoadingProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation();
  const [isLangReady, setIsLangReady] = useState(false);

  useEffect(() => {
    const path = window.location.pathname;
    const pathLang = path.split('/').find((segment) => languages.includes(segment as SupportedLanguage));

    if (pathLang) {
      i18n.changeLanguage(pathLang).then(() => {
        setIsLangReady(true);
      });
    } else {
      setIsLangReady(true);
    }
  }, [i18n]);

  //can add lodaer
  if (!isLangReady) {
    return null;
  }

  return <>{children}</>;
};
