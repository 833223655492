import type { FC } from 'react';

import type { MainColors } from 'ts/constants/general';
import type { InternalFileType } from 'ts/types/file';

export enum EServiceType {
  EDITOR = 'EDITOR',
  CONVERTOR = 'CONVERTOR',
  COMPRESSOR = 'COMPRESSOR',
  PDF_CONVERTOR = 'PDF_CONVERTOR',
  SPLITTER = 'SPLITTER',
  MERGER = 'MERGER',
  OCR = 'OCR',
  TRANSLATE = 'TRANSLATE',
  AI_SUMMARIZER = 'AI_SUMMARIZER'
}

export interface IService {
  metaTitle?: string;
  serviceType: EServiceType;
  metaDescription?: string;
  isMergeImages?: boolean;
  nameKey: string;
  path: string;
  name: string;
  title: string;
  description: string;
  icon?: string;
  from: string;
  to: string;
  availableFormats: string;
  uploadSectionImage?: string;
  aboutSection?: {
    title: string;
    items: string[];
  };
  ctaTitle?: string;
  meta?: {
    title?: string;
    description?: string;
  };
  heroFeatures?: {
    title: string;
    description?: string;
  }[];
  mainColor?: MainColors;
  uploadSection?: {
    from: InternalFileType;
    to: InternalFileType;
    headline: string;
    subtitle_1: string;
  };
  banner?: {
    headline: string;
    subtitle: string;
    format: InternalFileType;
    buttonLabel: string;
  };
  howTo: {
    headline_1: string;
    img: string;
    description: {
      icon: string;
      text: string;
    }[];
  };
  fag: {
    question: string;
    answer: string;
  };
  pageLink?: string;
  bannerQuickNEasy?: {
    headline: string;
    subtitle: string;
    format: InternalFileType;
    buttonLabel: string;
  };
}

export interface IFormService {
  serviceType: EServiceType;
  pagesCount: number;
  path: string;
  filePath: string;
  fileName: string;
  from: string;
  to: string;
  meta: {
    title: string;
    description: string;
  };
  getForm: {
    headline1: string;
    headline2: string;
    formName: string;
    subHeadline: string;
    backgroundImageWeb: string;
    backgroundImageTab: string;
    backgroundImageMob: string;
    buttonLabel: string;
  };
  easilyComplete: {
    headline: string;
    description: {
      icon: string;
      iconMob: string;
      title: string;
      text: string;
    }[];
  };
  whatIsForm: {
    headline: string;
    text_1: string[];
    text_2: string[];
    text_3: string[];
    text_4: string[];
    text_5?: string[];
    title_1: string;
    title_2: string;
    title_3?: string;
    subTitle: string;
    sectionImage: string;
    sectionImageTab: string;
    description?: string[];
    description2?: FC;
    bullets: {
      strong?: string;
      text: string;
    }[];
    title_list?: string;
    orangeList?: {
      strong?: string;
      text: string;
      isColumn?: boolean;
    }[];
    redList?: {
      strong?: string;
      text: string;
      isColumn?: boolean;
    }[];
    orangeListSmall?: string[];
  };
  blockWithBlueTitle1?: {
    title: string;
    description: string[];
    title_list?: {
      strong?: string;
      text: string;
    };
    title_list2?: {
      strong?: string;
      text: string;
    };
    orangeList: {
      strong?: string;
      isColumn?: boolean;
      text: string;
    }[];
    textAfterList?: string[];
  };
  blockWithBlueTitle2?: {
    title: string;
    description: string[];
    title_list?: {
      strong?: string;
      text: string;
    };
    title_list2?: {
      strong?: string;
      text: string;
    };
    orangeList: {
      strong?: string;
      isColumn?: boolean;
      text: string;
    }[];
    orangeList2?: {
      strong?: string;
      isColumn?: boolean;
      text: string;
    }[];
    textAfterList?: string[];
  };
  howToFill: {
    headline: string;
    sectionImage: string;
    bullets: {
      icon: string;
      strong?: string;
      text: string;
    }[];
  };
  fillFormFree: {
    headline: string;
    subtitle: string;
    buttonLabel: string;
  };
  faqSection: {
    headline: string;
    faqSectionDescription: {
      question: string;
      answer: string[];
      answer2?: FC;
    }[];
  };
  fag: {
    question: string;
    answer: string;
  };
  bannerQuickNEasy?: {
    headline: string;
    subtitle: string;
    format: InternalFileType;
    buttonLabel: string;
  };
  differenceBetween2?: {
    headline: string;
    text: string | string[];
  };
  differenceBetween1?: {
    headline: string;
    text: string;
  };
  differenceBetween?: {
    headline: string;
    text: string;
  };
  nextSteps?: {
    headline: string;
    backgroundNextStepsWeb: string;
    backgroundNextStepsTab: string;
    backgroundNextStepsMob: string;
    texts: string[];
  };
  howTo?: {
    headline: string;
    sectionImage: string;
    bullets: {
      icon: string;
      text: string;
    }[];
  };
  fag1?: {
    question: string;
    answer: string;
  };
  is1099Form?: boolean;
}
