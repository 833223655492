import { createSelector } from 'reselect';

const baseModalsSelector = (state: any) => state?.modals;

export const modalsSelector = createSelector(baseModalsSelector, (modals) => modals);

export const modalOptionsSelector = createSelector(baseModalsSelector, (modals) => modals?.options || {});

export const modalTypeSelector = createSelector(baseModalsSelector, (modals) => modals?.type || '');

export const modalFromEventSelector = createSelector(baseModalsSelector, (modals) => modals?.options?.from_event || '');
