import { EReduxTypes } from 'ts/enums/redux.types';
import type { IReduxAction } from 'ts/interfaces/redux/redux.action';

export interface IChatHistoryItem {
  id: string;
  chatName: string;
  updated_at: string;
  fileId?: string;
  file_url?: string;
  file_name?: string;
  openai_thread_id?: string;
  status?: 'READY' | 'WAITING';
}

export interface ICurrentThreadAssistantMessage {
  id: string;
  role: 'assistant';
  message: string;
  threadId: string;
  suggestedQuestions?: string[];
}

interface ICurrentThreadUserMessage {
  id: string;
  role: 'user';
  threadId: string | null;
  message: string;
}

export interface INewFileData {
  key: string;
  filename: string;
  size: number;
  pagesCount: number;
}

export type ICurrentThreadData = ICurrentThreadAssistantMessage | ICurrentThreadUserMessage;

export interface IAiSummarizerState {
  chatsHistory: IChatHistoryItem[];
  fileId: string | null;
  currentThreadId: string | null;
  currentThreadData: ICurrentThreadData[];
  currentFileUrl: string | null;
  newFileData: INewFileData | null;
  fileName: string | null;
  isMobileMenuOpen: boolean;
  freeLimitReached: boolean;
}

const initialState: IAiSummarizerState = {
  chatsHistory: [],
  fileId: null,
  currentThreadId: null,
  currentThreadData: [],
  currentFileUrl: null,
  newFileData: null,
  fileName: null,
  isMobileMenuOpen: false,
  freeLimitReached: false
};

const aiSummarizer = (state = initialState, action: IReduxAction): IAiSummarizerState => {
  switch (action.type) {
    case EReduxTypes.SET_CHATS_HISTORY:
      return {
        ...state,
        chatsHistory: action.data.chatsHistory
      };

    case EReduxTypes.SET_FILE_ID:
      return {
        ...state,
        fileId: action.data.fileId
      };

    case EReduxTypes.SET_CURRENT_THREAD_ID:
      return {
        ...state,
        currentThreadId: action.data.currentThreadId
      };

    case EReduxTypes.SET_CURRENT_THREAD_ASSISTANT_MESSAGE:
      console.log('REDUCER ASISSTANT MESSAGE ===>', action.data.currentThreadData);

      return {
        ...state,
        currentThreadData: [
          ...state.currentThreadData,
          {
            id: action.data.currentThreadData.id,
            threadId: action.data.currentThreadData.threadId,
            role: action.data.currentThreadData.role,
            message: action.data.currentThreadData.message
          }
        ]
      };

    case EReduxTypes.SET_CURRENT_THREAD_USER_MESSAGE:
      console.log('SET_CURRENT_THREAD_USER_MESSAGE received:', JSON.stringify(action));

      console.log('Extracted user message:', action.data.message);

      return {
        ...state,
        currentThreadData: [
          ...state.currentThreadData,
          {
            id: `id-${Math.random().toString(36).substr(2, 9)}`,
            role: 'user',
            threadId: state.currentThreadId || null,
            message: action.data.message
          }
        ]
      };

    case EReduxTypes.REMOVE_CURRENT_THREAD_DATA:
      console.log('REMOVE_CURRENT_THREAD_DATA received in reducer with state:', state);
      console.log('Returning state with empty currentThreadData');

      return {
        ...state,
        currentThreadData: []
      };

    case EReduxTypes.SET_CURRENT_FILE_URL:
      return {
        ...state,
        currentFileUrl: action.data.currentFileUrl
      };

    case EReduxTypes.SET_NEW_FILE_DATA:
      return {
        ...state,
        newFileData: action.data.newFileData
      };

    case EReduxTypes.SET_FILE_NAME:
      return {
        ...state,
        fileName: action.data.fileName
      };

    case EReduxTypes.SET_MOBILE_MENU_OPEN:
      return {
        ...state,
        isMobileMenuOpen: action.data.isMobileMenuOpen
      };

    case EReduxTypes.SET_FREE_LIMIT_REACHED:
      return {
        ...state,
        freeLimitReached: action.data.freeLimitReached
      };

    default:
      return state;
  }
};

export default aiSummarizer;
