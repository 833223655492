import type { ECompressionLevel } from 'ts/enums/compressLevel';
import type { FileType } from 'ts/enums/internal.file';

import type { ApiFile } from '../../types/file';

export interface IDocument {
  id: string;
  filename: string;
  internal_type: string;
  created_at: string;
  size: string;
  aws_url: string;
  processing_status: string;
}

export interface ICompressDocumentDto {
  filename: string;
  key: string;
  size: number;
  compressLevel: ECompressionLevel;
  pagesCount?: number;
  exportFilesCount?: number;
  to?: string;
  from?: string;
  serviceType?: string;
}

export interface IMergeDocumentDto {
  filename: string;
  key: string;
  size: number;
  pagesCount?: number;
  orderIndex: number;
}

export interface ISplitDocumentDto {
  filename: string;
  key: string;
  size: number;
  pagesCount?: number;
  onSuccess?: unknown;
  onFailed?: unknown;
}

export interface IConvertDocumentDto {
  filename: string;
  key: string | null;
  size: number;
  from: string;
  to: string;
  pagesCount?: number;
  url?: string;
  serviceType?: string;
  fileList?: any[];
  documentId?: string;
  fileBase64?: string;
  pages_counter?: number;
}

export interface ITranslateDocumentDto {
  filename: string;
  key: string | null;
  size: number;
  fromLang: string;
  toLang: string;
  pagesCount?: number;
  url?: string;
  serviceType?: string;
  fileList?: any[];
  documentId?: string;
  fileBase64?: string;
}

export interface IEditDocumentDto {
  filename: string;
  key: string | null;
  size: number;
  pagesCount?: number;
  documentId?: string;
  url?: string;
  serviceType?: string;
  from?: string;
  fileBase64?: string;
  pages_counter?: number;
}

export interface IOCRDocumentDto {
  size: number;
  filename: string;
  key: string;
  exportFormat: SupportedOcrExportFormat | string;
  url?: string;
  documentId?: string;
  serviceType?: string;
  to: FileType;
  from?: string;
  pages_counter?: number;
}

export enum OcrExportFormatEnum {
  'txt' = 'txt',
  'txtUnstructured' = 'txtUnstructured',
  'rtf' = 'rtf',
  'docx' = 'docx',
  'xlsx' = 'xlsx',
  'pptx' = 'pptx',
  'pdfSearchable' = 'pdfSearchable',
  'pdfTextAndImages' = 'pdfTextAndImages',
  'pdfa' = 'pdfa'
}

export type SupportedOcrExportFormat =
  | OcrExportFormatEnum.docx
  | OcrExportFormatEnum.pdfSearchable
  | OcrExportFormatEnum.txt;

export interface IUploadLink {
  filename: string;
  url: string;
}

export interface IDocumentById {
  format: string;
  filename: string;
  url: string;
}

export interface IMergeDocument {
  file: File;
  thumbnail?: string | null;
  hashId: string;
  parentHashId?: string;
  pageNumber?: number;
}

export interface IDocumentsState {
  editDocumentsList: any;
  documents: {
    data: any[];
    meta: any;
  };
  uploadLinks: IUploadLink[] | null;
  uploadedFile: any;
  downloadProgress: number;
  convertDocumentData: IConvertDocumentDto | null;
  pdfFileContent: any | ArrayBuffer;
  documentById: null | IDocumentById;
  fileUpload: any;
  editFilename: string;
  mergeDocumentsList: IMergeDocument[];
  sendNotification: boolean;
  editDocumentData: null | IEditDocumentDto;
  selectedFile: ApiFile | null;
  selectedFiles: string[];
  editMultipleDocumentsSendNotification: boolean;
}
