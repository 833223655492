import form4506t from 'assets/forms/4506-t.pdf';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';
import blue_number_icon_5 from 'assets/img/forms/5-step-blue-icon.svg';
import blue_number_icon_6 from 'assets/img/forms/6-step-blue-icon.svg';
import blue_number_icon_7 from 'assets/img/forms/7-step-blue-icon.svg';
import bgImgMob from 'assets/img/forms/background-4506-t-mob.webp';
//import bgImgTab from 'assets/img/forms/background-941-tab.webp';
import bgImgWeb from 'assets/img/forms/background-4506-t-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';

import type { IFormService } from 'ts/interfaces/services/service';
import { EServiceType } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export const form4506tService = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    pagesCount: 6,
    path: '/form-4506-t',
    filePath: form4506t,
    fileName: '4506-t.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_4506t.title'),
      description: t('meta.form_4506t.description')
    },
    getForm: {
      headline1: 'IRS',
      headline2: 'Form',
      formName: '4506-T',
      subHeadline: '2021-2024 Printable Template',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM'
    },
    easilyComplete: {
      headline: 'How it works',
      description: [
        {
          icon: icon_1,
          iconMob: icon_mob_1,
          title: 'Fill form',
          text: 'Start on PDF Guru and access our intuitive editor tool to open and fill the blank Form 4506-T.'
        },
        {
          icon: icon_2,
          iconMob: icon_mob_2,
          title: 'Enter your information',
          text: 'Fill in your personal details, tax year, and any other required information.'
        },
        {
          icon: icon_3,
          iconMob: icon_mob_3,
          title: 'Download for submission',
          text: 'Review the completed form, download it for your records, and follow IRS submission guidelines for official filing.'
        }
      ]
    },
    whatIsForm: {
      headline: 'What is a Form 4506-T?',
      text_1: [],
      text_2: [
        'Form 4506-T can also be used to designate a third party to receive the tax information, which can be beneficial in situations where a financial advisor or legal representative is managing your tax affairs.'
      ],
      text_3: [
        'Form 4506-T is necessary for individuals and entities that need to provide proof of their tax return information. This includes:'
      ],
      text_4: [],
      title_1: 'What is the Form 4506-T used for?',
      title_2: 'Who needs a Form 4506-T?',
      subTitle:
        'The primary purpose of Form 4506-T is to request tax transcripts, which are summaries of tax return information. These transcripts are often required by mortgage lenders, financial institutions, and legal entities to verify income and tax compliance. Additionally, individuals may use this form to verify past income for personal reasons, such as preparing for future tax filings or rectifying past tax issues.',
      sectionImage: section_img,
      sectionImageTab: section_img_tab,
      description: [
        'Form 4506-T, officially titled "Request for Transcript of Tax Return," is a crucial document used by individuals and businesses to request tax return information from the IRS. This form allows you to access previous tax returns, W-2 information, and other tax-related documents. It\'s particularly useful for situations requiring proof of income, such as applying for loans or financial assistance programs.',
        'The form itself is quite easy to use but requires careful attention to detail to ensure the IRS provides the correct tax information. Filling it out correctly is essential to prevent delays or issues in retrieving your tax history.'
      ],
      bullets: [],
      redList: [
        { text: 'Loan applicants who must prove their income to lenders.' },
        { text: 'Students applying for financial aid who need to verify family income.' },
        { text: 'Individuals handling estate settlements or legal disputes involving income or tax details.' },
        { text: 'Businesses requiring past tax data for financial planning or audit purposes.' }
      ]
    },
    differenceBetween1: {
      headline: 'How to get a Form 4506-T',
      text: 'Getting your Form 4506-T is easy with PDF Guru. We provide a blank form for you to fill out directly on our platform. Just click "GET FORM," and you\'re ready to start. Once you have filled out the form, you can sign and download it. With PDF Guru, obtaining your Form 4506-T has never been easier.'
    },
    howToFill: {
      headline: 'How to fill out Form 4506-T',
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          strong: 'Personal Information:',
          text: " Fill in your name, Social Security Number (SSN), or Individual Taxpayer Identification Number (ITIN), and current address. If you filed a joint return, include your spouse's information."
        },
        {
          icon: blue_number_icon_2,
          strong: 'Previous Address:',
          text: ' If your current address is different from the one on your last tax return, provide the old address.'
        },
        {
          icon: blue_number_icon_3,
          strong: 'Customer File Number:',
          text: ' Optionally, assign a unique number to your request for easy tracking.'
        },
        {
          icon: blue_number_icon_4,
          strong: 'Type of Transcript and Tax Form Number:',
          text: ' Specify the type of transcript you need and the tax form number, such as Form 1040 for individual tax returns.'
        },
        {
          icon: blue_number_icon_5,
          strong: 'Year or Period Requested:',
          text: ' Enter the specific year or period for the tax transcript.'
        },
        {
          icon: blue_number_icon_6,
          strong: 'Signature and Date:',
          text: ' Sign and date the form to validate your request.'
        },
        {
          icon: blue_number_icon_7,
          strong: 'Submit the form:',
          text: ' Follow IRS guidelines for submitting the form, using the current contact details from the IRS website.'
        }
      ]
    },
    differenceBetween2: {
      headline: 'Can you submit Form 4506-T online?',
      text: "Form 4506-T can also be requested online through the IRS's 'Get Transcript' service. This is an alternative to mailing the form, offering quicker access to your tax transcript information."
    },
    differenceBetween: {
      headline: 'Can you mail Form 4506-T?',
      text: "Yes, you can mail Form 4506-T. Once you've filled out and signed the form, send it to the IRS at the appropriate address provided on the IRS website. Ensure all information is accurate to avoid processing delays."
    },
    fillFormFree: {
      headline: 'Simply Fill Out Form 4506-T Now',
      subtitle: 'Get started with PDF Guru',
      buttonLabel: 'Fill Form'
    },
    bannerQuickNEasy: {
      headline: 'Complete Form 4506-T with PDF Guru',
      subtitle: 'Streamline your tax transcript request',
      format: InternalFileType.PDF,
      buttonLabel: 'Fill Form'
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: 'How long does it take to process Form 4506-T?',
          answer: [
            "The processing time for Form 4506-T is typically 10 business days from the date the IRS receives the form. However, during peak periods, such as tax season, processing times may be longer. It's important to plan accordingly and submit your form early if you need the information by a specific date."
          ]
        },
        {
          question: 'Is there a fee for requesting transcripts using Form 4506-T?',
          answer: [
            'No, there is no fee for requesting tax transcripts using Form 4506-T. The IRS provides this service free of charge, making it an accessible option for individuals and businesses needing tax return information.'
          ]
        },
        {
          question: 'Can I request multiple years of tax information on one Form 4506-T?',
          answer: [
            'Yes, you can request multiple years of tax information on a single Form 4506-T. You can list up to four years or periods on the form. If you need more than four years, you will need to submit additional forms.'
          ]
        }
      ]
    },
    fag: {
      question: 'How to fill out Form 4506-t?',
      answer:
        "1. Personal Information: Fill in your name, Social Security Number (SSN), or Individual Taxpayer Identification Number (ITIN), and current address. If you filed a joint return, include your spouse's information. 2. Previous Address: If your current address is different from the one on your last tax return, provide the old address. 3. Customer File Number: Optionally, assign a unique number to your request for easy tracking. 4. Type of Transcript and Tax Form Number: Specify the type of transcript you need and the tax form number, such as Form 1040 for individual tax returns. 5. Year or Period Requested: Enter the specific year or period for the tax transcript. 6. Signature and Date: Sign and date the form to validate your request. 7. Submit the form: Follow IRS guidelines for submitting the form, using the current contact details from the IRS website."
    }
  };
};
