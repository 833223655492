import { useDispatch, useSelector } from 'react-redux';

import * as pdfjs from 'pdfjs-dist';
import { franc } from 'franc';
import { countPdfPages } from 'helpers/countPdfPages';
import { useLocaleNavigate } from 'hooks/useLocaleNavigate';
import cloneDeep from 'lodash/cloneDeep';

import { Analytics } from 'services/analytics';

import {
  getSupportedLanguages,
  setDocumentForTranslate,
  setPDFfileContent,
  setTranslateDocumentData,
  updateDownloadProgress
} from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import { userEmailSelector } from 'data/selectors/user';

import { blobToBase64 } from 'utils/blobToBase64';
import { formatFileSize } from 'utils/formatFileSize';

import { PAGE_LINKS } from 'ts/constants/page-links';
import { EModalTypes } from 'ts/enums/modal.types';
import type { IEditDocumentDto, ITranslateDocumentDto } from 'ts/interfaces/documents/document';
import type { IService } from 'ts/interfaces/services/service';

export const TRANSLATE_FILE_KEY = 'TRANSLATE';

const useFileUploadAndTranslate = ({ service }: { service?: IService }) => {
  const navigate = useLocaleNavigate();
  const dispatch = useDispatch();
  const email = useSelector(userEmailSelector);

  // after upload a file run the progress animation
  const handleUpdateDownloadProgress = () => {
    let progressValue = 90;
    const intervalId = setInterval(() => {
      progressValue += 1;
      dispatch(updateDownloadProgress(progressValue));
      if (progressValue >= 100) clearInterval(intervalId);
    }, 50);
  };

  const analyticsEventUpload = ({
    success,
    size,
    errorCode,
    file_counter,
    pages_counter
  }: {
    success: boolean;
    size: number;
    errorCode?: number;
    file_counter?: number;
    pages_counter?: number;
  }) => {
    void Analytics.sendEvent({
      event: 'file_upload_status',
      data: {
        status: success ? 'success' : 'fail',
        place: 'additional',
        errorCode,
        size: formatFileSize(size),
        accurate_size: size / 1000000,
        file_counter: file_counter || 1,
        file_format: '.pdf',
        is_validation_error: success ? 'false' : 'true',
        pages_counter: pages_counter || 1
      }
    });
  };

  const handleEditDocument = (dataToEdit: IEditDocumentDto, signUp?: boolean) => {
    dispatch(getSupportedLanguages());
    handleUpdateDownloadProgress();

    const extractTextAndDetectLanguage = async (pdfData: string) => {
      try {
        const pdfBytes = atob(pdfData.split(',')[1]);
        const byteArray = new Uint8Array(pdfBytes.length);
        for (let i = 0; i < pdfBytes.length; i++) {
          byteArray[i] = pdfBytes.charCodeAt(i);
        }

        const pdf = await pdfjs.getDocument(byteArray).promise;
        const maxPages = Math.min(5, pdf.numPages);
        let fullText = '';

        for (let i = 1; i <= maxPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          const pageText = textContent.items.map((item: any) => item.str).join(' ');
          fullText += pageText + ' ';
        }

        const detectedLang = franc(fullText);
        localStorage.setItem('detectedLangForTranslate', detectedLang);

        return { text: fullText, language: detectedLang };
      } catch (error) {
        console.error('Error extracting text from PDF:', error);
        return null;
      }
    };

    if (dataToEdit.fileBase64) {
      void extractTextAndDetectLanguage(dataToEdit.fileBase64);
    }

    return setTimeout(
      async () => {
        const dataToEditFileCopy = cloneDeep(dataToEdit);

        delete dataToEditFileCopy?.fileBase64;
        try {
          // set converted data to localStorage and use this data for google auth
          localStorage.setItem(
            'dataToEdit',
            JSON.stringify({
              file: dataToEditFileCopy,
              service: service?.path,
              serviceType: service?.serviceType,
              email: email,
              documentId: dataToEdit.documentId,
              fileBase64: dataToEdit.fileBase64
            })
          );
          // set converted data to localStorage and use this data for google auth
          localStorage.setItem(
            'dataToConvert',
            JSON.stringify({
              file: dataToEditFileCopy,
              service: service?.path,
              email: email,
              serviceType: service?.serviceType,
              fileBase64: dataToEdit.fileBase64
            })
          );
        } catch (e) {
          console.log('Error', e);
          // set converted data to localStorage and use this data for google auth
          localStorage.setItem(
            'dataToEdit',
            JSON.stringify({
              file: dataToEditFileCopy,
              service: service?.path,
              email: email,
              documentId: dataToEdit.documentId,
              fileBase64: undefined,
              serviceType: service?.serviceType
            })
          );
          // set converted data to localStorage and use this data for google auth
          localStorage.setItem(
            'dataToConvert',
            JSON.stringify({
              file: dataToEditFileCopy,
              service: service?.path,
              email: email,
              serviceType: service?.serviceType,
              fileBase64: undefined
            })
          );
        }

        if (!dataToEdit.fileBase64) {
          return;
        }

        dispatch(setPDFfileContent({ document: dataToEdit.fileBase64 }));
        dispatch(toggleModal({ visible: false }));
        navigate(`${PAGE_LINKS.EDITOR}?to=${TRANSLATE_FILE_KEY}`, { replace: true });
        window.scrollTo(0, 0); // Scrolls to the top of the page
      },
      signUp ? 0 : 1000
    );
  };

  const handleEditFile = (dataToEdit: IEditDocumentDto) => {
    handleEditDocument(dataToEdit);
  };

  const handleSaveFileToLocalStorage = async (file: File) => {
    try {
      dispatch(updateDownloadProgress(0));
      dispatch(
        toggleModal({
          type: EModalTypes.PROGRESS_EDIT_FILE,
          visible: true
        })
      );

      const from = file?.name?.split('.')?.pop()?.toUpperCase() || service?.from || 'PDF';
      const pagesCount = from === 'PDF' ? await countPdfPages(file) : 1;

      const fileBase64 = await blobToBase64(file);

      const dataToEdit: IEditDocumentDto = {
        filename: file.name,
        size: file.size,
        key: null,
        pagesCount,
        serviceType: service?.serviceType,
        fileBase64
      };

      const dataToTranslate: ITranslateDocumentDto = {
        filename: file.name,
        size: file.size,
        key: null,
        pagesCount,
        fromLang: 'PDF',
        toLang: 'PDF',
        serviceType: service?.serviceType,
        fileBase64
      };

      // awaiting the downloading animation
      setTimeout(() => {
        dispatch(setTranslateDocumentData(dataToTranslate));

        handleEditFile(dataToEdit);
        analyticsEventUpload({ size: file?.size, success: true, pages_counter: pagesCount });
      }, 5000);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // upload to s3 bucket and convert file
  const handleUploadFile = (file: File) => {
    if (!file) return;
    dispatch(setDocumentForTranslate({ document: file }));

    void Analytics.sendEvent({
      event: 'features_tap',
      data: {
        features_name: service?.path?.replace('/', '') || '',
        method: 'click'
      }
    });

    handleSaveFileToLocalStorage(file);
  };

  return {
    handleUploadFile,
    handleEditDocument
  };
};

export default useFileUploadAndTranslate;
