import type { ChangeEvent } from 'react';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useLocaleNavigate } from 'hooks/useLocaleNavigate';

import ModalLayout from 'components/modals/baseModal/base';

import { Analytics } from 'services/analytics';

import { sendFileViaEmail } from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector } from 'data/selectors/modals';
import { userDataSelector, userSubscriptionSelector } from 'data/selectors/user';

import showToast from 'utils/toast';

import { PAGE_LINKS } from 'ts/constants/page-links';
import { EModalTypes } from 'ts/enums/modal.types';

import { PrimaryButton } from '../../buttons/primary-button';

export const SendFileViaEmail: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { file } = useSelector(modalOptionsSelector);
  const [fileName, setFileName] = React.useState<string>(file.filename || file.name);
  const [emailTo, setEmailTo] = React.useState<string>('');
  const [message, setMessage] = React.useState<string>('');
  const subscription = useSelector(userSubscriptionSelector);
  const user = useSelector(userDataSelector);
  const navigate = useLocaleNavigate();

  const handleFileNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFileName(event.target.value);
  };

  const handleEmailToChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailTo(event.target.value);
  };

  const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const handleCancel = () => {
    Analytics.sendEvent({ event: 'cancel_save_edited_file' });
    dispatch(toggleModal({ visible: false }));

    localStorage.removeItem('sendFileViaEmailPayload');
  };

  const handleCloseModal = () => {
    localStorage.removeItem('sendFileViaEmailPayload');
  };

  const handleSend = () => {
    if (!fileName || !emailTo) return;

    const payload = {
      fileId: file.id || localStorage.getItem('fileIdToSend'),
      filename: fileName,
      recipientEmail: emailTo,
      message
    };

    const handleCustomNavigate = () => {
      navigate(PAGE_LINKS.CHOOSING_PLAN);
    };

    if (subscription?.status !== 'active') {
      dispatch(toggleModal({ visible: false }));
      localStorage.setItem('sendFileViaEmailPayload', JSON.stringify(payload));
      if (!user?.email) {
        dispatch(
          toggleModal({
            visible: true,
            type: EModalTypes.ENTER_EMAIL_ADDRESS,
            options: {
              handleCustomNavigate
            }
          })
        );
        return;
      } else {
        return navigate(PAGE_LINKS.CHOOSING_PLAN);
      }
    }

    dispatch(sendFileViaEmail(payload))
      .then(() => {
        showToast('success', 'Email sent successfully', 5000, 'sendTo');
        Analytics.sendEvent({ event: 'send_file_success' });
        localStorage.removeItem('sendFileViaEmailPayload');
      })
      .catch((error: any) => {
        showToast('error', 'Failed to send email', 5000, 'sendTo');
        console.error('Error sending email:', error);
      });
    dispatch(toggleModal({ visible: false }));
  };

  return (
    <ModalLayout
      canClose
      closePopup={handleCloseModal}
    >
      <div
        className='bg-white rounded-2xl tablet:pt-8 tablet:pb-10 tablet:px-[44px] pt-[44px] pb-4 px-4
        max-w-[657px] w-full relative text-[#575757] flex flex-col justify-center'
      >
        <h2 className='mb-6 text-[#1D1D1D] font-[900] text-[22px] leading-[30px] tablet:text-[25px] tablet:leading-[30px] text-center'>
          {t('popups.send_to_popup.title')}
        </h2>
        <div className='flex flex-col w-full mb-8 px-4 tablet:px-0'>
          <label htmlFor='fileName'>{t('popups.send_to_popup.shared_file')}</label>
          <input
            type='text'
            id='fileName'
            name='fileName'
            placeholder={t('editor_page.convert_banner.file_name_placeholder')}
            value={fileName}
            onChange={handleFileNameChange}
            className='rounded-md border border-[#C7C7C7] py-3 px-4 mt-2'
          />
        </div>
        <div className='flex flex-col w-full mb-8 px-4 tablet:px-0'>
          <label htmlFor='emailTo'>{t('popups.send_to_popup.email')}</label>
          <input
            type='text'
            id='emailTo'
            name='emailTo'
            placeholder='na@email.com'
            value={emailTo}
            onChange={handleEmailToChange}
            className='rounded-md border border-[#C7C7C7] py-3 px-4 mt-2'
          />
        </div>
        <div className='flex flex-col w-full mb-8 px-4 tablet:px-0'>
          <label htmlFor='message'>{t('popups.send_to_popup.message')}</label>
          <textarea
            id='message'
            name='message'
            placeholder={t('popups.send_to_popup.message_placeholder')}
            value={message}
            onChange={handleMessageChange}
            style={{ resize: 'none' }}
            className='border border-solid border-[#C7C7C7] rounded-[5px] w-full h-[140px] p-4'
          />
        </div>
        <div className='flex flex-col gap-6  mobile:flex-row justify-center items-center mobile:justify-around tablet:justify-between w-full'>
          <PrimaryButton
            onClick={handleCancel}
            className='hidden tablet:block bg-white border border-solid border-[#1D1D1D] hover:bg-[#1D1D1D] text-[#1D1D1D] py-[19px] mobile:py-4
            hover:!text-[#FFFFFF] min-w-[145px] w-full mobile:w-[267px] mobile:!text-[20px] mobile:!leading-[30px]'
          >
            {t('editor_page.convert_banner.cancel')}
          </PrimaryButton>
          <PrimaryButton
            onClick={handleSend}
            className='min-w-[145px] w-full mobile:w-[267px] border border-solid border-[#D2294B] py-[19px] mobile:py-4
              hover:border-[#f4476ae0] bg-[#D2294B] hover:bg-[#f4476ae0] mobile:!text-[20px] mobile:!leading-[30px]'
          >
            {t('popups.send_to_popup.send')}
          </PrimaryButton>
        </div>
      </div>
    </ModalLayout>
  );
};
